import { useMutation, useQuery } from "react-query";
import { CustomListData, CustomListDataRow } from "./CustomListView.types";
import { get } from "../../common/api/apiShared";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import { useStateUrlParams } from "../../common/hooks/useStateUrlParams";
import { CustomListType } from "../CustomLists.types";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";
import { AnnualWheelPriority } from "../../annualWheel/annualWheelDialog/AnnualWheelDialog.types";
import { ControllerProcess, SharingTypeEnum } from "../../processingActivity/processingActivityOverview/ProcessingActivityOverview.types";
import { SystemType } from "../../processingActivity/systemsStep/SystemsStep.types";
import { LegalEntityType, LevelOfProtectionType } from "../../legalEntity/addLegalEntityDialog/AddLegalEntityDialog.types";
import { DotLegalBreadCrumbModel, useDotLegalSnackbar, usePaginationDataMapping } from "@dotlegal/dotlegal-ui-components";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { PropertyType } from "../../common/PropertyTypes";
import { SystemStatus } from "../../system/systemEdit/SystemEdit.types";
import { SystemDataType, SystemEntityType, SystemStorageType } from "../../system/systemEdit/systemDataAndStorageStep/SystemDataAndStorageStep.types";
import { DataSourceEnum } from "../../processingActivity/ProcessingActivity.types";
import { LegalEntityAuditFrequency, LegalEntityAuditType } from "../../legalEntity/legalEntityManagement/LegalEntityManagement.types";
import { useCustomListExport } from "./useCustomListExport.ts";

export function useCustomListViewHooks() {
    const customListExport = useCustomListExport();
    const {
        translateString,
        translateDate,
        translateCountry,
        translateLegalEntityRiskAssessment,
        translateCustomListType,
        translateStatus,
        translateDocumentType,
        translateDataSubjectsEnum,
        translateProcessorsEnum,
    } = useTranslation();
    const {
        getCustomProcessingActivityListsUrl,
        getCustomCompanyListsUrl,
        getCustomSharingListsUrl,
        getCustomSystemListsUrl,
        getProcessingActivitiesOverviewUrl,
        getSystemsUrl,
        getLegalEntitiesUrl,
        getGroupEntitiesUrl,
        getCustomGroupCompanyListsUrl,
        getSettingsUrl,
    } = useUrlProvider();
    const [orderBy, setOrderBy] = useState<number>(0);
    const [order, setOrder] = useState<"asc" | "desc">("asc");
    const [searchString, setSearchString] = useState<string>("");
    const { customListId } = useParams<{ customListId: string }>();
    const url = "/CustomListsData/" + customListId;
    const query = useQuery(url, () => get<CustomListData>(url));
    const [openEditDialog, setOpenEditDialog] = useStateUrlParams("showDialog", "");
    const [isGroupCompanies] = useStateUrlParams("groupcompanies", "");
    const { show: showSnackbar } = useDotLegalSnackbar();
    const { setPage, getPagination } = usePaginationDataMapping();
    const { permissions } = useUserContext();

    let listData: CustomListData = {
        rows: [],
        columns: [],
        name: "",
        description: "",
        listType: CustomListType.Company,
        isReadOnly: false,
        processingActivityLimitReached: false,
        maxNumberOfProcessingActivities: 0,
    };

    const listRows = new Array<CustomListDataRow>();
    if (query.data) {
        listData = query.data;
    }

    function onSortClick(columnIndex: number) {
        setOrderBy(columnIndex);
        setOrder(order === "asc" ? "desc" : "asc");
    }
    for (const d of listData.rows) {
        listRows.push({
            data: d.data.map(getCellText),
            id: d.id,
            isGroupCompany: d.isGroupCompany,
            processingActivityId: d.processingActivityId,
            subStep: d.subStep,
        });
    }

    const filtered = listRows.filter((x) => x.data.some((d) => d !== null && d.toString().toLowerCase().includes(searchString.toLowerCase())));
    let sortedData = sortTableData(filtered, order);
    const { calculatedPage, to, from, count, noOfPages, rows } = getPagination(listData.rows.length, filtered, false, 100);
    sortedData = rows;

    const listType = query.data?.listType!;

    const getBreadCrumbs = () => {
        let breadCrumbs: Array<DotLegalBreadCrumbModel> = [];

        if (listType !== undefined) {
            if (isGroupCompanies === "true") {
                breadCrumbs.push({ link: getSettingsUrl(), name: translateString("settings") });
            }

            breadCrumbs.push(getParentPageBreadCrumb());
            breadCrumbs.push({
                name: `${translateString("customLists")} - ${translateCustomListType(listType.toString())}`,
                link: getCustomListUrl(),
            });
            breadCrumbs.push({
                name: listData.name,
                tooltip: listData.description,
            });
        }

        return breadCrumbs;
    };

    const getCustomListUrl = () => {
        switch (listType) {
            case CustomListType.Company: {
                if (isGroupCompanies === "true") {
                    return getCustomGroupCompanyListsUrl();
                }
                return getCustomCompanyListsUrl();
            }
            case CustomListType.Sharing: {
                return getCustomSharingListsUrl();
            }
            case CustomListType.ProcessingActivity: {
                return getCustomProcessingActivityListsUrl();
            }
            case CustomListType.System: {
                return getCustomSystemListsUrl();
            }
            default: {
                return "";
            }
        }
    };

    const getParentPageBreadCrumb = () => {
        switch (listType) {
            case CustomListType.Company: {
                if (isGroupCompanies === "true") {
                    return { name: translateString("groupCompanies"), link: getGroupEntitiesUrl() };
                } else {
                    return { name: translateString("companies"), link: getLegalEntitiesUrl() };
                }
            }
            case CustomListType.Sharing:
            case CustomListType.ProcessingActivity: {
                return { name: translateString("processingActivities"), link: getProcessingActivitiesOverviewUrl() };
            }
            case CustomListType.System: {
                return { name: translateString("systems"), link: getSystemsUrl() };
            }
            default: {
                throw Error("Invalid ListType: " + listType);
            }
        }
    };

    const getExportMutation = useMutation(getExport, {
        onSuccess: () => {
            if (listData.processingActivityLimitReached) {
                showSnackbar(translateString("exportFileIsBeingPrepared"));
            } else {
                showSnackbar(translateString("exportFileIsDownloaded"));
            }
        },
    });

    async function getExport() {
        if (listData.processingActivityLimitReached) {
            return customListExport.exportListInBackground(customListId!);
        } else {
            await customListExport.exportListInstantly(customListId!, listData.name);
        }
    }

    const showEditDialog = openEditDialog === "true";

    return {
        isLoading: query.isLoading,
        isLoadingExport: getExportMutation.isLoading,
        columns: listData.columns,
        rows: sortedData,
        isReadOnly: query.data?.isReadOnly || !permissions.customListPermissions.edit,
        onSortClick,
        setSearchString,
        searchString,
        orderBy,
        order,
        getBreadCrumbs,
        showEditDialog,
        setOpenEditDialog,
        customListId,
        refetch: query.refetch,
        listType,
        hasData: listData.rows.length > 0,
        onClickExport: async () => await getExportMutation.mutateAsync(),
        calculatedPage,
        processingActivityLimitReached: listData.processingActivityLimitReached,
        processingActivityLimit: listData.maxNumberOfProcessingActivities,
        to,
        from,
        count,
        noOfPages,
        setPage,
    };

    function sortTableData(data: Array<CustomListDataRow>, order: "asc" | "desc") {
        return data.sort((a: CustomListDataRow, b: CustomListDataRow) => {
            const getElemABiggestOrder = () => (order === "asc" ? 1 : -1);
            const getElemBBiggestOrder = () => (order === "asc" ? -1 : 1);
            let aElement = a.data[orderBy];
            let bElement = b.data[orderBy];
            if (!aElement) {
                return getElemBBiggestOrder();
            }
            if (!bElement) {
                return getElemABiggestOrder();
            }
            if (typeof aElement === "string") {
                aElement = aElement.toLowerCase();
                bElement = bElement.toLowerCase();
            }
            if (aElement > bElement) {
                return getElemABiggestOrder();
            }
            if (aElement < bElement) {
                return getElemBBiggestOrder();
            }
            return 0;
        });
    }

    function getCellText(cell: any, index: any): string {
        const type = listData.columns[index].columnType;
        if (cell === null && type !== PropertyType.Sharings) {
            return cell;
        }

        switch (type) {
            case PropertyType.ProcessingActivityClassification:
            case PropertyType.Risk: {
                if (cell === "0") return type === PropertyType.ProcessingActivityClassification ? translateString("noData") : translateString("notAnswered");
                else return translateString(setFirstLetterToLowerCase(AnnualWheelPriority[cell].toString()));
            }
            case PropertyType.ValidationDate:
            case PropertyType.ReportApprovalDate:
            case PropertyType.ReportDataProcessorEndDate: {
                return translateDate(cell);
            }
            case PropertyType.Status: {
                return cell + " %";
            }
            case PropertyType.SenderRoles:
            case PropertyType.RecipientRoles: {
                return translateString(ControllerProcess[cell].toString());
            }
            case PropertyType.RecipientCountries:
            case PropertyType.SenderCountries:
            case PropertyType.Country: {
                return translateCountry(cell);
            }
            case PropertyType.StorageLocations:
            case PropertyType.DataProcessorCountries:
            case PropertyType.SupportAccessLocations: {
                return sortArray(getCountries(cell)).join(", ");
            }
            case PropertyType.TIA:
            case PropertyType.DataProcessorAgreement:
            case PropertyType.Agreement: {
                return cell === "False" ? translateString("no") : cell === "True" ? translateString("yes") : cell;
            }
            case PropertyType.Sharings: {
                return cell ? cell : translateString("unNamedSharing");
            }
            case PropertyType.SystemTypes: {
                return translateString(setFirstLetterToLowerCase(SystemType[cell].toString()));
            }
            case PropertyType.ReceivingLocation:
            case PropertyType.Location: {
                return getLevelOfProtection(cell)!;
            }
            case PropertyType.DataProcessorLocations: {
                return sortArray(getLevelOfProtections(cell)!).join(", ");
            }
            case PropertyType.Active:
            case PropertyType.TIANotNecessary:
            case PropertyType.IsDataProcessor:
            case PropertyType.IsSubProcessor:
            case PropertyType.IsCompanyRecipientOnDislosure:
            case PropertyType.IsCompanySenderOnSource:
            case PropertyType.IsCompanySenderOnDataProcessor:
            case PropertyType.CompanyHasDocuments:
            case PropertyType.IsSupplierADataProcessor:
            case PropertyType.IsCriticalForOperation:
            case PropertyType.IsGroupCompany: {
                return translateString(cell);
            }
            case PropertyType.Roles: {
                return sortArray(getLegalEntityRole(cell)).join(", ");
            }
            case PropertyType.Type: {
                return sortArray(getLegalEntityTypes(cell)).join(", ");
            }
            case PropertyType.LegalEntityRisk: {
                return translateLegalEntityRiskAssessment(cell.toString());
            }
            case PropertyType.SharingTypes: {
                return translateString(SharingTypeEnum[cell].toString());
            }
            case PropertyType.ProcessingActivityStatus: {
                return translateStatus([cell].toString());
            }
            case PropertyType.DocumentTypes: {
                return getDocumentTypes(cell).join(", ");
            }
            case PropertyType.LegalEntityAuditType: {
                return translateString(setFirstLetterToLowerCase(LegalEntityAuditType[cell].toString()));
            }
            case PropertyType.LegalEntityAuditFrequency: {
                return translateString(setFirstLetterToLowerCase(LegalEntityAuditFrequency[cell].toString()));
            }
            case PropertyType.SystemStatus: {
                return translateString(setFirstLetterToLowerCase(SystemStatus[cell].toString()));
            }
            case PropertyType.SystemStorageType: {
                return translateString(setFirstLetterToLowerCase(SystemStorageType[cell].toString()));
            }
            case PropertyType.SystemEntityType: {
                return translateString(setFirstLetterToLowerCase(SystemEntityType[cell].toString()));
            }
            case PropertyType.SystemDataType: {
                return getSystemDataTypes(cell);
            }
            case PropertyType.GroupEntitiesOnDocuments: {
                return cell === "All" ? translateString("entireGroup") : cell;
            }
            case PropertyType.DataSource: {
                return sortArray(getDataSources(cell)).join(", ");
            }
            case PropertyType.NumberOfDataSubjects: {
                return sortArray(getNumberOfDataSubjects(cell)).join(", ");
            }
            case PropertyType.NumberOfProcessors: {
                return sortArray(getNumberOfProcessors(cell)).join(", ");
            }
            default: {
                return cell;
            }
        }
    }

    function getLevelOfProtection(cell: any) {
        const levelOfProtection = Number(cell);
        switch (levelOfProtection) {
            case LevelOfProtectionType.EU: {
                return translateString("eu");
            }
            case LevelOfProtectionType.EEA: {
                return translateString("eea");
            }
            case LevelOfProtectionType.AdequateProtection: {
                return translateString("secureThirdCountries");
            }
            case LevelOfProtectionType.ThirdCountry: {
                return translateString("unsecureThirdCountries");
            }
            case LevelOfProtectionType.None: {
                return translateString("none");
            }
            case LevelOfProtectionType.CPIEDA: {
                return translateString("cpieda");
            }
            case LevelOfProtectionType.PrivacyShield: {
                return translateString("privacyshield");
            }
            default: {
                new Error("LevelOfProtection needs to be implemented: " + cell);
            }
        }
    }

    function getLevelOfProtections(cell: any) {
        const levelOfProtections = cell.split(",");
        return levelOfProtections.map((x: any) => {
            return getLevelOfProtection(x.trim());
        });
    }

    function getLegalEntityRole(cell: any) {
        const roles = cell.split(",");
        return roles.map((x: any) => {
            const role = Number(x.trim());
            return translateString(ControllerProcess[role].toString());
        });
    }

    function getLegalEntityTypes(cell: any) {
        const types = cell.split(",");
        return types.map((x: any) => {
            const type = Number(x.trim());
            return translateString(setFirstLetterToLowerCase(LegalEntityType[type].toString()));
        });
    }

    function getDataSources(cell: any) {
        const dataSources = cell.split(",");
        return dataSources.map((x: any) => {
            const dataSource = Number(x.trim());
            return translateString(setFirstLetterToLowerCase(DataSourceEnum[dataSource].toString()));
        });
    }

    function getDocumentTypes(cell: any) {
        const documentTypes = cell.split(",");
        return documentTypes.map((x: any) => {
            return translateDocumentType(x.trim());
        });
    }

    function getSystemDataTypes(cell: any) {
        return cell
            .split(",")
            .map((x: any) => translateString(setFirstLetterToLowerCase(SystemDataType[x.trim()])))
            .join(", ");
    }

    function getCountries(cell: any) {
        const countryCodes = cell.split(",");
        return countryCodes.map((x: any) => {
            return translateCountry(x.trim());
        });
    }

    function sortArray(list: string[]) {
        return list.sort((a, b) => (a > b ? 1 : -1));
    }

    function getNumberOfDataSubjects(cell: any) {
        const dataSubjects = cell.split(",");
        return dataSubjects.map((x: any) => {
            const dataSubject = Number(x.trim());
            return translateDataSubjectsEnum(dataSubject.toString());
        });
    }

    function getNumberOfProcessors(cell: any) {
        const processors = cell.split(",");
        return processors.map((x: any) => {
            const processor = Number(x.trim());
            return translateProcessorsEnum(processor.toString());
        });
    }
}
