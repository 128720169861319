import { useState } from "react";
import { useQuery } from "react-query";
import { get } from "../common/api/apiShared";
import { useTranslation } from "../localization/useTranslation";
import { IAuditLogDialog } from "./AuditLogDialog";
import { AuditLogEntryViewModel, AuditLogPropertyType, AuditLogPropertyViewModel, AuditLogViewModel, EntityType } from "./AuditLogDialog.types";
import { isNullOrWhitespace } from "../common/stringOperations";
import { ITableHeader } from "@dotlegal/dotlegal-ui-components";

export function AuditLogDialogHooks(props: IAuditLogDialog) {
    const { translateString, translateDataSubjectsEnum, translateProcessorsEnum } = useTranslation();
    const [selectedLog, setSelectedLog] = useState<string | null>(null);
    const [properties, setProperties] = useState<Array<AuditLogPropertyViewModel>>([]);
    const { isLoading, data } = useQuery(`/auditlog/${props.entityId}`, () => get<AuditLogViewModel>(`/auditlog/${props.entityId}`));

    let tableEntryHeader: Array<ITableHeader<AuditLogEntryViewModel>> = [
        { property: "auditType", text: translateString("action"), align: "left", showOnMobile: true },
        { property: "timeStamp", text: translateString("timeStamp"), align: "left", showOnMobile: true },
        { property: "name", text: translateString("name"), align: "left", showOnMobile: true },
    ];

    //Skal udvides med at på et BA/System har den step, på risk har den også et senario og dette som den er nu som default.
    let tablePriorityHeader: Array<ITableHeader<AuditLogPropertyViewModel>> = [
        { property: "propertyName", text: translateString("input"), align: "left", showOnMobile: true },
        { property: "from", text: translateString("updatedFrom"), align: "left", showOnMobile: true },
        { property: "to", text: translateString("updatedTo"), align: "left", showOnMobile: true },
    ];

    function getHeader() {
        switch (props.entityType) {
            case EntityType.Processingactivity:
                return translateString("auditLogProcessingActivityHeader");
            case EntityType.AnnualWheel:
                return translateString("auditLogAnnualWheelHeader");
            case EntityType.Risk:
                return translateString("auditLogRiskHeader");
            case EntityType.System:
                return translateString("auditLogSystemHeader");
            case EntityType.TaskManagement:
                return translateString("auditLogTaskManagementHeader");
        }
    }

    return {
        isLoading,
        data,
        getHeader,
        selectedLog,
        setSelectedLog,
        tableEntryHeader,
        tablePriorityHeader,
        properties,
        setProperties,
        translateValue,
    };

    function translateValue(value: string | null, propertyType: AuditLogPropertyType, propertyName: string) {
        if (isNullOrWhitespace(value ?? undefined)) return value;

        if (propertyType === AuditLogPropertyType.Enum) return value;

        if (!isNullOrWhitespace(value ?? undefined)) {
            if (value!.toLowerCase() === "false" || value!.toLowerCase() === "true") {
                return translateString(value!.toLowerCase());
            }
        }

        return value;
    }
}
