import React from "react";
import { LatestLegalEntityAuditSubmissionViewModel } from "../LatestAuditsSubmittedWidget.types";
import { DotLegalEmptyState, DotLegalTable, DotLegalTooltip, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { Box, TableCell, TableRow } from "@mui/material";
import { useRiskIndicator } from "../../../riskAssessment/riskIndicator/RiskIndicator.hooks";
import { getQuestionnaireResponseScore } from "../../../common/questionnaireResponseScore";
import { useUrlProvider } from "../../../useUrlProvider";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import LatestAuditsEmptyStateIcon from "../latest-audits-empty-state.svg?react";
import { Trans } from "react-i18next";
import DashboardPrettyScrollbar from "../../DashboardPrettyScrollbar.tsx";
import { LatestAuditEmptyStateWrapper } from "../LatestAuditsSubmittedWidget";
import { LegalEntityAuditTabs } from "../../../legalEntity/LegalEntity.types";

export interface LatestAuditsTableProps {
    data: Array<LatestLegalEntityAuditSubmissionViewModel>;
    isEnabled: boolean;
    isLoading: boolean;
}

function LatestAuditsTable(props: LatestAuditsTableProps) {
    const { translateString, translateDate } = useTranslation();
    const { getRiskIcon } = useRiskIndicator();
    const { getLegalEntityUrl } = useUrlProvider();
    const { getAuditsUrl } = useUrlProvider();

    function getHeaders() {
        let headers = Array<ITableHeader<LatestLegalEntityAuditSubmissionViewModel>>();
        headers.push({ property: "legalEntityName", text: translateString("legalEntity"), align: "left", showOnMobile: true });
        headers.push({ property: "templateName", text: translateString("template"), align: "left", showOnMobile: true });
        headers.push({ property: "score", text: translateString("riskLevel"), align: "left", showOnMobile: true });
        headers.push({ property: "latestResponseChange", text: translateString("riskLevel"), align: "left", showOnMobile: true, hideHeader: true });

        return headers;
    }

    const getScore = (row: LatestLegalEntityAuditSubmissionViewModel) => {
        return getQuestionnaireResponseScore(true, row.scoreEnabled, row.score, translateString("scoreNotEnabled"));
    };

    return props.isEnabled && props.data.length > 0 ? (
        <DashboardPrettyScrollbar>
            <DotLegalTable
                marginTop={0}
                headers={getHeaders()}
                defaultOrderBy={"latestResponseChange"}
                defaultOrder={"desc"}
                paginationLabelOf={""}
                isLoading={props.isLoading}
                data={props.data}
                hidePagination
                clickableRows={false}
                renderRow={(row) => {
                    return (
                        <DotLegalTooltip
                            text={<Trans i18nKey={"submittedOnDate"} values={{ date: translateDate(row.latestResponseChange) }} />}
                            key={row.legalEntityId}
                            followCursor
                            position="right"
                        >
                            <TableRow>
                                <TableCell>
                                    <DotLegalLink
                                        bold
                                        linkColor={"primary"}
                                        to={`${getLegalEntityUrl(row.legalEntityId, LegalEntityAuditTabs.Audits)}`}
                                        stopPropagation
                                    >
                                        {row.legalEntityName}
                                    </DotLegalLink>
                                </TableCell>
                                <TableCell>{row.templateName}</TableCell>
                                <TableCell>
                                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                                        {getRiskIcon(row.riskLevel)}
                                        {getScore(row)}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </DotLegalTooltip>
                    );
                }}
            />
        </DashboardPrettyScrollbar>
    ) : (
        <LatestAuditEmptyStateWrapper>
            <DotLegalEmptyState
                textColor={"darkBlue"}
                noFixedHeight
                noPadding
                icon={<LatestAuditsEmptyStateIcon />}
                text={
                    <Trans
                        i18nKey={"sendCustomAuditsToLegalEntities"}
                        components={{
                            box: (
                                <DotLegalLink to={getAuditsUrl()} linkColor="primary">
                                    {undefined}
                                </DotLegalLink>
                            ),
                        }}
                    />
                }
            />
        </LatestAuditEmptyStateWrapper>
    );
}

export default LatestAuditsTable;
