import React from "react";
import { useSendAuditDialog } from "./SendAuditDialog.hooks";
import { arrow, leftWhiteArrowButton } from "../../../common/icons";
import { DotLegalDatePicker, DotLegalDialog, DotLegalSelect, DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { apiUrls } from "../../../common/api/apiUrls";
import DotLegalSelectApi from "../../../common/components/dotLegalSelectApi/DotLegalSelectApi";
import DotLegalMultiSelectApi from "../../../common/components/dotLegalMultiSelectApi/DotLegalMultiSelectApi";
import { Box, TableCell, TableRow } from "@mui/material";
import { LegalEntityContactType, LegalEntityContactViewModel } from "../../legalEntityContactTab/LegalEntityContactTab.types";
import { QuestionnaireSelectableItem } from "../LegalEntityAudit.types";
import { SendAuditViewModel } from "./SendAuditDialog.types";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import GroupEntityStep from "../groupEntityStep/GroupEntityStep";

export interface ISendAuditDialogProps {
    onDialogClose: () => void;
    onAuditSent: () => void;
    legalEntityId: string | undefined;
    legalEntityName: string;
    responseId?: string;
    questionnaire?: QuestionnaireSelectableItem;
}

function SendAuditDialog(props: ISendAuditDialogProps) {
    const { translateString, getDateTimePickerLanguage, translateDate } = useTranslation();

    const {
        onAuditChanged,
        model,
        onContactChanged,
        onDateChanged,
        onSystemsChanged,
        onGroupEntityChanged,
        onResponsibleChanged,
        onNextOrSendClick,
        onPreviousButtonClick,
        step,
        isSending,
        selectableGroupEntitiesLoading,
        selectableGroupEntitiesData,
        validation,
        selectableResponsiblesData,
        selectableresponsiblesLoading,
    } = useSendAuditDialog(props);

    const stepSelect = (
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Box sx={{ width: 450 }}>
                <DotLegalSelectApi<QuestionnaireSelectableItem>
                    url={apiUrls().questionnaires.selectableItems}
                    onChange={onAuditChanged}
                    label={translateString("template")}
                    noOptionsLabel={translateString("noOptions")}
                    selectedItem={model.questionnaireId}
                    toolTipText={translateString("selectAuditInfo")}
                    errorText={validation.getErrorsForDisplay("questionnaireId")}
                    disabled={props.questionnaire !== undefined}
                    disableClearable
                />

                <DotLegalSelectApi<LegalEntityContactViewModel>
                    url={apiUrls().legalEntities.contacts(props.legalEntityId!)}
                    onChange={onContactChanged}
                    label={translateString("contact")}
                    noOptionsLabel={translateString("noOptions")}
                    selectedItem={model.contactId}
                    toolTipText={translateString("selectAuditContactInfo")}
                    errorText={validation.getErrorsForDisplay("contactId")}
                    onOptionsRender={(item) =>
                        item.contactTypes.length > 0
                            ? `${item.name} (${item.contactTypes
                                  .map((ct) => translateString(setFirstLetterToLowerCase(LegalEntityContactType[ct].toString())))
                                  .join(", ")})`
                            : item.name
                    }
                />

                <DotLegalSelect
                    options={selectableResponsiblesData}
                    onChange={onResponsibleChanged}
                    label={translateString("responsible")}
                    selectedItem={model.responsibleId}
                    noOptionsLabel={translateString("noOptions")}
                    isLoading={selectableresponsiblesLoading}
                    errorText={validation.getErrorsForDisplay("responsibleId")}
                    toolTipText={translateString("legalEntityAuditReponsibleTooltip")}
                />

                <DotLegalDatePicker
                    label={translateString("deadline")}
                    value={model.deadline ?? null}
                    onChange={onDateChanged}
                    language={getDateTimePickerLanguage()}
                    toolTipText={translateString("selectAuditDeadlineInfo")}
                    error={!!validation.getErrorsForDisplay("deadline")}
                    helperMessage={validation.getErrorsForDisplay("deadline")}
                />

                <DotLegalMultiSelectApi
                    url={apiUrls().systems.selectableItemsByLegalEntityId(props.legalEntityId!)}
                    onChange={onSystemsChanged}
                    label={translateString("systems")}
                    noOptionsLabel={translateString("noOptions")}
                    chosenOptions={model.systemIds}
                    toolTipText={translateString("selectAuditSystemsInfo")}
                />
            </Box>
        </Box>
    );

    const stepGroupCompany = (
        <GroupEntityStep
            showGdpoIfAvailable
            onGroupEntityChanged={onGroupEntityChanged}
            errormsg={validation.getErrorsForDisplay("groupEntityId")}
            groupEntityId={model.groupEntityId}
            selectableGroupEntitiesData={selectableGroupEntitiesData}
            header={translateString("legalEntityAudtiGroupEntityHeader")}
            infoTranslationKey={"legalEntityAudtiGroupEntity"}
            checkboxTranslationKey={"legalEntityAuditEntireGroup"}
            checkboxTooltip={translateString("legalEntityAuditEntireGroupInfo")}
        />
    );

    let headers: Array<ITableHeader<SendAuditViewModel>> = [
        { property: "legalEntityName", text: translateString("legalEntity"), align: "left", showOnMobile: true },
        { property: "contactName", text: translateString("contact"), align: "left", showOnMobile: true },
        { property: "contactEmail", text: translateString("contactEmail"), align: "left", showOnMobile: true },
        { property: "responsibleId", text: translateString("responsible"), align: "left", showOnMobile: true },
        { property: "responsibleId", text: translateString("responsibleEmail"), align: "left", showOnMobile: true },
        { property: "systemNames", text: translateString("systems"), align: "left", showOnMobile: true },
        { property: "questionnaireName", text: translateString("template"), align: "left", showOnMobile: true },
        { property: "deadline", text: translateString("deadline"), align: "left", showOnMobile: true },
        { property: "groupEntityName", text: translateString("sentFrom"), align: "left", showOnMobile: true },
    ];

    const stepSend = (
        <div>
            <DotLegalTable
                headers={headers}
                defaultOrderBy={"legalEntityName"}
                defaultOrder={"asc"}
                isLoading={false}
                clickableRows={false}
                hidePagination
                data={[model]}
                renderRow={(row, i) => {
                    const responsible = selectableResponsiblesData?.find((x) => x.id === row.responsibleId);
                    return (
                        <TableRow key={row.legalEntityName! + i}>
                            <TableCell>{row.legalEntityName}</TableCell>
                            <TableCell>{row.contactName}</TableCell>
                            <TableCell>{row.contactEmail}</TableCell>
                            <TableCell>{responsible?.name ?? ""}</TableCell>
                            <TableCell>{responsible?.email ?? ""}</TableCell>
                            <TableCell>{row.systemNames.join(", ")}</TableCell>
                            <TableCell>{row.questionnaireName}</TableCell>
                            <TableCell>{translateDate(row.deadline!)}</TableCell>
                            <TableCell>{row.groupEntityName}</TableCell>
                        </TableRow>
                    );
                }}
                paginationLabelOf={""}
            />
        </div>
    );

    return (
        <DotLegalDialog
            header={translateString("sendAudit")}
            size={"lg"}
            buttonOkIcon={arrow}
            buttonOkText={step === "send" ? translateString("send") : translateString("next")}
            onDialogClose={props.onDialogClose}
            onOkClick={onNextOrSendClick}
            previousButtonText={translateString("previous")}
            previousButtonClick={step !== "first" ? onPreviousButtonClick : undefined}
            previousButtonIcon={leftWhiteArrowButton}
            open
            buttonRight
            inProgress={isSending}
            okButtonDisabled={selectableGroupEntitiesLoading}
        >
            {step === "first" ? stepSelect : step === "selectGroupEntity" ? stepGroupCompany : stepSend}
        </DotLegalDialog>
    );
}

export default SendAuditDialog;
