import { Box } from "@mui/material";
import React from "react";
import { DotLegalPrettyScrollbar } from "@dotlegal/dotlegal-ui-components";
export interface IDashboardPrettyScrollbarProps {
    children?: React.ReactNode;
    showGutter?: boolean;
}

function DashboardPrettyScrollbar(props: IDashboardPrettyScrollbarProps) {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <DotLegalPrettyScrollbar
                showGutter={props.showGutter}
                sx={{
                    height: 0,
                    flex: "1 1 auto",
                }}
            >
                {props.children}
            </DotLegalPrettyScrollbar>
        </Box>
    );
}

export default DashboardPrettyScrollbar;
