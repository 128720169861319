import { Box, Grid, Menu, MenuItem, Skeleton, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
    DotLegalButton,
    DotLegalChip,
    DotLegalHeader,
    DotLegalMultiSelect,
    DotLegalPageHeader,
    DotLegalSearchField,
    DotLegalSwitch,
} from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../localization/useTranslation";
import { useAnnualWheelDataMapping } from "./AnnualWheel.hooks";
import { completedTaskCountColor, overDueTaskCountColor, plannedTaskCountColor, totalTaskCounterColor, useAnnualWheelStyles } from "./AnnualWheel.styles";
import exportReportWave from "./exportReportWave.svg?url";
import overdueTasksWave from "./overdueTasksWave.svg?url";
import plannedTasksWave from "./plannedTasksWave.svg?url";
import completedTasksWave from "./completedTasksWave.svg?url";
import totalTasksWave from "./totalTasksWave.svg?url";
import AnnualWheelYearPicker from "./annualWheelYearPicker/AnnualWheelYearPicker";
import AnnualWheelDialog from "./annualWheelDialog/AnnualWheelDialog";
import DeleteAnnualWheelActivityDialog from "./deleteAnnualWheelActivtyDialog/DeleteAnnualWheelActivityDialog";
import { useNavMenuDataMapping } from "../main/NavMenu/NavMenu.hooks";
import { usePlanContext } from "../auth/planProvider/PlanProvider";
import { PurpleLockIcon } from "../common/icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PlanDialogButtonWrapper from "../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider";
import AnnualWheelCalendarOverview from "./annualWheelCalendarOverview/AnnualWheelCalendarOverview";
import AnnualWheelListOverview from "./annualWheelListOverview/AnnualWheelListOverview";
import { GridSizes } from "../common/components/dotLegalTable/DotLegalTable.types";
import { TaskStateEnum } from "./AnnualWheel.types";
import { isNullOrWhitespace } from "../common/stringOperations";

function AnnualWheel() {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClickDownloadMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setDownloadMenuOpen(true);
    };
    const handleCloseDownloadMenu = () => {
        setAnchorEl(null);
        setDownloadMenuOpen(false);
    };
    const [downloadMenuOpen, setDownloadMenuOpen] = useState(false);
    const styles = useAnnualWheelStyles();
    const { translateString } = useTranslation();
    const {
        isLoading,
        data,
        year,
        setYear,
        showAnnualWheelDialog,
        setShowAnnualWheelDialog,
        refetchData,
        month,
        setMonth,
        activity,
        setActivity,
        showDeleteActivityDialog,
        setShowDeleteActivityDialog,
        onDownloadPdfClick,
        isDownloadingPdf,
        permissions,
        onClickDownloadExcelReport,
        isDownloadingReport,
        showListView,
        setShowListView,
        areasFilterOptions,
        businessAreasFilterOptions,
        monthsFilterOptions,
        prioritiesFilterOptions,
        responsiblesFilterOptions,
        statusFilterOptions,
        searchedAreas,
        searchedBusinessAreas,
        searchedMonths,
        searchedPriorities,
        searchedResponisbles,
        searchedStatus,
        setSearchedAreas,
        setSearchedBusinessAreas,
        setSearchedMonths,
        setSearchedPriorities,
        setSearchedResponsibles,
        setSearchedStatus,
        getFilteredData,
        searchedString,
        setSearchedString,
        onBoxCounterClick,
        getTaskManagementUrlForMonth,
        getTaskManagementUrlForTask,
        groupCompanyOptions,
        searchedGroupCompanies,
        setSearchedGroupCompanies,
        hasActivities,
    } = useAnnualWheelDataMapping();

    const { refetchMenuNotifications } = useNavMenuDataMapping();
    const { taskManagementAndAnnualWheelPlan: taskManagerAndAnnualWheelPlan } = usePlanContext();
    const { customerName } = useUserContext();

    const tableGridProps: GridSizes = {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 4,
        xl: true,
    };

    function getLeftPageHeaderComponents() {
        return (
            <DotLegalSwitch
                checked={showListView}
                onChange={(showList) => setShowListView(showList)}
                leftLabel={translateString("calendar")}
                rightLabel={translateString("list")}
            />
        );
    }

    return (
        <React.Fragment>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={[{ name: translateString("annualWheel") }]}
                topLeftComponent={
                    <AnnualWheelYearPicker
                        showPreviousYear={data?.previousYearHasActivities}
                        sx={styles.yearPicker}
                        year={year}
                        onChange={setYear}
                        isLoading={isLoading}
                    />
                }
                leftComponent={getLeftPageHeaderComponents()}
            />
            <Grid
                container
                spacing={2}
                sx={(theme1) => ({
                    "& .MuiChip-label": {
                        fontWeight: "bold",
                    },
                })}
            >
                <Grid item xs={12} sm={7} md={6} lg={4} xl={3}>
                    <DotLegalPaper
                        sx={() => ({
                            backgroundImage: `url("${exportReportWave}")`,
                            ...styles.taskCounterPaper,
                            alignItems: "flex-start",
                            justifyContent: "center",
                        })}
                    >
                        <Box
                            sx={(theme) => ({
                                display: "flex",
                                "& .MuiButton-root": {
                                    marginRight: theme.spacing(2),
                                },
                            })}
                        >
                            {permissions.annualWheelPermission.create && (
                                <>
                                    <DotLegalButton
                                        buttonType={"primary"}
                                        onClick={() => {
                                            setMonth(1 + new Date().getMonth());
                                            setShowAnnualWheelDialog(true);
                                        }}
                                    >
                                        {translateString("attachActivity")}
                                    </DotLegalButton>
                                </>
                            )}
                            <PlanDialogButtonWrapper enabled={!taskManagerAndAnnualWheelPlan.annualWheelReport}>
                                <DotLegalButton
                                    isLoading={isDownloadingPdf || isDownloadingReport}
                                    disabled={isDownloadingPdf || isDownloadingReport || !taskManagerAndAnnualWheelPlan.annualWheelReport}
                                    onClick={handleClickDownloadMenu}
                                    buttonType={"secondary"}
                                    endIcon={!taskManagerAndAnnualWheelPlan.annualWheelReport ? PurpleLockIcon : <KeyboardArrowDownIcon />}
                                    toolTip={!taskManagerAndAnnualWheelPlan.annualWheelReport ? translateString("upgradePrivacyPlanToday") : undefined}
                                >
                                    {translateString("download")}
                                </DotLegalButton>
                                <Menu onClose={handleCloseDownloadMenu} anchorEl={anchorEl} open={downloadMenuOpen}>
                                    <MenuItem
                                        disabled={isDownloadingPdf}
                                        onClick={(_) => {
                                            onDownloadPdfClick().then();
                                            setDownloadMenuOpen(false);
                                        }}
                                    >
                                        {translateString("downLoadAnnualWheel")}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(_) => {
                                            onClickDownloadExcelReport().then();
                                            setDownloadMenuOpen(false);
                                        }}
                                    >
                                        {translateString("downloadReport")}
                                    </MenuItem>
                                </Menu>
                            </PlanDialogButtonWrapper>
                        </Box>
                    </DotLegalPaper>
                </Grid>
                <Grid item xs={12} sm={5} md={4} lg>
                    <Box sx={(theme1) => ({})}>
                        <DotLegalPaper
                            sx={{
                                backgroundImage: `url("${overdueTasksWave}")`,
                                outline: searchedStatus.includes(TaskStateEnum.Overdue.toString()) ? theme.palette.primary.dark + " solid" : "",
                                ...styles.taskCounterPaper,
                            }}
                            clickableRows
                            onClick={() => onBoxCounterClick(TaskStateEnum.Overdue)}
                        >
                            <DotLegalHeader headerStyle={"medium"} center>
                                {translateString("overdueActivities")}
                            </DotLegalHeader>
                            {!isLoading ? (
                                <DotLegalChip alternativeColor={overDueTaskCountColor()} value={data!.overdueTaskCount!.toString()}></DotLegalChip>
                            ) : (
                                <Skeleton>
                                    <DotLegalChip alternativeColor={totalTaskCounterColor()} value={"0"}></DotLegalChip>
                                </Skeleton>
                            )}
                        </DotLegalPaper>
                    </Box>
                </Grid>
                <Grid item xs={12} sm md={4} lg>
                    <DotLegalPaper
                        sx={{
                            backgroundImage: `url("${plannedTasksWave}")`,
                            outline: searchedStatus.includes(TaskStateEnum.Ready.toString()) ? theme.palette.primary.dark + " solid" : "",
                            ...styles.taskCounterPaper,
                        }}
                        clickableRows
                        onClick={() => onBoxCounterClick(TaskStateEnum.Ready)}
                    >
                        <Box
                            sx={(_) => ({
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            })}
                        >
                            <DotLegalHeader headerStyle={"medium"} center>
                                {translateString("plannedActivities")}
                            </DotLegalHeader>
                            {!isLoading ? (
                                <DotLegalChip alternativeColor={plannedTaskCountColor()} value={data!.plannedTaskCount!.toString()}></DotLegalChip>
                            ) : (
                                <Skeleton>
                                    <DotLegalChip alternativeColor={totalTaskCounterColor()} value={"0"}></DotLegalChip>
                                </Skeleton>
                            )}
                        </Box>
                    </DotLegalPaper>
                </Grid>
                <Grid item xs={12} sm md={4} lg>
                    <DotLegalPaper
                        sx={{
                            backgroundImage: `url("${completedTasksWave}")`,
                            outline: searchedStatus.includes(TaskStateEnum.Completed.toString()) ? theme.palette.primary.dark + " solid" : "",
                            ...styles.taskCounterPaper,
                        }}
                        clickableRows
                        onClick={() => onBoxCounterClick(TaskStateEnum.Completed)}
                    >
                        <DotLegalHeader headerStyle={"medium"} center>
                            {translateString("completedActivities")}
                        </DotLegalHeader>
                        {!isLoading ? (
                            <DotLegalChip alternativeColor={completedTaskCountColor()} value={data!.completedTaskCount!.toString()}></DotLegalChip>
                        ) : (
                            <Skeleton>
                                <DotLegalChip alternativeColor={totalTaskCounterColor()} value={"0"}></DotLegalChip>
                            </Skeleton>
                        )}
                    </DotLegalPaper>
                </Grid>
                <Grid item xs={12} sm md={4} lg>
                    <DotLegalPaper sx={{ backgroundImage: `url("${totalTasksWave}")`, ...styles.taskCounterPaper }}>
                        <DotLegalHeader headerStyle={"medium"} center>
                            {translateString("totalTasksForYear")}
                        </DotLegalHeader>
                        {!isLoading ? (
                            <DotLegalChip alternativeColor={totalTaskCounterColor()} value={data!.totalTaskCount!.toString()}></DotLegalChip>
                        ) : (
                            <Skeleton>
                                <DotLegalChip alternativeColor={totalTaskCounterColor()} value={"0"}></DotLegalChip>
                            </Skeleton>
                        )}
                    </DotLegalPaper>
                </Grid>
            </Grid>

            <Box sx={{ marginTop: theme.spacing(2) }}>
                <DotLegalPaper removePadding>
                    <Box sx={{ padding: 2 }}>
                        <Grid marginBottom={2} spacing={1} container width={"100%"}>
                            {hasActivities && (
                                <React.Fragment>
                                    <Grid {...tableGridProps} item>
                                        <DotLegalMultiSelect
                                            options={monthsFilterOptions}
                                            chosenOptions={searchedMonths}
                                            onChange={setSearchedMonths}
                                            placeholder={translateString("month")}
                                            label={translateString("month")}
                                            noOptionsLabel={translateString("noOptions")}
                                            noMargin
                                        />
                                    </Grid>
                                    <Grid {...tableGridProps} item>
                                        <DotLegalMultiSelect
                                            options={prioritiesFilterOptions}
                                            chosenOptions={searchedPriorities}
                                            onChange={setSearchedPriorities}
                                            placeholder={translateString("priority")}
                                            label={translateString("priority")}
                                            noOptionsLabel={translateString("noOptions")}
                                            noMargin
                                        />
                                    </Grid>
                                    <Grid {...tableGridProps} item>
                                        <DotLegalMultiSelect
                                            options={statusFilterOptions}
                                            chosenOptions={searchedStatus}
                                            onChange={setSearchedStatus}
                                            placeholder={translateString("status")}
                                            label={translateString("status")}
                                            noOptionsLabel={translateString("noOptions")}
                                            noMargin
                                        />
                                    </Grid>
                                    {taskManagerAndAnnualWheelPlan.annualWheelAreas && (
                                        <Grid {...tableGridProps} item>
                                            <DotLegalMultiSelect
                                                options={areasFilterOptions}
                                                chosenOptions={searchedAreas}
                                                onChange={setSearchedAreas}
                                                placeholder={translateString("area")}
                                                label={translateString("area")}
                                                noOptionsLabel={translateString("noOptions")}
                                                noMargin
                                            />
                                        </Grid>
                                    )}

                                    <Grid {...tableGridProps} item>
                                        <DotLegalMultiSelect
                                            options={businessAreasFilterOptions}
                                            chosenOptions={searchedBusinessAreas}
                                            onChange={setSearchedBusinessAreas}
                                            placeholder={translateString("businessArea")}
                                            label={translateString("businessArea")}
                                            noOptionsLabel={translateString("noOptions")}
                                            noMargin
                                        />
                                    </Grid>
                                    <Grid {...tableGridProps} item>
                                        <DotLegalMultiSelect
                                            options={responsiblesFilterOptions}
                                            chosenOptions={searchedResponisbles}
                                            onChange={setSearchedResponsibles}
                                            placeholder={translateString("responsible")}
                                            label={translateString("responsible")}
                                            noOptionsLabel={translateString("noOptions")}
                                            noMargin
                                        />
                                    </Grid>
                                    <Grid {...tableGridProps} item>
                                        <DotLegalMultiSelect
                                            options={groupCompanyOptions}
                                            chosenOptions={
                                                groupCompanyOptions.length === 2
                                                    ? [groupCompanyOptions[0].id, groupCompanyOptions[1].id]
                                                    : searchedGroupCompanies
                                            }
                                            onChange={setSearchedGroupCompanies}
                                            placeholder={translateString("groupCompanies")}
                                            label={translateString("groupCompanies")}
                                            noOptionsLabel={translateString("noOptions")}
                                            disabled={groupCompanyOptions.length === 2}
                                            noMargin
                                        />
                                    </Grid>
                                    <Grid {...tableGridProps} item sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <DotLegalSearchField
                                            value={searchedString}
                                            onChange={setSearchedString}
                                            labelSearch={translateString("search")}
                                            useFullWidth
                                        />
                                    </Grid>
                                </React.Fragment>
                            )}
                        </Grid>
                        {showListView ? (
                            <AnnualWheelListOverview
                                setShowDeleteActivityDialog={setShowDeleteActivityDialog}
                                activties={getFilteredData(data?.annualWheelActivities ?? [])}
                                isLoading={isLoading}
                                setSelectedActivity={setActivity}
                                setShowAnnualWheelDialog={setShowAnnualWheelDialog}
                                getTaskManagementUrlForTask={getTaskManagementUrlForTask}
                                hasActivities={hasActivities}
                            />
                        ) : (
                            <AnnualWheelCalendarOverview
                                activities={getFilteredData(data?.annualWheelActivities ?? [])}
                                isLoading={isLoading}
                                setMonth={setMonth}
                                setSelectedActivity={setActivity}
                                setShowAnnualWheelDialog={setShowAnnualWheelDialog}
                                year={year}
                                setShowDeleteActivityDialog={setShowDeleteActivityDialog}
                                getTaskManagementUrlForMonthAndYear={getTaskManagementUrlForMonth}
                                getTaskManagementUrlForTask={getTaskManagementUrlForTask}
                                extendColumns={!isNullOrWhitespace(searchedString)}
                            />
                        )}
                    </Box>
                </DotLegalPaper>
            </Box>

            {showAnnualWheelDialog && (
                <AnnualWheelDialog
                    setShowDialog={setShowAnnualWheelDialog}
                    onSave={refetchData}
                    selectedYear={year}
                    selectedMonth={month}
                    activityId={activity?.id}
                    activityName={activity?.name}
                    onDialogClose={() => {
                        setShowAnnualWheelDialog(false);
                        setActivity(undefined);
                    }}
                    templateId={activity?.templateId}
                />
            )}

            {showDeleteActivityDialog.showDialog && (
                <DeleteAnnualWheelActivityDialog
                    activityId={activity!.id}
                    activityName={showDeleteActivityDialog.activityName}
                    onCloseDialog={() => {
                        setShowDeleteActivityDialog({ showDialog: false, activityName: "" });
                        setActivity(undefined);
                    }}
                    onDeleteClick={() => {
                        refetchData();
                        refetchMenuNotifications().then();
                        setShowDeleteActivityDialog({ showDialog: false, activityName: "" });
                        setActivity(undefined);
                    }}
                    hardDelete={activity?.deleted}
                />
            )}
        </React.Fragment>
    );
}

export default AnnualWheel;
