import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared.ts";
import { ITasksCountWidgetProps } from "./TasksCountWidget.tsx";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider.tsx";
import { DashboardTaskOverviewViewModel } from "../taskOverviewWidget/TaskOverviewWidget.types.ts";
import { useState } from "react";

export function useTasksCountWidget(props: ITasksCountWidgetProps) {
    const userContext = useUserContext();
    const isEnabled = userContext.permissions.taskPermissions.read;
    const [counterValue, setCounterValue] = useState(0);

    const url = `/taskManagement/dashboard/tasksCount?year=${new Date().getFullYear()}&onlyUserSpecific=${props.onlyUserSpecific}&groupentityid=${props.selectedGroupCompany}`;
    const { isLoading, data } = useQuery(url, () => get<DashboardTaskOverviewViewModel>(url), { enabled: isEnabled });

    return {
        isLoading,
        data,
        isEnabled,
        counterValue,
        setCounterValue,
    };
}
