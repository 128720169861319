import { SelectableDataCategoryItem, SelectableLegalUnitsItem } from "../processingActivity/ProcessingActivity.types";
import { SharingOrigin } from "../processingActivity/processingActivityOverview/ProcessingActivityOverview.types";
import { UserSelectableItem } from "../user/User.types";
import { LevelOfProtectionType } from "./addLegalEntityDialog/AddLegalEntityDialog.types";

export interface LegalEntitySelectorViewModel {
    name: string;
    address: string;
    city: string;
    zipCode: string;
    country: string;
    id: string;
    isActive: boolean;
    users?: Array<UserSelectableItem>;
}

export interface LegalEntitySharingsViewModel {
    sharingRows: Array<LegalEntitySharingRowsModel>;
    dataCategories: Array<SelectableDataCategoryItem>;
    senders: Array<SelectableLegalUnitsItem>;
    recipients: Array<SelectableLegalUnitsItem>;
}

export interface LegalEntitySharingRowsModel {
    id: string;
    name: string;
    description: string;
    senderName: string;
    senderId: string;
    senderRole: string;
    dataCategories: Array<SelectableDataCategoryItem>;
    recipientName: string;
    recipientId: string;
    recipientRole: string;
    recipientInThirdCountry: boolean;
    subStep: number;
    sharingId: string;
    recipientLevelOfProtection: LevelOfProtectionType;
    sharingOrigin: SharingOrigin;
}

export enum LegalEntityAuditTabs {
    Classifications = 0,
    Assets = 1,
    Sharings = 2,
    Audits = 3,
    Documents = 4,
    Contacts = 5,
}
