import React from "react";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import {
    DotLegalActionBar,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalTableWithControls,
    DotLegalSwitch,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { AddEditDeletionPeriodDialog } from "../addEditDeletionPeriodDialog/AddEditDeletionPeriodDialog";
import { useDeletionPeriodsHooks } from "./DeletionPeriods.hooks";
import { useDeletionPeriodsStyles } from "./DeletionPeriods.styles";
import { DeletionPeriodFrequencyType, DeletionPeriodViewModel } from "./DeletionPeriods.types";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { DeleteDeletionPeriodDialog } from "../deleteDeletionPeriodDialog/DeleteDeletionPeriodDialog";
import { ChangeDeletionPeriodStatusDialog } from "../changeDeletionPeriodStatusDialog/ChangeDeletionPeriodStatusDialog";
import { Box, TableCell, TableRow } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider";
import { getMasterIcon } from "../../MasterData.hooks";

export function DeletionPeriods() {
    const { permissions, customerName } = useUserContext();
    const { translateString } = useTranslation();
    const styles = useDeletionPeriodsStyles();
    const { getBreadCrumbs } = useMasterDataBreadCrumbProvider();

    const {
        isLoading,
        deletionPeriods,
        showAllDeletionsPeriods,
        setShowAllDeletionsPeriods,
        showAddEditDialog,
        setShowAddEditDialog,
        showDeleteDeletionPeriodDialog,
        setShowDeleteDeletionPeriodDialog,
        selectedDeletionPeriod,
        setSelectedDeletionPeriod,
        refetchDeletionPeriods,
        showChangeStatusDialog,
        setShowChangeStausDialog,
    } = useDeletionPeriodsHooks();

    const getMenuItems = (dp: DeletionPeriodViewModel) => {
        let menuItems = [];
        if (permissions.canManageMasterData) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedDeletionPeriod({
                        name: dp.name,
                        description: dp.description,
                        id: dp.id,
                        deletionPeriodLength: dp.deletionPeriodLength,
                        deletionPeriodType: Number(dp.deletionPeriodType!.toString()),
                        active: dp.active,
                        isCustom: dp.isCustom,
                    });
                    setShowAddEditDialog(true);
                },
            });

            menuItems.push({
                menuItem: dp.active ? translateString("deactivate") : translateString("activate"),
                onClick: () => {
                    setSelectedDeletionPeriod({
                        name: dp.name,
                        description: dp.description,
                        id: dp.id,
                        deletionPeriodLength: dp.deletionPeriodLength,
                        deletionPeriodType: Number(dp.deletionPeriodType!.toString()),
                        active: dp.active,
                        isCustom: dp.isCustom,
                    });
                    setShowChangeStausDialog(true);
                },
            });
        }

        if (permissions.canManageMasterData && dp.isCustom) {
            menuItems.push({
                menuItem: translateString("removeDeletionPeriod"),
                onClick: () => {
                    setSelectedDeletionPeriod({
                        name: dp.name,
                        description: dp.description,
                        id: dp.id,
                        deletionPeriodLength: dp.deletionPeriodLength,
                        deletionPeriodType: Number(dp.deletionPeriodType!.toString()),
                        active: dp.active,
                        isCustom: dp.isCustom,
                    });
                    setShowDeleteDeletionPeriodDialog(true);
                },
            });
        }
        return menuItems;
    };

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={getBreadCrumbs(translateString("deletionPeriods"))}
                leftComponent={
                    <DotLegalSwitch
                        onChange={setShowAllDeletionsPeriods}
                        checked={showAllDeletionsPeriods}
                        leftLabel={translateString("active")}
                        rightLabel={translateString("all")}
                    />
                }
            />
            <div>
                <DotLegalActionBar
                    canCreate={permissions.canManageMasterData}
                    buttonText={translateString("createDeletionPeriod")}
                    onOpenDialog={() => {
                        setShowAddEditDialog(true);
                        setSelectedDeletionPeriod(undefined);
                    }}
                    showDialog={showAddEditDialog}
                    icon={getMasterIcon()}
                >
                    <AddEditDeletionPeriodDialog
                        deletionPeriod={selectedDeletionPeriod}
                        isAdd={selectedDeletionPeriod === undefined}
                        showGlobal
                        open={showAddEditDialog}
                        onDialogClose={() => setShowAddEditDialog(false)}
                        onOkClick={() => setShowAddEditDialog(false)}
                        refetch={refetchDeletionPeriods}
                    />
                </DotLegalActionBar>
            </div>
            <DotLegalPaper>
                <DotLegalTableWithControls
                    headers={getHeaders(translateString)}
                    isLoading={isLoading}
                    defaultOrderBy={"name"}
                    defaultOrder={"asc"}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    clickableRows={false}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    emptyText={translateString("noData")}
                    data={deletionPeriods}
                    renderRow={(row, i) => (
                        <TableRow key={i}>
                            <TableCell sx={styles.bold}>{row.name}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{translateString(DeletionPeriodFrequencyType[row.deletionPeriodType as number].toLowerCase())}</TableCell>
                            <TableCell>{row.deletionPeriodLength?.toString() ?? ""}</TableCell>
                            <TableCell>
                                <Box sx={styles.iconContainer}>{row.active ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={styles.iconContainer}>{!row.isCustom ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                            </TableCell>
                            <TableCell align={"right"}>
                                <DotLegalOverflowMenu items={getMenuItems(row)} />
                            </TableCell>
                        </TableRow>
                    )}
                />
            </DotLegalPaper>

            {showDeleteDeletionPeriodDialog && (
                <DeleteDeletionPeriodDialog
                    selectedDeletionPeriod={selectedDeletionPeriod!}
                    onSaveCorrect={refetchDeletionPeriods}
                    onCloseDialog={() => setShowDeleteDeletionPeriodDialog(false)}
                />
            )}

            {showChangeStatusDialog && (
                <ChangeDeletionPeriodStatusDialog
                    selectedDeletionPeriod={selectedDeletionPeriod!}
                    refetch={refetchDeletionPeriods}
                    onCloseDialog={() => setShowChangeStausDialog(false)}
                />
            )}
        </Box>
    );
}

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<DeletionPeriodViewModel>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "description", text: translateString("description"), align: "left", showOnMobile: true });
    headers.push({ property: "deletionPeriodType", text: translateString("deletionPeriodType"), align: "left", showOnMobile: true });
    headers.push({ property: "deletionPeriodLength", text: translateString("deletionPeriodLength"), align: "left", showOnMobile: true });
    headers.push({ property: "active", text: translateString("active"), align: "left", showOnMobile: true });
    headers.push({ property: "isCustom", text: translateString("standardMasterData"), align: "left", showOnMobile: true });

    return headers;
}
