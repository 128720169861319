import { Box, Icon } from "@mui/material";
import React from "react";
import { LegalEntityAuditStatus } from "../../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { DotLegalTooltip } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../../localization/useTranslation";
import { auditInProgress, closed, greenCheckmark, closedBlue } from "../../../../common/icons";

export interface IQuestionnaireStatus {
    showQuestionsStatistics: boolean;
    numberOfQuestionsAnswered: number;
    totalNumberOfQuestions: number;
    lastestRecipientUpdate?: Date;
    status: LegalEntityAuditStatus;
}

function QuestionnaireStatus(props: IQuestionnaireStatus) {
    const { translateLegalEntityAuditStatus, translateString, translateDate } = useTranslation();

    const styles = {
        wrapper: {
            display: "flex",
            alignItems: "center",
            gap: "5px",
        },
        statusText: {
            marginTop: "5px",
        },
    };

    function translateStatus(status: LegalEntityAuditStatus) {
        let icon = auditInProgress;
        if (status === LegalEntityAuditStatus.DoesNotWantToRespond || status === LegalEntityAuditStatus.Other) {
            icon = closed;
        } else if (status === LegalEntityAuditStatus.Submitted) {
            icon = greenCheckmark;
        } else if (status === LegalEntityAuditStatus.AuditOpinion || status === LegalEntityAuditStatus.DoesNotNeedToRespond) {
            icon = closedBlue;
        }

        return (
            <Box sx={styles.wrapper}>
                {getIcon(icon)}
                <Box sx={styles.statusText}>{translateLegalEntityAuditStatus(status.toString())}</Box>
            </Box>
        );
    }

    function getQuestionsStatistics() {
        return (
            <Box sx={styles.wrapper}>
                {getIcon(auditInProgress)}
                <Box sx={styles.statusText}>{`${props.numberOfQuestionsAnswered}/${props.totalNumberOfQuestions}`}</Box>
            </Box>
        );
    }

    function getIcon(icon: JSX.Element) {
        return (
            <Icon
                sx={{
                    "& svg": {
                        height: 24,
                    },
                }}
            >
                {icon}
            </Icon>
        );
    }

    function getStatus() {
        let status = props.showQuestionsStatistics ? getQuestionsStatistics() : translateStatus(props.status);

        if (props.lastestRecipientUpdate) {
            return (
                <DotLegalTooltip
                    text={
                        <React.Fragment>
                            {props.status !== LegalEntityAuditStatus.Submitted && (
                                <Box sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>{translateString("latestActive")}</Box>
                            )}
                            <Box>{translateDate(props.lastestRecipientUpdate)}</Box>
                        </React.Fragment>
                    }
                >
                    {status}
                </DotLegalTooltip>
            );
        }

        return status;
    }

    return <React.Fragment>{getStatus()}</React.Fragment>;
}

export default QuestionnaireStatus;
