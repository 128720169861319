import React from "react";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../../localization/useTranslation";
import { useUsersDataMapping } from "./Users.hooks";
import { useUsersStyles } from "./Users.styles";
import UserIcon from "../users.svg?react";
import { UserViewModel } from "../User.types";
import {
    DotLegalActionBar,
    DotLegalButton,
    DotLegalMultiSelect,
    DotLegalPageHeader,
    DotLegalTableWithControls,
    DotLegalSwitch,
    useIsOnSmallScreen,
    useIsOnMediumScreen,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { Box, Grid, GridSize, TableCell, TableRow } from "@mui/material";
import { greenArrowRight, PurpleLockIcon, WhiteLockIcon } from "../../common/icons";
import AddEditUserDialog from "../addEditUserDialog/AddEditUserDialog";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import PlanDialogButtonWrapper from "../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import TransferUserResponsibility from "../transferUserResponsibility/TransferUserResponsibility";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";

function Users() {
    const styles = useUsersStyles();
    const isSmallScreen = useIsOnSmallScreen();
    const isOnMediumScreen = useIsOnMediumScreen();
    const { customerName } = useUserContext();
    const { technicalPlan } = usePlanContext();
    const { translateString } = useTranslation();
    const {
        isLoading,
        users,
        showAllUsers,
        setShowAllUsers,
        showAddUserDialog,
        setShowAddUserDialog,
        searchedBusinessAreas,
        setSearchedBusinessAreas,
        searchedUserRoles,
        setSearchedUserRoles,
        businessAreaFilterOptions,
        userRolesFilterOptions,
        handleUserAddSuccess,
        goToUser,
        hasUsers,
        userLimitReached,
        getBreadCrumbs,
        setShowTransferResponsbilityDialog,
        showTransferResponsbilityDialog,
    } = useUsersDataMapping();

    function getAdditionalFilters() {
        const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize } = {
            item: true,
            xs: 12,
            sm: 6,
            md: 4,
            lg: 2,
        };

        return (
            <React.Fragment>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedBusinessAreas}
                        chosenOptions={searchedBusinessAreas}
                        label={translateString("processingActivityAreas")}
                        placeholder={translateString("processingActivityAreas")}
                        isLoading={isLoading}
                        options={businessAreaFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid {...gridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedUserRoles}
                        chosenOptions={searchedUserRoles}
                        label={translateString("roles")}
                        placeholder={translateString("roles")}
                        isLoading={isLoading}
                        options={userRolesFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    const formatUserGroupEntities = (row: UserViewModel) => {
        if (row.gdpo) return translateString("all");

        const numberOfGroupEntities = row.userGroupEntityNames.length;
        if (numberOfGroupEntities === 0) return "";

        const maxNumberOfGroupEntitiesToShow = isSmallScreen || isOnMediumScreen ? 1 : 5;
        let extra;
        if (numberOfGroupEntities > maxNumberOfGroupEntitiesToShow) {
            extra = ` +${numberOfGroupEntities - maxNumberOfGroupEntitiesToShow}`;
        }
        const names = row.userGroupEntityNames.slice(0, maxNumberOfGroupEntitiesToShow).join(", ");
        return (
            <Box component={"span"} sx={{ maxWidth: 450 }}>
                {names}
                <Box component={"b"}>{extra}</Box>
            </Box>
        );
    };

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{
                    customerName: customerName,
                }}
                breadCrumbs={getBreadCrumbs()}
                leftComponent={
                    <DotLegalSwitch
                        onChange={setShowAllUsers}
                        checked={showAllUsers}
                        leftLabel={translateString("active")}
                        rightLabel={translateString("all")}
                    />
                }
            />
            <div>
                <DotLegalActionBar
                    canCreate={userLimitReached !== undefined}
                    showDialog={showAddUserDialog}
                    icon={<UserIcon />}
                    primaryButton={
                        <PlanDialogButtonWrapper enabled={userLimitReached ?? false}>
                            <DotLegalButton
                                buttonType="primary"
                                size="medium"
                                onClick={() => setShowAddUserDialog(true)}
                                disabled={userLimitReached}
                                endIcon={userLimitReached ? WhiteLockIcon : undefined}
                                toolTip={userLimitReached ? translateString("upgradePrivacyPlanToday") : undefined}
                            >
                                {translateString("createUser")}
                            </DotLegalButton>
                        </PlanDialogButtonWrapper>
                    }
                    additionalButtons={[
                        <PlanDialogButtonWrapper key={0} enabled={!technicalPlan.transferUserResponsibility}>
                            <DotLegalButton
                                key={0}
                                onClick={() => setShowTransferResponsbilityDialog(true)}
                                buttonType={"secondary"}
                                disabled={!technicalPlan.transferUserResponsibility}
                                endIcon={!technicalPlan.transferUserResponsibility ? PurpleLockIcon : undefined}
                                toolTip={!technicalPlan.transferUserResponsibility ? translateString("upgradePrivacyPlanToday") : undefined}
                            >
                                {translateString("transferReponsibility")}
                            </DotLegalButton>
                        </PlanDialogButtonWrapper>,
                    ]}
                >
                    <AddEditUserDialog isEdit={false} onCloseDialog={() => setShowAddUserDialog(false)} onSaveCorrect={handleUserAddSuccess} />
                </DotLegalActionBar>
            </div>

            <Box sx={styles.paperContainer}>
                <DotLegalPaper>
                    {hasUsers ? (
                        <DotLegalTableWithControls
                            extraControls={getAdditionalFilters()}
                            headers={getHeaders(translateString)}
                            getUserSpecificPageLength={() => 30}
                            hideRowsPerPage
                            defaultOrderBy={"firstName"}
                            defaultOrder={"asc"}
                            isLoading={isLoading}
                            emptyText={translateString("noUsersMatchesQuery")}
                            data={users}
                            renderRow={(row, i) => (
                                <TableRow onClick={() => goToUser(row.id)} key={i} sx={styles.tableRow}>
                                    <TableCell sx={styles.bold}>{row.fullName}</TableCell>
                                    <TableCell>{row.emailAddress}</TableCell>
                                    <TableCell>{row.businessAreas.map((x) => x.name).join(", ")}</TableCell>
                                    <TableCell>{row.userRoles.map((x) => x.name).join(", ")}</TableCell>
                                    <TableCell>
                                        <Box sx={styles.iconContainer}>{formatUserGroupEntities(row)}</Box>
                                    </TableCell>
                                    <TableCell>{row?.lastLogin}</TableCell>
                                    <TableCell>
                                        <div className={`${styles.greenArrowContainer} ${styles.iconContainer}`}>{greenArrowRight}</div>
                                    </TableCell>
                                </TableRow>
                            )}
                            labelRowsPerPage={translateString("showEntites")}
                            labelSearch={translateString("search")}
                            noOptionsLabel={translateString("noOptions")}
                            paginationLabelOf={translateString("labelOf")}
                        />
                    ) : (
                        <DotLegalEmptyState icon={<UserIcon />} text={translateString("noUsers")} />
                    )}
                </DotLegalPaper>
            </Box>
            {showTransferResponsbilityDialog && <TransferUserResponsibility onDialogClose={() => setShowTransferResponsbilityDialog(false)} />}
        </Box>
    );
}

export default Users;

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<UserViewModel>>();
    headers.push({ property: "firstName", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "emailAddress", text: translateString("emailAddress"), align: "left", showOnMobile: true });
    headers.push({ property: "businessAreas", text: translateString("processingActivityAreas"), align: "left", showOnMobile: true });
    headers.push({ property: "userRoles", text: translateString("roles"), align: "left", showOnMobile: true });
    headers.push({ property: "gdpo", text: translateString("groupEntities"), align: "left", showOnMobile: true });
    headers.push({ property: "lastLogin", text: translateString("lastLogin"), align: "left", showOnMobile: true });

    return headers;
}
