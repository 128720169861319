import { Box, TableCell, TableRow } from "@mui/material";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import {
    DotLegalActionBar,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalTableWithControls,
    DotLegalSwitch,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { BusinessAreaViewModel } from "./BusinessAreas.types";
import CheckIcon from "@mui/icons-material/Check";
import { useBusinessAreasDataMapping } from "./BusinessAreas.hooks";
import React from "react";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useBusinessAreaStyles } from "./BusinessAreas.styles";
import BusinessAreaDialog from "../businessAreaDialog/BusinessAreaDialog";
import { DeleteBusinessAreaDialog } from "../deleteBusinessAreaDialog/DeleteBusinessAreaDialog";
import { ChangeBusinessAreaStatusDialog } from "../changeBusinessAreaStatusDialog/ChangeBusinessAreaStatusDialog";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider";
import { getMasterIcon } from "../../MasterData.hooks";

function BusinessAreas() {
    const styles = useBusinessAreaStyles();
    const { translateString } = useTranslation();
    const { permissions, customerName } = useUserContext();
    const { getBreadCrumbs } = useMasterDataBreadCrumbProvider();

    const {
        isLoading,
        data,
        setShowAllBusinessAreas,
        showAllBusinessAreas,
        showBusinessAreaDialog,
        setShowBusinessAreaDialog,
        showDeleteBusinessAreaDialog,
        setShowDeleteBusinessAreaDialog,
        OnSave,
        selectedBusinessArea,
        setSelectedBusinessArea,
        setShowChangeStausDialog,
        showChangeStatusDialog,
    } = useBusinessAreasDataMapping();

    const getMenuItems = (businessArea: BusinessAreaViewModel) => {
        let menuItems = [];

        if (permissions.canManageMasterData) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedBusinessArea({
                        name: businessArea.name,
                        description: businessArea.description,
                        id: businessArea.id,
                        active: businessArea.active,
                        color: businessArea.color,
                        isCustom: businessArea.isCustom,
                    });
                    setShowBusinessAreaDialog(true);
                },
            });

            menuItems.push({
                menuItem: businessArea.active ? translateString("deactivate") : translateString("activate"),
                onClick: () => {
                    setSelectedBusinessArea({
                        name: businessArea.name,
                        description: businessArea.description,
                        id: businessArea.id,
                        active: businessArea.active,
                        color: businessArea.color,
                        isCustom: businessArea.isCustom,
                    });
                    setShowChangeStausDialog(true);
                },
            });
        }

        if (permissions.canManageMasterData && businessArea.isCustom) {
            menuItems.push({
                menuItem: translateString("removeBusinessArea"),
                onClick: () => {
                    setSelectedBusinessArea({
                        name: businessArea.name,
                        description: businessArea.description,
                        id: businessArea.id,
                        active: businessArea.active,
                        color: businessArea.color,
                        isCustom: businessArea.isCustom,
                    });
                    setShowDeleteBusinessAreaDialog(true);
                },
            });
        }

        return menuItems;
    };

    const openCreateDialog = () => {
        setSelectedBusinessArea(undefined);
        setShowBusinessAreaDialog(true);
    };

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={getBreadCrumbs(translateString("processingActivityAreas"))}
                leftComponent={
                    <DotLegalSwitch
                        onChange={setShowAllBusinessAreas}
                        checked={showAllBusinessAreas}
                        leftLabel={translateString("active")}
                        rightLabel={translateString("all")}
                    />
                }
            />

            <div>
                <DotLegalActionBar
                    canCreate={permissions.canManageMasterData}
                    buttonText={translateString("createBusinessArea")}
                    onOpenDialog={openCreateDialog}
                    showDialog={showBusinessAreaDialog}
                    icon={getMasterIcon()}
                >
                    {showBusinessAreaDialog && (
                        <BusinessAreaDialog onHideDialog={() => setShowBusinessAreaDialog(false)} onSave={OnSave} selectedBusinessArea={selectedBusinessArea} />
                    )}
                </DotLegalActionBar>
            </div>

            <Box sx={styles.paperContainer}>
                <DotLegalPaper>
                    <DotLegalTableWithControls
                        headers={getHeaders(translateString)}
                        getUserSpecificPageLength={() => 30}
                        hideRowsPerPage
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        clickableRows={false}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        emptyText={translateString("noData")}
                        data={data}
                        renderRow={(row, i) => (
                            <TableRow key={row.id}>
                                <TableCell sx={styles.bold}>{row.name}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>
                                    <Box sx={styles.iconContainer}>{row.active ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={styles.iconContainer}>{!row.isCustom ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                                </TableCell>
                                <TableCell align={"right"}>
                                    <DotLegalOverflowMenu items={getMenuItems(row)} />
                                </TableCell>
                            </TableRow>
                        )}
                    />
                </DotLegalPaper>
            </Box>

            {showDeleteBusinessAreaDialog && (
                <DeleteBusinessAreaDialog
                    selectedBusinessArea={selectedBusinessArea!}
                    onDeletion={OnSave}
                    onCloseDialog={() => setShowDeleteBusinessAreaDialog(false)}
                />
            )}

            {showChangeStatusDialog && (
                <ChangeBusinessAreaStatusDialog
                    selectedBusinessArea={selectedBusinessArea!}
                    OnSave={OnSave}
                    onCloseDialog={() => setShowChangeStausDialog(false)}
                />
            )}
        </Box>
    );
}

export default BusinessAreas;

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<BusinessAreaViewModel>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "description", text: translateString("description"), align: "left", showOnMobile: true });
    headers.push({ property: "active", text: translateString("active"), align: "left", showOnMobile: true });
    headers.push({ property: "isCustom", text: translateString("standardMasterData"), align: "left", showOnMobile: true });

    return headers;
}
