import { useUserContext } from "../../auth/userContextProvider/UserContextProvider.tsx";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared.ts";
import { GdprIncidentLogCountBySeverityViewModel } from "./IncidentLogWidget.types.ts";
import { useEffect, useState } from "react";

export function UseIncidentLogWidget() {
    const userContext = useUserContext();
    const isEnabled = userContext.permissions.gdprIncidentLogPermissions.read;
    const [graphData, setGraphData] = useState([{ severity: 0, numberOfIncidents: 0 }]);

    const url = `/gdprIncidentLog/dashboard/bySeverity`;
    const { isLoading, data } = useQuery(url, () => get<Array<GdprIncidentLogCountBySeverityViewModel>>(url), { enabled: isEnabled });

    let highestNumberOfIncidents = 0;
    if (data) {
        const numberOfIncidents = data!.map((x) => x.numberOfIncidents);
        highestNumberOfIncidents = Math.max(...numberOfIncidents);
    }

    useEffect(() => {
        if (data) {
            setGraphData(data);
        }
    }, [data]);

    return {
        isLoading,
        data: graphData ?? [],
        isEnabled,
        highestNumberOfIncidents,
    };
}
