import { QuestionType } from "./RiskAssessment.types";
import { useTranslation } from "../localization/useTranslation";

export function useQuestionToolTip() {
    const { translateString } = useTranslation();

    const getToolTip = (questionType: QuestionType) => {
        const translationKey = `${QuestionType[questionType]}Info`;

        let translation = translateString(translationKey);
        if (translation === QuestionType[questionType].toString()) return "";
        if (translation === translationKey) return "";

        return translation;
    };

    return {
        getQuestionToolTip: getToolTip,
    };
}
