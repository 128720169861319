import { useQuery } from "react-query";
import { get } from "../../../common/api/apiShared";
import { useTranslation } from "../../../localization/useTranslation";
import { SystemType } from "../../systemsStep/SystemsStep.types";
import { SystemViewItem } from "../ProcessingActivityOverview.types";
import { ITableHeader } from "@dotlegal/dotlegal-ui-components";

export function UseProcessingActivityOverViewSystemTable(processingActivityId: string | undefined) {
    const { translateString } = useTranslation();

    let headers: Array<ITableHeader<SystemViewItem>> = [
        { property: "systemName", text: translateString("systems"), align: "left", showOnMobile: true },
        { property: "systemType", text: translateString("type"), align: "left", showOnMobile: true },
    ];

    let { isLoading, data } = useQuery("processingOverviewSystemTable" + processingActivityId, () =>
        get<Array<{ systemName: string; systemType: SystemType }>>("/processingactivityoverview/systems/" + processingActivityId)
    );

    let systems;
    if (data) {
        systems = data.map((system) => {
            return { systemName: system.systemName, systemType: translateString(SystemType[system.systemType].toLowerCase()) };
        });
    }

    return {
        headers,
        isLoading,
        systems,
    };
}
