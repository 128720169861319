import { DotLegalActionBar, DotLegalOverflowMenu, DotLegalPageHeader, DotLegalTableWithControls, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { TableCell, TableRow } from "@mui/material";
import React from "react";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../../../localization/useTranslation";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider";
import HelpTextDeleteDialog from "../helpTextDeleteDialog/HelpTextDeleteDialog";
import HelpTextDialog from "../helpTextDialog/HelpTextDialog";
import { useHelpTextDataMapping } from "./HelpTexts.hooks";
import { HelpTextViewModel } from "./HelpTexts.types";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import EmptyStateSvg from "../../../system/systems/noSystems.svg?react";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";

function HelpTexts() {
    const { getBreadCrumbs } = useMasterDataBreadCrumbProvider();
    const { translateString, translateHelpTextTypes } = useTranslation();
    const { customerName } = useUserContext();
    const {
        data,
        isLoading,
        refetch,
        setShowDialog,
        showDialog,
        selectedHelpText,
        setSelectedHelpText,
        getEmptyHelpText,
        showDeleteDialog,
        setShowDeleteDialog,
        hasHelpTexts,
    } = useHelpTextDataMapping();

    const getMenuItems = (row: HelpTextViewModel) => {
        let menuItems = [];

        menuItems.push({
            menuItem: translateString("edit"),
            onClick: () => {
                setSelectedHelpText({ content: row.content, header: row.header, id: row.id, type: row.type });
                setShowDialog(true);
            },
        });

        menuItems.push({
            menuItem: translateString("delete"),
            onClick: () => {
                setSelectedHelpText({ content: "", header: row.header, id: row.id, type: undefined });
                setShowDeleteDialog(true);
            },
        });

        return menuItems;
    };

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName }} breadCrumbs={getBreadCrumbs(translateString("helpTexts"))} />
            <DotLegalActionBar
                canCreate
                buttonText={translateString("createHelpText")}
                onOpenDialog={() => {
                    setSelectedHelpText(getEmptyHelpText());
                    setShowDialog(true);
                }}
                showDialog={showDialog}
            >
                <HelpTextDialog
                    onDialogClose={() => setShowDialog(false)}
                    onSave={() => refetch()}
                    selectedHelpText={selectedHelpText}
                    usedHelpTextTypes={data?.map((x) => x.type) ?? []}
                ></HelpTextDialog>
            </DotLegalActionBar>

            <DotLegalPaper>
                {hasHelpTexts ? (
                    <DotLegalTableWithControls
                        headers={getHeaders(translateString)}
                        getUserSpecificPageLength={() => 30}
                        hideRowsPerPage
                        defaultOrderBy={"header"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        emptyText={translateString("noData")}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        data={data}
                        renderRow={(row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.header}</TableCell>
                                <TableCell>{row.content}</TableCell>
                                <TableCell>{translateHelpTextTypes(row.type.toString())}</TableCell>
                                <TableCell align={"right"}>
                                    <DotLegalOverflowMenu items={getMenuItems(row)} />
                                </TableCell>
                            </TableRow>
                        )}
                    />
                ) : (
                    <DotLegalEmptyState icon={<EmptyStateSvg />} text={translateString("noHelpTexts")} />
                )}
            </DotLegalPaper>

            {showDeleteDialog && (
                <HelpTextDeleteDialog closeDialog={() => setShowDeleteDialog(false)} onDeleted={() => refetch()} selectedHelpText={selectedHelpText} />
            )}
        </React.Fragment>
    );
}

export default HelpTexts;

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<HelpTextViewModel>>();
    headers.push({ property: "header", text: translateString("header"), align: "left", showOnMobile: true });
    headers.push({ property: "content", text: translateString("content"), align: "left", showOnMobile: true });
    headers.push({ property: "type", text: translateString("type"), align: "left", showOnMobile: true });

    return headers;
}
