import React from "react";
import { useDataControllerRecordStyles } from "./DataControllerRecord.styles";
import { TableCell, TableRow } from "@mui/material";
import { DataControllerRecordDataSubject, DataControllerRecordModel, DataControllerRecordRecipient } from "./DataControllerRecord.types";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import { DotLegalTableWithControls, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../localization/useTranslation";
import { useUrlProvider } from "../useUrlProvider";
import { Box } from "@mui/system";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import EmptyRecord from "../record/emptyRecord.svg?react";
import DotLegalLink from "../common/components/dotLegalLink/DotLegalLink";
import { ControllerProcess } from "../processingActivity/processingActivityOverview/ProcessingActivityOverview.types";

export interface IDataControllerRecordProps {
    rows: Array<DataControllerRecordModel>;
    isLoading: boolean;
    isExtended: boolean;
    hasData: boolean;
}

function DataControllerRecord(props: IDataControllerRecordProps) {
    const { getProcessingActivityOverviewUrl } = useUrlProvider();
    const { translateString } = useTranslation();
    const styles = useDataControllerRecordStyles();

    const formatRecipientData = (data: Array<DataControllerRecordRecipient>, id: string) => {
        return (
            <React.Fragment>
                {data.map((object, i) => {
                    return (
                        <React.Fragment key={"formatRecipientData" + i + id}>
                            {getContent(
                                `${object.name} (${object.countryCode}) (${object.sharingRoles.map((role) => translateString(ControllerProcess[Number(role)])).join(", ")})`,
                                object.dataCategories
                            )}
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    };

    const formatDataSubjects = (data: Array<DataControllerRecordDataSubject>) => {
        return (
            <React.Fragment>
                {data.map((object, i) => {
                    return <React.Fragment key={"formatDataSubjects" + i}>{getContent(object.name, object.dataCategories)}</React.Fragment>;
                })}
            </React.Fragment>
        );
    };

    const getContent = (name: string, dataCategories: string[]) => {
        return (
            <React.Fragment>
                <div key={name}>
                    <Box component={"span"} sx={styles.bold}>
                        {name}
                    </Box>
                    {dataCategories.length > 0 && (
                        <Box component={"span"} sx={styles.italic}>
                            :{" " + dataCategories.join(", ")}
                        </Box>
                    )}
                </div>
                <br />
            </React.Fragment>
        );
    };

    const getLegalBasisContent = (legalBases: string[]) => {
        return (
            <React.Fragment>
                {legalBases.map((object, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div>{object}</div>
                            <br />
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    };

    const getHeaders = () => {
        const headers = Array<ITableHeader<DataControllerRecordModel>>();
        headers.push({ property: "name", text: translateString("processingActivity"), align: "left" });

        if (props.isExtended) {
            headers.push({ property: "businessAreas", text: translateString("businessAreas"), align: "left" });
            headers.push({ property: "description", text: translateString("description"), align: "left" });
        }

        headers.push({ property: "purposes", text: translateString("purposes"), align: "left" });
        headers.push({ property: "dataCategories", text: translateString("dataCategoryHeader"), align: "left" });

        if (props.isExtended) {
            headers.push({ property: "legalBases", text: translateString("legalBasisDataProcessorRecord"), align: "left" });
            headers.push({ property: "disclosureBases", text: translateString("basisOfDisclosures"), align: "left" });
        }

        headers.push({ property: "dataSubjects", text: translateString("dataSubjects"), align: "left" });
        headers.push({ property: "deletionPeriods", text: translateString("deletionPeriods"), align: "left" });
        headers.push({ property: "securityMeasures", text: translateString("securityMeasures"), align: "left" });

        if (props.isExtended) {
            headers.push({ property: "systems", text: translateString("systems"), align: "left" });
        }

        headers.push({ property: "recipientsEuEea", text: translateString("recipientsEuEaa"), align: "left" });
        headers.push({ property: "recipientsAdequate", text: translateString("recipientsAdequate"), align: "left" });
        headers.push({ property: "recipientsThirdCountry", text: translateString("recipientsThirdCountry"), align: "left" });

        if (props.isExtended) {
            headers.push({ property: "transferBases", text: translateString("transferBasis"), align: "left" });
        }

        return headers;
    };

    return (
        <DotLegalPaper sx={styles.table}>
            {props.hasData ? (
                <DotLegalTableWithControls
                    headers={getHeaders()}
                    defaultOrderBy={"name"}
                    defaultOrder={"asc"}
                    isLoading={props.isLoading}
                    hideRowsPerPage
                    getUserSpecificPageLength={() => 30}
                    clickableRows={false}
                    data={props.rows}
                    emptyText={translateString("noProcessingActivitesMatchesQuery")}
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    renderRow={(row) => (
                        <TableRow key={row.id}>
                            <TableCell>
                                <DotLegalLink to={getProcessingActivityOverviewUrl(row.id)}>{row.name}</DotLegalLink>
                            </TableCell>
                            {props.isExtended && (
                                <React.Fragment>
                                    <TableCell>{row.businessAreas}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                </React.Fragment>
                            )}

                            <TableCell>{row.purposes}</TableCell>
                            <TableCell>{row.dataCategories}</TableCell>
                            {props.isExtended && (
                                <React.Fragment>
                                    <TableCell>{getLegalBasisContent(row.legalBases)}</TableCell>
                                    <TableCell>{getLegalBasisContent(row.disclosureBases)}</TableCell>
                                </React.Fragment>
                            )}

                            <TableCell>{formatDataSubjects(row.dataSubjects)}</TableCell>
                            <TableCell>{row.deletionPeriods.join(", ")}</TableCell>
                            <TableCell>{row.securityMeasures}</TableCell>

                            {props.isExtended && <TableCell>{row.systems}</TableCell>}

                            <TableCell>{formatRecipientData(row.recipientsEuEea, "EUEEA")}</TableCell>
                            <TableCell>{formatRecipientData(row.recipientsAdequate, "Adequate")}</TableCell>
                            <TableCell>{formatRecipientData(row.recipientsThirdCountry, "ThirdCountry")}</TableCell>

                            {props.isExtended && <TableCell>{getLegalBasisContent(row.transferBases)}</TableCell>}
                        </TableRow>
                    )}
                />
            ) : (
                <DotLegalEmptyState icon={<EmptyRecord />} text={translateString("noProcessingActivitesMatchesQuery")} />
            )}
        </DotLegalPaper>
    );
}

export default DataControllerRecord;
