import { usePlanContext } from "./planProvider/PlanProvider";
import { useUserContext } from "./userContextProvider/UserContextProvider";

export function usePermissionsProvider() {
    const { taskManagementAndAnnualWheelPlan } = usePlanContext();
    const { permissions } = useUserContext();

    function hasAnnualWheelActivityAccess() {
        return taskManagementAndAnnualWheelPlan.gdprPlanningWheel && permissions.annualWheelPermission.read;
    }

    function hasTaskManagementAccess() {
        return taskManagementAndAnnualWheelPlan.gdprPlanningWheel && permissions.taskPermissions.read;
    }

    return {
        hasAnnualWheelActivityAccess,
        hasTaskManagementAccess,
    };
}
