import { IconButton, TableCell, TableRow } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import CloseIcon from "@mui/icons-material/Close";
import { DocumentModel } from "../../documents/documentModel";
import { useDotLegalFileUploadDataMapping } from "./DotLegalFileUploadTable.hooks";
import DotLegalDeleteDialogWrapper from "../dotLegalDeleteDialog/DotLegalDeleteDialogWrapper";
import { isNullOrWhitespace } from "../../stringOperations";

export interface IDotLegalFileUploadTable {
    documents: Array<DocumentModel> | undefined;
    onRemoveDocument: (documentId: string) => void;
    disabled?: boolean;
}

function DotLegalFileUploadTable(props: IDotLegalFileUploadTable) {
    const [documentForDeletion, setDocumentForDeletion] = useState<{ id: string; name: string } | undefined>(undefined);
    const { translateString, translateDate } = useTranslation();
    const { downloadDocument } = useDotLegalFileUploadDataMapping();

    const getHeaders = () => {
        let headers: Array<ITableHeader<DocumentModel>> = [];
        headers.push({ property: "fileName", text: translateString("document"), align: "left", showOnMobile: true });
        headers.push({ property: "created", text: translateString("date"), align: "left", showOnMobile: true });

        return headers;
    };

    const handleRowClick = (row: DocumentModel) => {
        const isFileDocument = !isNullOrWhitespace(row.fileName) || !isNullOrWhitespace(row.dataUri);
        if (isFileDocument) {
            downloadDocument(row.id, row.fileName);
        } else {
            window.open(row.link, "_blank");
        }
    };

    return (
        <Fragment>
            <DotLegalTable
                headers={getHeaders()}
                emptyText={translateString("noDocuments")}
                paginationLabelOf={translateString("labelOf")}
                defaultOrderBy={"fileName"}
                defaultOrder={"asc"}
                isLoading={false}
                hidePagination
                textStyle={"NoCapsHeaders"}
                data={props.documents!}
                marginTop={1}
                rowsPerPage={1000}
                renderRow={(row) => (
                    <TableRow
                        sx={{
                            "&:hover": {
                                button: {
                                    visibility: "visible",
                                },
                            },
                        }}
                        key={row.id}
                        onClick={() => handleRowClick(row)}
                    >
                        <TableCell sx={{ wordBreak: "break-word" }}>{row.fileName ?? row.link}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{translateDate(row.created)}</TableCell>

                        <TableCell width={40}>
                            {props.disabled === false && (
                                <IconButton
                                    sx={{ visibility: "hidden" }}
                                    size="small"
                                    onClick={(e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
                                        e.stopPropagation();
                                        setDocumentForDeletion({ id: row.id, name: row.fileName ?? row.link });
                                    }}
                                >
                                    <CloseIcon sx={(theme) => ({ fill: theme.palette.error.main })} />
                                </IconButton>
                            )}
                        </TableCell>
                    </TableRow>
                )}
            />

            <DotLegalDeleteDialogWrapper
                itemForDeletion={documentForDeletion ? documentForDeletion.name : ""}
                open={!!documentForDeletion}
                onDialogClose={() => setDocumentForDeletion(undefined)}
                onOkClick={() => {
                    setDocumentForDeletion(undefined);
                    props.onRemoveDocument(documentForDeletion!.id);
                }}
            />
        </Fragment>
    );
}

export default DotLegalFileUploadTable;
