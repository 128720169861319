import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { LegalEntityAuditGroupAuditModel, LegalEntityAuditGroupAuditTableModel } from "../../legalEntityAuditGroupOverview/LegalEntityAuditGroupOverview.types";
import { useManagementReportCompletedAudits } from "./ManagementReportCompletedAudits.hooks";
import { useUrlProvider } from "../../../useUrlProvider";
import { DotLegalHeader, DotLegalMultiSelect, DotLegalTableWithControls, DotLegalTooltip, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, Grid, GridSize, TableCell, TableRow } from "@mui/material";
import QuestionnaireReportOverflowButtons from "../../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportOverflowButtons";
import QuestionnaireReportButtons from "../../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportButtons";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink";
import SystemLinks from "../../../system/SystemLinks";
import { getQuestionnaireResponseScore } from "../../../common/questionnaireResponseScore";
import { LegalEntityAuditStatus } from "../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { LegalEntityContactType } from "../../../legalEntity/legalEntityContactTab/LegalEntityContactTab.types";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { LegalEntityMasterType } from "../../../legalEntity/legalEntities/LegalEntities.types";
import { IDownloadAuditReportModel } from "../../DowloadLegalEntityAuditReport.hooks";
import QuestionnaireRisk from "../../../common/components/questionnaireComponents/questionnaireRisk/QuestionnaireRisk";
import QuestionnaireEvaluation from "../../../common/components/questionnaireComponents/questionnaireEvaluation/QuestionnaireEvaluation.tsx";
import { LegalEntityAuditTabs } from "../../../legalEntity/LegalEntity.types.ts";

export interface ManagementReportCompletedAuditsProps {
    isLoading: boolean;
    audits: Array<LegalEntityAuditGroupAuditModel>;
    scoreEnabled: boolean;
    isPdf: boolean;
}

function ManagementReportCompletedAudits(props: ManagementReportCompletedAuditsProps) {
    const { translateString, translateLegalEntityAuditStatus, translateDate } = useTranslation();
    const {
        rows,
        searchedRiskLevel,
        setSearchedRiskLevel,
        onDownloadReportAsPdf,
        getReportPdfUrl,
        isDownloadingPdf,
        riskLevelFilterOptions,
        searchedAuditScore,
        setSearchedAuditScore,
        auditScoreFilterOptions,
        auditEvaluationFilterOptions,
        searchedAuditEvaluation,
        setSearchedAuditEvaluation,
    } = useManagementReportCompletedAudits(props);
    const { getLegalEntityUrl } = useUrlProvider();

    function getHeaders() {
        let headers = Array<ITableHeader<LegalEntityAuditGroupAuditTableModel>>();
        headers.push({ property: "legalEntityName", text: translateString("legalEntities"), align: "left", showOnMobile: true });
        headers.push({ property: "assets", text: translateString("assets"), align: "left", showOnMobile: true });
        headers.push({ property: "contact", text: translateString("contact"), align: "left", showOnMobile: true });
        headers.push({ property: "riskLevel", text: translateString("riskLevel"), align: "left", showOnMobile: true });
        headers.push({ property: "score", text: translateString("score"), align: "left", showOnMobile: true });
        headers.push({ property: "evaluationStatus", text: translateString("evaluation"), align: "left", showOnMobile: true });

        headers.push({ property: "report", text: translateString("legalEntityAuditReport"), align: "left", showOnMobile: true });
        headers.push({ property: "note", text: translateString("note"), align: "left", showOnMobile: true });

        // Hidden headers - allowing search
        headers.push({ property: "assetsString", text: "", align: "left", showOnMobile: true, hideHeader: true });
        headers.push({ property: "contactTypesString", text: "", align: "left", showOnMobile: true, hideHeader: true });
        headers.push({ property: "auditStatusString", text: "", align: "left", showOnMobile: true, hideHeader: true });

        return headers;
    }

    function getSearchFields() {
        const tableGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize } = {
            item: true,
            xs: 12,
            sm: 6,
            md: 4,
            lg: 3,
        };

        if (rows.some((x) => !x.scoreEnabled)) {
            return undefined;
        }

        return (
            <React.Fragment>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedRiskLevel}
                        chosenOptions={searchedRiskLevel}
                        label={translateString("riskLevel")}
                        placeholder={translateString("riskLevel")}
                        isLoading={props.isLoading}
                        options={riskLevelFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedAuditScore}
                        chosenOptions={searchedAuditScore}
                        label={translateString("score")}
                        placeholder={translateString("score")}
                        isLoading={props.isLoading}
                        options={auditScoreFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedAuditEvaluation}
                        chosenOptions={searchedAuditEvaluation}
                        label={translateString("evaluation")}
                        placeholder={translateString("evaluation")}
                        isLoading={props.isLoading}
                        options={auditEvaluationFilterOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    function getReportButtons(row: LegalEntityAuditGroupAuditTableModel) {
        const downloadModel: IDownloadAuditReportModel = {
            legalEntityAuditId: row.id,
            deadline: row.deadline,
            legalEntityName: row.legalEntityName,
            questionnaireName: row.questionnaireName,
            questionnaireResponseId: row.questionnaireResponseId,
            responseExternalId: row.responseExternalId,
            sender: row.sender,
            hasRecommendations: row.hasRecommendations,
        };
        const reportUrl = getReportPdfUrl(row.questionnaireResponseId, row.deadline, row.sender, row.legalEntityName, false, row.hasRecommendations);
        const showButtons = row.status !== LegalEntityAuditStatus.Opened && row.status !== LegalEntityAuditStatus.Sent && !row.closed;
        const downloadingPdf = isDownloadingPdf === row.id;

        if (row.scoreEnabled || row.hasRecommendations) {
            return (
                <QuestionnaireReportOverflowButtons
                    reportPdfUrl={reportUrl}
                    reportPdfMenuItems={getReportButtonMenuItems(row.scoreEnabled, downloadModel)}
                    showButtons={showButtons}
                    isDownloadingPdf={downloadingPdf}
                />
            );
        }

        return (
            <QuestionnaireReportButtons
                isDownloadingPdf={downloadingPdf}
                onDownloadReportPdf={() => onDownloadReportAsPdf(downloadModel, true, row.hasRecommendations)}
                reportPdfUrl={reportUrl}
                showButtons={showButtons}
            />
        );
    }

    function getReportButtonMenuItems(isScoreEnabled: boolean, row: IDownloadAuditReportModel) {
        if (isScoreEnabled) {
            return [
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithScoreAndRecommendations") : translateString("downloadWithScore"),
                    onClick: () => onDownloadReportAsPdf(row, false, row.hasRecommendations),
                },
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithoutScoreAndRecommendations") : translateString("downloadWithoutScore"),
                    onClick: () => onDownloadReportAsPdf(row, true, false),
                },
            ];
        } else {
            return [
                { menuItem: translateString("downloadWithRecommendations"), onClick: () => onDownloadReportAsPdf(row, false, true) },
                {
                    menuItem: translateString("downloadWithoutRecommendations"),
                    onClick: () => onDownloadReportAsPdf(row, false, false),
                },
            ];
        }
    }

    return rows.length === 0 ? undefined : (
        <Box sx={{ marginTop: 3 }} className={"avoid-page-break"}>
            <DotLegalHeader headerStyle={"medium"} marginBottom={props.scoreEnabled ? 2 : props.isPdf ? 0 : -3.75}>
                {translateString("completed").toUpperCase()}{" "}
                <Box sx={(theme) => ({ color: theme.palette.grey[500] })} component="span">
                    ({props.audits.length})
                </Box>
            </DotLegalHeader>
            <DotLegalTableWithControls
                getUserSpecificPageLength={() => 1000}
                extraControls={getSearchFields()}
                labelRowsPerPage={""}
                labelSearch={translateString("search")}
                noOptionsLabel={translateString("noOptions")}
                headers={getHeaders()}
                defaultOrderBy={"legalEntityName"}
                defaultOrder={"asc"}
                paginationLabelOf={""}
                hidePagination
                hideRowsPerPage
                data={mapToTableData(rows)}
                emptyText={translateString("noData")}
                renderRow={(row) => {
                    return (
                        <TableRow key={row.legalEntityId}>
                            <TableCell>
                                <Box key={row.id} component={"span"} sx={{ fontWeight: "bold" }}>
                                    <DotLegalLink linkColor={"primary"} to={`${getLegalEntityUrl(row.legalEntityId, LegalEntityAuditTabs.Audits)}`}>
                                        {row.legalEntityName}
                                    </DotLegalLink>
                                </Box>
                            </TableCell>

                            <TableCell>
                                <SystemLinks systems={row.assets} />
                            </TableCell>
                            <TableCell>
                                <DotLegalTooltip text={row.contactEmail}>
                                    <Box>
                                        {row.contact} {row.contactTypes.length > 0 && <>({row.contactTypesString})</>}
                                    </Box>
                                </DotLegalTooltip>
                            </TableCell>
                            <TableCell>
                                <QuestionnaireRisk riskLevel={row.riskLevel} riskName={row.riskName} scoreEnabled={row.scoreEnabled} status={row.status} />
                            </TableCell>
                            <TableCell>{row.scoreString}</TableCell>
                            <TableCell>
                                <QuestionnaireEvaluation evaluationStatus={row.evaluationStatus} evaluation={row.evaluation} />
                            </TableCell>
                            <TableCell>{getReportButtons(row)}</TableCell>
                            <TableCell>{row.note}</TableCell>
                        </TableRow>
                    );
                }}
            />
        </Box>
    );

    function mapToTableData(audits: Array<LegalEntityAuditGroupAuditModel> | undefined): Array<LegalEntityAuditGroupAuditTableModel> {
        if (audits) {
            return audits.map((x) => {
                return {
                    ...x,
                    assetsString: x.assets.flatMap((x) => x.name).join(", "),
                    contactTypesString: x.contactTypes.map((x) => translateString(setFirstLetterToLowerCase(LegalEntityContactType[x].toString()))).join(", "),
                    auditStatusString: translateLegalEntityAuditStatus(x.status.toString()),
                    scoreString: getQuestionnaireResponseScore(
                        x.status === LegalEntityAuditStatus.Submitted,
                        x.scoreEnabled,
                        x.score,
                        translateString("scoreNotEnabled")
                    ),
                    legalEntityTypeString: x.legalEntityTypes
                        ?.map((type) => translateString(setFirstLetterToLowerCase(LegalEntityMasterType[type].toString())))
                        .join(", "),
                    reminderSentString: x.reminderSent ? translateDate(x.reminderSent) : "",
                };
            });
        }

        return [];
    }
}

export default ManagementReportCompletedAudits;
