import { DotLegalEmptyState, DotLegalHeader } from "@dotlegal/dotlegal-ui-components";
import React from "react";
import Widget from "../Widget.tsx";
import { Box, useTheme } from "@mui/material";
import { useTranslation } from "../../localization/useTranslation.ts";
import { useTaskOverviewWidget } from "./TaskOverviewWidget.hooks.ts";
import AnnualWheelYearPicker from "../../annualWheel/annualWheelYearPicker/AnnualWheelYearPicker.tsx";
import NavigateTo from "../navigateTo/NavigateTo.tsx";
import { useUrlProvider } from "../../useUrlProvider.ts";
import { ResponsiveBar } from "@nivo/bar";
import { setFirstLetterToLowerCase } from "../../common/stringOperations.ts";
import { AnnualWheelMonthEnum } from "../../annualWheel/AnnualWheel.types.ts";
import EmptyStateIcon from "../svgs/Task overview.svg?react";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink.tsx";

export interface ITaskOverviewWidgetProps {
    onlyUserSpecific: boolean;
    selectedGroupCompany: string | undefined;
}

function TaskOverviewWidget(props: ITaskOverviewWidgetProps) {
    const { translateString, translateShortMonth } = useTranslation();
    const { data, isLoading, isEnabled, year, setYear, highestNumberOfTasksInAMonth } = useTaskOverviewWidget(props);
    const { getAnnualWheelUrl, getTaskManagementUrl } = useUrlProvider();

    const overdueColor = "rgb(254, 159, 188)";
    const completedColor = "rgb(44, 213, 196)";
    const plannedColor = "rgb(114, 132, 250)";
    const ongoingColor = "rgb(189, 211, 236)";
    const theme = useTheme();

    function getGraphTooltipContent(numberOfTasks: number | undefined, title: string, color: string) {
        if (!numberOfTasks) return <React.Fragment></React.Fragment>;
        return (
            numberOfTasks !== 0 && (
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "10px", marginRight: "10px", fontSize: "14px" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Box sx={{ width: "15px", height: "15px", backgroundColor: color, marginRight: "10px" }}></Box>
                        <Box>{translateString(title)}</Box>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ fontWeight: "bold" }}>{numberOfTasks}</Box>
                    </Box>
                </Box>
            )
        );
    }

    function getGraphTooltip(month: number) {
        if (!data) return <Box></Box>;
        const tasksInMonth = data.find((x) => x.month === month);
        return (
            <Box
                sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    background: "white",
                    width: "200px",
                    paddingLeft: "10px",
                    paddingTop: "5px",
                    paddingRight: "10px",
                    paddingBottom: "5px",
                    justifyContent: "center",
                    borderRadius: "10px",
                    color: theme.palette.primary.dark,
                    boxShadow: "0px 3px 10px #E7E7E7",
                })}
            >
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <DotLegalHeader headerStyle={"small"} marginBottom={0} color={"lightgrey"}>
                        {translateString(setFirstLetterToLowerCase(AnnualWheelMonthEnum[month].toString()))} {year}
                    </DotLegalHeader>
                </Box>
                {getGraphTooltipContent(tasksInMonth?.numberOfPlannedTasks, "planned", plannedColor)}
                {getGraphTooltipContent(tasksInMonth?.numberOfOngoingTasks, "inProgress", ongoingColor)}
                {getGraphTooltipContent(tasksInMonth?.numberOfCompletedTasks, "completed", completedColor)}
                {getGraphTooltipContent(tasksInMonth?.numberOfOverdueTasks, "overdue", overdueColor)}
            </Box>
        );
    }

    function getYAxisTicks() {
        if (highestNumberOfTasksInAMonth === 1) return ["0", "1"];
        if (highestNumberOfTasksInAMonth <= 10) return ["0", "2", "4", "6", "8", "10"];
        if (highestNumberOfTasksInAMonth <= 25) return ["0", "5", "10", "15", "20", "25"];
        if (highestNumberOfTasksInAMonth <= 50) return ["0", "10", "20", "30", "40", "50"];
        if (highestNumberOfTasksInAMonth <= 100) return ["0", "20", "40", "60", "80", "100"];
        return undefined; //returning undefined will fallback to the default graph ticks
    }

    return (
        <Widget isLoading={isLoading} disabled={!isEnabled}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <DotLegalHeader headerStyle={"medium"} marginBottom={0}>
                        {translateString("taskOverviewWidgetHeader").toUpperCase()}
                    </DotLegalHeader>
                    <Box sx={{ marginLeft: "10px" }}>
                        <AnnualWheelYearPicker sx={{ height: "25px" }} showPreviousYear year={year!} onChange={setYear} isLoading={isLoading} />
                    </Box>
                </Box>
                {highestNumberOfTasksInAMonth === 0 ? (
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            backgroundColor: "white",
                            borderRadius: "20px",
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            maxHeight: "80%",
                            marginTop: "25px",
                        }}
                    >
                        <DotLegalEmptyState
                            noFixedHeight
                            noPadding
                            text={
                                <Box sx={(theme) => ({ display: "flex", flexDirection: "column", color: theme.palette.primary.dark, mt: 3 })}>
                                    {translateString("taskOverviewWidgetNoTasks")}
                                    <DotLegalLink linkColor={"primary"} to={getAnnualWheelUrl()}>
                                        {translateString("taskOverviewWidgetCreateTask")}
                                    </DotLegalLink>
                                </Box>
                            }
                            icon={<EmptyStateIcon />}
                        />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: "100%",
                            height: 0,
                            borderRadius: "20px",
                            flex: "1 1 auto",
                            backgroundColor: "white",
                            "g rect:hover": { cursor: "pointer" },
                            marginTop: "25px",
                            paddingTop: "15px",
                        }}
                    >
                        {data && (
                            <ResponsiveBar
                                maxValue={highestNumberOfTasksInAMonth}
                                data={data}
                                keys={["numberOfPlannedTasks", "numberOfOngoingTasks", "numberOfCompletedTasks", "numberOfOverdueTasks"]}
                                indexBy="month"
                                margin={{ top: 10, right: 60, bottom: 50, left: 60 }}
                                padding={0.3}
                                label={"false"}
                                valueScale={{ type: "linear" }}
                                colors={({ id }) => {
                                    if (id === "numberOfCompletedTasks") return completedColor;
                                    if (id === "numberOfOverdueTasks") return overdueColor;
                                    if (id === "numberOfPlannedTasks") return plannedColor;
                                    return ongoingColor;
                                }}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legendPosition: "middle",
                                    legendOffset: 32,
                                    format: (value) => `${translateShortMonth(value - 1)}`,
                                }}
                                axisLeft={{
                                    legend: translateString("tasks"),
                                    legendPosition: "middle",
                                    legendOffset: -45,
                                    tickValues: getYAxisTicks(),
                                }}
                                tooltip={(x) => getGraphTooltip(x.index + 1)}
                                theme={{
                                    axis: {
                                        legend: {
                                            text: {
                                                fontSize: 16,
                                                fill: theme.palette.primary.dark,
                                                fontWeight: "bold",
                                            },
                                        },
                                        ticks: {
                                            text: {
                                                fontSize: 14,
                                                fill: theme.palette.primary.dark,
                                                fontWeight: "bold",
                                            },
                                        },
                                    },
                                }}
                            />
                        )}
                    </Box>
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <NavigateTo color="purple" text={translateString("taskOverviewWidgetGoToTasks")} to={getTaskManagementUrl()} />
                </Box>
            </Box>
        </Widget>
    );
}

export default TaskOverviewWidget;
