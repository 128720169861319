import { Skeleton, TableCell, TableRow } from "@mui/material";
import React, { CSSProperties } from "react";
import DotLegalPaper from "../common/components/dotLegalPaper/DotLegalPaper";
import { DotLegalSearchField, DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../localization/useTranslation";
import { useUrlProvider } from "../useUrlProvider";
import { useDataProcessorRecordStyles } from "./DataProcessorRecord.styles";
import { DataProcessorRecordRow, DataProcessorRecordTableModel } from "./DataProcessorRecord.types";
import { Box } from "@mui/system";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import EmptyRecord from "../record/emptyRecord.svg?react";
import DotLegalLink from "../common/components/dotLegalLink/DotLegalLink";

export interface IDataProcessorRecord {
    rows: Array<DataProcessorRecordTableModel>;
    isLoading: boolean;
    searchString: string;
    setSearchString: (s: string) => void;
    hasData: boolean;
}

function DataProcessorRecord(props: IDataProcessorRecord) {
    const { translateString } = useTranslation();
    const styles = useDataProcessorRecordStyles();
    const { getProcessingActivityOverviewUrl } = useUrlProvider();

    const getHeaders = () => {
        const headers = Array<ITableHeader<DataProcessorRecordRow>>();
        headers.push({ property: "processingActivityName", text: translateString("processingActivity"), align: "left" });
        headers.push({ property: "dataCategories", text: translateString("dataCategories"), align: "left" });
        headers.push({ property: "securityMeasures", text: translateString("securityMeasures"), align: "left" });
        headers.push({
            property: "thirdCountryTransfers",
            text: translateString("recipientsThirdCountry"),
            align: "left",
        });
        return headers;
    };

    const getSkeletons = () => {
        return (
            <React.Fragment>
                <Skeleton sx={styles.skeleton} variant="text" />
                <Skeleton sx={styles.skeleton} variant="text" />
                <Skeleton sx={styles.skeleton} variant="text" />
                <Skeleton sx={styles.skeleton} variant="text" />
            </React.Fragment>
        );
    };

    const getTableClass = (index: number): CSSProperties => {
        if (index !== 0) return styles.table;

        return {};
    };

    return (
        <DotLegalPaper sx={styles.paper}>
            {props.rows.length > 0 && (
                <Box sx={styles.searchField}>
                    <DotLegalSearchField labelSearch={translateString("search")} value={props.searchString} onChange={(e) => props.setSearchString(e)} />
                </Box>
            )}

            {props.isLoading ? (
                getSkeletons()
            ) : props.hasData ? (
                props.rows!.map((record, index) => {
                    var tableClass = getTableClass(index);
                    return (
                        <Box sx={tableClass} key={record.name + index}>
                            <Box sx={styles.tableHeader}>{record.name}</Box>
                            <DotLegalTable
                                hidePagination
                                headers={getHeaders()}
                                clickableRows={false}
                                isLoading={false}
                                rowsPerPage={1000}
                                hideRowsPerPage
                                data={record.rows}
                                defaultOrderBy={"processingActivityName"}
                                paginationLabelOf={translateString("labelOf")}
                                defaultOrder="asc"
                                renderRow={(row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <DotLegalLink to={getProcessingActivityOverviewUrl(row.id)}>{row.processingActivityName}</DotLegalLink>
                                        </TableCell>
                                        <TableCell>{row.dataCategories.join(", ")}</TableCell>
                                        <TableCell>{row.securityMeasures.join(", ")}</TableCell>
                                        <TableCell>{row.thirdCountryTransfers.join(", ")}</TableCell>
                                    </TableRow>
                                )}
                            />
                        </Box>
                    );
                })
            ) : (
                <DotLegalEmptyState icon={<EmptyRecord />} text={translateString("noProcessingActivitesMatchesQuery")} />
            )}
        </DotLegalPaper>
    );
}

export default DataProcessorRecord;
