import React from "react";
import {
    DotLegalActionBar,
    DotLegalButton,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalTableWithControls,
    ITableHeader,
    useHistoryWithReferer,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import DotLegalPaper from "../../common/components/dotLegalPaper/DotLegalPaper";
import { useIncidentLogDataMapping } from "./IncidentLog.hooks";
import { DotLegalEmptyState } from "@dotlegal/dotlegal-ui-components";
import Logo from "./IncidentLog.svg?react";
import LogoUrl from "./IncidentLog.svg?react";
import PlanDialogButtonWrapper from "../../plan/planDialogButtonWrapper/planDialogButtonWrapper";
import { warning, WhiteLockIcon } from "../../common/icons";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { usePlanContext } from "../../auth/planProvider/PlanProvider";
import CreateEditGdprIncidentLogDialog from "../incidentLogDialog/CreateEditGdprIncidentLogDialog";
import { GdprIncidentLogListViewModel, SeverityLevelEnum } from "../IncidentLogDialog.types";
import { Box, TableCell, TableRow } from "@mui/material";
import { useUrlProvider } from "../../useUrlProvider";
import IncidentLogDeleteDialog from "../incidentLogDeleteDialog/IncidentLogDeleteDialog";
import { useIncidentLogStyles } from "./IncidentLog.styles";
import { IncidentLogTableModel } from "./incidentLog.types";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";

function IncidentLog() {
    const { translateString, translateDateWithTime, translateDate, translateGdprIncidentType } = useTranslation();
    const {
        incidentLogLimitReached,
        showCreateDialog,
        setShowCreateDialog,
        isLoading,
        logEntries,
        refetch,
        selectedIncident,
        setSelectedIncident,
        showDeleteIncidentDialog,
        setShowDeleteIncidentDialog,
        showEditDialog,
        setShowEditDialog,
    } = useIncidentLogDataMapping();
    const userContext = useUserContext();
    const { incidentLogPlan } = usePlanContext();
    const historyWithReferer = useHistoryWithReferer();
    const urlProvider = useUrlProvider();
    const styles = useIncidentLogStyles();

    function getHeaders() {
        let headers = Array<ITableHeader<GdprIncidentLogListViewModel>>();
        headers.push({ property: "name", text: translateString("incident"), align: "left", showOnMobile: true });
        headers.push({ property: "types", text: translateString("incidentTableTypes"), align: "left", showOnMobile: true });
        headers.push({ property: "severity", text: translateString("severity"), align: "left", showOnMobile: true });
        headers.push({ property: "dateAndTimeOfIncident", text: translateString("incidentTableDate"), align: "left", showOnMobile: true });
        headers.push({ property: "dataCategories", text: translateString("incidentTableDataCategories"), align: "left", showOnMobile: true });
        headers.push({ property: "reportedToAgency", text: translateString("incidentTableReportedToAgency"), align: "left", showOnMobile: true });
        headers.push({ property: "affectedPeopleNotified", text: translateString("incidentTableAffectedPeopleNotified"), align: "left", showOnMobile: true });
        return headers;
    }

    function formatIncidentTypes(types: Array<number>) {
        return types.map((x) => translateGdprIncidentType(x.toString())).join(", ");
    }

    function formatDataCategories(dataCategories: Array<string>) {
        return dataCategories.join(", ");
    }

    function getWarning(item: GdprIncidentLogListViewModel) {
        if (!item.hasWarning) return "";
        return <Box marginLeft={1}>{warning}</Box>;
    }

    const getMenuItems = (incident: GdprIncidentLogListViewModel) => {
        let menuItems = [];
        menuItems.push({
            menuItem: translateString("overview"),
            onClick: () => {
                historyWithReferer.push(urlProvider.getIncidentLogOverviewUrl(incident.id));
            },
        });
        if (userContext.permissions.gdprIncidentLogPermissions.edit) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedIncident(incident);
                    setShowEditDialog(true);
                },
            });
        }
        if (userContext.permissions.gdprIncidentLogPermissions.delete) {
            menuItems.push({
                menuItem: translateString("delete"),
                onClick: () => {
                    setSelectedIncident(incident);
                    setShowDeleteIncidentDialog(true);
                },
            });
        }
        return menuItems;
    };

    function getDateAndTime(row: GdprIncidentLogListViewModel) {
        return row.hasTime ? translateDateWithTime(row.dateAndTimeOfIncident!) : translateDate(row.dateAndTimeOfIncident!);
    }

    return (
        <React.Fragment>
            <DotLegalPageHeader userContext={{ customerName: userContext.customerName }} breadCrumbs={[{ name: translateString("incidentLog") }]} />
            <DotLegalActionBar
                canCreate={userContext.permissions.gdprIncidentLogPermissions.create}
                showDialog={showCreateDialog}
                icon={<LogoUrl />}
                primaryButton={
                    <PlanDialogButtonWrapper enabled={incidentLogLimitReached ?? false}>
                        <DotLegalButton
                            buttonType="primary"
                            size="medium"
                            disabled={incidentLogLimitReached}
                            endIcon={incidentLogLimitReached ? WhiteLockIcon : undefined}
                            toolTip={
                                incidentLogLimitReached
                                    ? translateString("incidentLogLimitReached", { count: incidentLogPlan.gdprMaxNumberOfIncidents })
                                    : undefined
                            }
                            onClick={() => {
                                setShowCreateDialog(true);
                            }}
                        >
                            {translateString("addIncident")}
                        </DotLegalButton>
                    </PlanDialogButtonWrapper>
                }
            >
                <CreateEditGdprIncidentLogDialog
                    closeDialog={() => setShowCreateDialog(false)}
                    onSave={async () => {
                        setShowCreateDialog(false);
                        await refetch();
                    }}
                />
            </DotLegalActionBar>
            <DotLegalPaper>
                {logEntries && logEntries.length === 0 ? (
                    <DotLegalEmptyState text={translateString("emptyIncidentLog")} icon={<Logo />} />
                ) : (
                    <DotLegalTableWithControls
                        getUserSpecificPageLength={() => 30}
                        hideRowsPerPage
                        defaultOrderBy={"dateAndTimeOfIncident"}
                        defaultOrder={"desc"}
                        clickableRows
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        labelRowsPerPage={translateString("showEntites")}
                        headers={getHeaders()}
                        isLoading={isLoading}
                        data={getTableData(logEntries ?? [])}
                        emptyText={translateString("noData")}
                        renderRow={(row, i) => (
                            <TableRow key={i} onClick={() => historyWithReferer.push(urlProvider.getIncidentLogOverviewUrl(row.id))}>
                                <TableCell sx={styles.bold}>
                                    <Box sx={styles.iconContainer}>
                                        {row.name} {getWarning(row)}
                                    </Box>
                                </TableCell>
                                <TableCell>{formatIncidentTypes(row.types)}</TableCell>
                                <TableCell>{row.severityLevelString}</TableCell>
                                <TableCell>{row.dateAndTimeOfIncident !== null ? getDateAndTime(row) : ""}</TableCell>
                                <TableCell>{formatDataCategories(row.dataCategories)}</TableCell>
                                <TableCell>{row.reportedToAgency !== null ? translateString(row.reportedToAgency ? "yes" : "no") : ""}</TableCell>
                                <TableCell>{row.affectedPeopleNotified !== null ? translateString(row.affectedPeopleNotified ? "yes" : "no") : ""}</TableCell>
                                <TableCell align={"right"}>
                                    <DotLegalOverflowMenu items={getMenuItems(row)} />
                                </TableCell>
                            </TableRow>
                        )}
                    />
                )}
            </DotLegalPaper>
            {showDeleteIncidentDialog && (
                <IncidentLogDeleteDialog
                    onCloseDialog={() => setShowDeleteIncidentDialog(false)}
                    name={selectedIncident?.name!}
                    id={selectedIncident?.id!}
                    onSuccessDelete={async () => {
                        refetch();
                    }}
                />
            )}
            {showEditDialog && (
                <CreateEditGdprIncidentLogDialog
                    id={selectedIncident?.id!}
                    closeDialog={() => setShowEditDialog(false)}
                    onSave={async () => {
                        setShowEditDialog(false);
                        refetch();
                    }}
                />
            )}
        </React.Fragment>
    );

    function getTableData(logEntries: Array<GdprIncidentLogListViewModel>): Array<IncidentLogTableModel> {
        return logEntries.map((log) => {
            return {
                ...log,
                severityLevelString: translateString(setFirstLetterToLowerCase(SeverityLevelEnum[log.severity].toString())),
            };
        });
    }
}

export default IncidentLog;
