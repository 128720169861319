import { Box, Skeleton } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { DotLegalSelect } from "@dotlegal/dotlegal-ui-components";
import { useDotLegalCompanySelectorStyles } from "./DotLegalCompanySelector.styles";
import { SelectableCompany } from "./DotLegalCompanySelector.types";
import { isNullOrWhitespace } from "../../stringOperations";

export interface IDotLegalCompanySelector {
    companies: Array<SelectableCompany>;
    selectedCompany: string;
    setSelectedCompany: (id: string) => void;
    isLoading: boolean;
}

function DotLegalCompanySelector(props: IDotLegalCompanySelector) {
    const styles = useDotLegalCompanySelectorStyles();
    const { translateString } = useTranslation();

    let allCompanies = [...props.companies];

    if (allCompanies.filter((x) => x.id === "null").length === 0) {
        allCompanies.unshift({ id: "null", name: translateString("allGroupEntities") });
    }

    const getContent = () => {
        if (props.isLoading || props.companies.length > 1) {
            return (
                <Box sx={styles.container}>
                    {props.isLoading ? (
                        <Skeleton variant="rectangular" sx={{ borderRadius: "10px" }} width={220} height={39} />
                    ) : (
                        <DotLegalSelect
                            noMargin
                            width="220px"
                            options={allCompanies}
                            onChange={(groupEntityId) => props.setSelectedCompany(groupEntityId ?? "")}
                            label={translateString("changeOrganisation")}
                            noOptionsLabel={""}
                            selectedItem={isNullOrWhitespace(props.selectedCompany) ? "null" : props.selectedCompany}
                            backgroundColor="white"
                        />
                    )}
                </Box>
            );
        }

        return undefined;
    };

    return <React.Fragment>{getContent()}</React.Fragment>;
}

export default DotLegalCompanySelector;
