import { DotLegalDialog, DotLegalSearchField, DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { InternalAssessmentApiRow } from "../InternalAssessments.types";
import { Alert, Box, Radio, RadioGroup, TableCell, TableRow, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { useTranslation } from "../../localization/useTranslation";
import { useChangeAssigneeDialogHooks } from "./ChangeAssigneeDialog.hooks";
import { LegalEntityContactType, LegalEntityContactViewModel } from "../../legalEntity/legalEntityContactTab/LegalEntityContactTab.types";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";

export interface IChangeAssigneeDialog {
    internalAssessmentViewModel: InternalAssessmentApiRow;
    onDialogClose: () => void;
    onChangedAssignee: () => void;
}

function ChangeAssigneeDialog(props: IChangeAssigneeDialog) {
    const { translateString, translateDate } = useTranslation();
    const { data, isLoading, assignee, isChanging, setAssignee, onChangeAssigneeClick, assigneeSearch, setAssigneeSearch } =
        useChangeAssigneeDialogHooks(props);

    const getHeaders = () => {
        const headers = new Array<ITableHeader<LegalEntityContactViewModel>>();
        headers.push({ property: "id", text: "", align: "center", width: "50px" });
        headers.push({ property: "name", text: translateString("contact") });
        headers.push({ property: "contactTypes", text: translateString("role") });
        headers.push({ property: "email", text: translateString("email") });
        return headers;
    };

    return (
        <DotLegalDialog
            size="md"
            buttonOkText={translateString("save")}
            open
            onDialogClose={props.onDialogClose}
            onOkClick={onChangeAssigneeClick}
            isLoading={isLoading}
            header={translateString("changeAssignee")}
            inProgress={isChanging}
        >
            <Box display="flex" gap={1} flexDirection={"column"}>
                <Typography>
                    <Alert severity={"info"}>
                        <Trans
                            i18nKey={"changeAssigneeDialogDescription"}
                            values={{
                                audit: props.internalAssessmentViewModel.name,
                                deadline: translateDate(props.internalAssessmentViewModel.deadline),
                            }}
                            components={{
                                strong: <Box component="span" sx={(theme) => ({ fontWeight: "bold", color: theme.palette.primary.dark })}></Box>,
                            }}
                        />
                    </Alert>
                </Typography>

                <Box display="flex" justifyContent="space-between" alignItems={"center"} flexDirection={"row-reverse"}>
                    <DotLegalSearchField labelSearch={translateString("search")} value={assigneeSearch} onChange={(value) => setAssigneeSearch(value)} />
                </Box>

                <RadioGroup value={assignee} onChange={(event, value) => setAssignee(value)}>
                    <DotLegalTable
                        data={data ?? []}
                        headers={getHeaders()}
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        clickableRows
                        searchString={assigneeSearch}
                        emptyText={translateString("noData")}
                        renderRow={(row) => (
                            <TableRow key={row.id} onClick={() => setAssignee(row.id)}>
                                <TableCell>
                                    <Radio value={row.id} />
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                    {row.contactTypes &&
                                        row.contactTypes
                                            .map((x) => translateString(setFirstLetterToLowerCase(LegalEntityContactType[x].toString())))
                                            .join(", ")}
                                </TableCell>
                                <TableCell>{row.email}</TableCell>
                            </TableRow>
                        )}
                        paginationLabelOf={translateString("labelOf")}
                    />
                </RadioGroup>
            </Box>
        </DotLegalDialog>
    );
}

export default ChangeAssigneeDialog;
