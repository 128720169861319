import { DotLegalDialog, DotLegalPrettyScrollbar, DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useTranslation } from "../../../localization/useTranslation";
import { useUrlProvider } from "../../../useUrlProvider";
import { PolicyViewItem } from "../ProcessingActivityOverview.types";

export interface IPolicyOverviewDialog {
    onDialogClose: () => void;
    policies: Array<PolicyViewItem>;
}

export default function PolicyOverviewDialog(props: IPolicyOverviewDialog) {
    const { translateString, translatePolicyTypes } = useTranslation();
    const { getPolicyUrl } = useUrlProvider();
    const { permissions } = useUserContext();

    return (
        <DotLegalDialog
            header={translateString("policies")}
            buttonOkText={""}
            hideOkButton
            open
            onDialogClose={() => props.onDialogClose()}
            onOkClick={() => {}}
            size={"md"}
        >
            <DotLegalPrettyScrollbar>
                <Box sx={{ maxHeight: 525 }}>
                    <DotLegalTable
                        hideRowsPerPage
                        hidePagination
                        emptyText={translateString("noPoliciesMatchesQuery")}
                        paginationLabelOf={translateString("labelOf")}
                        headers={getHeaders(translateString)}
                        defaultOrderBy={"name"}
                        data={props.policies}
                        defaultOrder={"asc"}
                        isLoading={false}
                        rowsPerPage={1000}
                        renderRow={(row) => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    {permissions.policyPermissions.read ? (
                                        <Box component={Link} sx={{ textDecoration: "none" }} to={getPolicyUrl(row.id)}>
                                            <Box
                                                sx={(theme) => ({
                                                    color: theme.palette.secondary.main,
                                                    "&:hover": {
                                                        textDecoration: "underline",
                                                    },
                                                })}
                                            >
                                                {row.name}
                                            </Box>
                                        </Box>
                                    ) : (
                                        row.name
                                    )}
                                </TableCell>
                                <TableCell>{translatePolicyTypes(row.type.toString())}</TableCell>
                            </TableRow>
                        )}
                    />
                </Box>
            </DotLegalPrettyScrollbar>
        </DotLegalDialog>
    );
}

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<PolicyViewItem>>();
    headers.push({ property: "name", text: translateString("policy"), align: "left", showOnMobile: true });
    headers.push({ property: "type", text: translateString("type"), align: "left", showOnMobile: true });

    return headers;
}
