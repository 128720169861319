import { useTranslation } from "../../localization/useTranslation.ts";
import { useUrlProvider } from "../../useUrlProvider.ts";
import React from "react";
import { Box, useTheme } from "@mui/material";
import { UseIncidentLogWidget } from "./IncidentLogWidget.hooks.ts";
import Widget from "../Widget.tsx";
import { DotLegalEmptyState, DotLegalHeader, useIsOnMediumScreen, useIsOnSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { ResponsiveBar } from "@nivo/bar";
import NavigateTo from "../navigateTo/NavigateTo.tsx";
import { setFirstLetterToLowerCase } from "../../common/stringOperations.ts";
import { SeverityLevelEnum } from "../../incidentLog/IncidentLogDialog.types.ts";
import EmptyStateIcon from "../svgs/Incidents-empty-state.svg?react";
import WhiteDashboardBox from "../WhiteDashboardBox.tsx";

function IncidentLogWidget() {
    const { translateString } = useTranslation();
    const { data, isLoading, isEnabled, highestNumberOfIncidents } = UseIncidentLogWidget();
    const { getIncidentLogUrl } = useUrlProvider();
    const theme = useTheme();

    function getYAxisTicks() {
        if (highestNumberOfIncidents === 1) return ["0", "1"];
        if (highestNumberOfIncidents <= 10) return ["0", "2", "4", "6", "8", "10"];
        if (highestNumberOfIncidents <= 25) return ["0", "5", "10", "15", "20", "25"];
        if (highestNumberOfIncidents <= 50) return ["0", "10", "20", "30", "40", "50"];
        if (highestNumberOfIncidents <= 100) return ["0", "20", "40", "60", "80", "100"];
        return undefined; //returning undefined will fallback to the default graph ticks
    }

    function getGraphTooltip(severity: number) {
        if (!data) return <Box></Box>;
        const incidents = data.find((x) => x.severity === severity);
        return (
            <Box
                sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    background: "white",
                    width: "200px",
                    paddingLeft: "10px",
                    paddingTop: "5px",
                    paddingRight: "10px",
                    paddingBottom: "5px",
                    justifyContent: "center",
                    borderRadius: "10px",
                    color: theme.palette.primary.dark,
                    boxShadow: "0px 3px 10px #E7E7E7",
                })}
            >
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <DotLegalHeader headerStyle={"small"} marginBottom={0} color={"lightgrey"}>
                        {translateString("incidents")}
                    </DotLegalHeader>
                </Box>
                {getGraphTooltipContent(incidents?.numberOfIncidents, severity)}
            </Box>
        );
    }

    function getGraphTooltipContent(numberOfIncidents: number | undefined, value: number) {
        if (!numberOfIncidents) return <React.Fragment></React.Fragment>;
        return (
            numberOfIncidents !== 0 && (
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "10px", marginRight: "10px", fontSize: "14px" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Box sx={{ width: "15px", height: "15px", backgroundColor: theme.palette.primary.main, marginRight: "10px" }}></Box>
                        <Box>{translateString(setFirstLetterToLowerCase(SeverityLevelEnum[value].toString()))}</Box>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ fontWeight: "bold" }}>{numberOfIncidents}</Box>
                    </Box>
                </Box>
            )
        );
    }

    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    const HorizontalTick = ({ textAnchor, textBaseline, value, x, y }) => {
        const val: string = translateString(setFirstLetterToLowerCase(SeverityLevelEnum[value].toString()));
        const t = val.split(" ").map((val, i) => (
            <tspan dy={i + 15} x={0} key={val}>
                {val}
            </tspan>
        ));

        return (
            <g transform={`translate(${x},${y})`}>
                <text alignmentBaseline={textBaseline} textAnchor={textAnchor} style={{ fontWeight: "bold", fontSize: 14, fill: theme.palette.primary.dark }}>
                    {t}
                </text>
            </g>
        );
    };

    return (
        <Widget isLoading={isLoading} disabled={!isEnabled}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <DotLegalHeader headerStyle={"medium"} marginBottom={0}>
                        {translateString("incidents").toUpperCase()}
                    </DotLegalHeader>
                </Box>
                {highestNumberOfIncidents === 0 ? (
                    <WhiteDashboardBox fullHeight marginTop={2}>
                        <Box sx={{ display: "flex" }}>
                            <DotLegalEmptyState
                                noFixedHeight
                                noPadding
                                textColor={"darkBlue"}
                                text={translateString("noIncidents")}
                                icon={<EmptyStateIcon />}
                            />
                        </Box>
                    </WhiteDashboardBox>
                ) : (
                    <Box
                        sx={{
                            width: "100%",
                            height: 0,
                            borderRadius: "20px",
                            flex: "1 1 auto",
                            marginTop: 2,
                            backgroundColor: "white",
                            "g rect:hover": { cursor: "pointer" },
                            paddingTop: "15px",
                        }}
                    >
                        {data && (
                            <ResponsiveBar
                                maxValue={highestNumberOfIncidents}
                                data={data}
                                keys={["numberOfIncidents"]}
                                indexBy="severity"
                                margin={{ top: 10, right: 60, bottom: 50, left: 60 }}
                                padding={0.3}
                                label={"false"}
                                valueScale={{ type: "linear" }}
                                colors={({ id }) => theme.palette.primary.main}
                                tooltip={(x) => getGraphTooltip(x.index + 1)}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legendPosition: "middle",
                                    legendOffset: 32,
                                    renderTick: HorizontalTick,
                                    format: (value) => translateString(setFirstLetterToLowerCase(SeverityLevelEnum[value].toString())),
                                }}
                                axisLeft={{
                                    legend: translateString("incidents"),
                                    legendPosition: "middle",
                                    legendOffset: -45,
                                    tickValues: getYAxisTicks(),
                                }}
                                theme={{
                                    axis: {
                                        legend: {
                                            text: {
                                                fontSize: 16,
                                                fill: theme.palette.primary.dark,
                                                fontWeight: "bold",
                                            },
                                        },
                                        ticks: {
                                            text: {
                                                fontSize: 14,
                                                fill: theme.palette.primary.dark,
                                                fontWeight: "bold",
                                            },
                                        },
                                    },
                                }}
                            />
                        )}
                    </Box>
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <NavigateTo color="purple" text={translateString("goToIncidentLog")} to={getIncidentLogUrl()} />
                </Box>
            </Box>
        </Widget>
    );
}

export default IncidentLogWidget;
