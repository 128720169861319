import { ISystemsCountWidgetProps } from "./SystemsCountWidget.tsx";
import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared.ts";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider.tsx";
import { useState } from "react";

export function useSystemsCount(props: ISystemsCountWidgetProps) {
    const userContext = useUserContext();
    const isEnabled = userContext.permissions.systemPermissions.read;
    const [counterValue, setCounterValue] = useState(0);
    const url = `/system/dashboard/systemsCount?onlyUserSpecific=${props.onlyUserSpecific}`;
    const { isLoading, data } = useQuery(url, () => get<number>(url), { enabled: isEnabled });

    return {
        isLoading,
        data,
        isEnabled,
        counterValue,
        setCounterValue,
    };
}
