import React from "react";
import { useTranslation } from "../../localization/useTranslation";
import { useProcessingActivityCountWidgetProps } from "./ProcessingActivityCountWiget.hooks";
import DashboardCountCard from "../DashboardCountCard";
import Widget from "../Widget";
import { useUrlProvider } from "../../useUrlProvider";
import { Box, CircularProgress, styled } from "@mui/material";
import { processingActivityAllOnlyUserSpecificStorageKey } from "../../processingActivity/processingActivities/ProcessingActivities.hooks";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";
import CountUp from "react-countup";

export interface ProcessingActivityCountWigetProps {
    onlyUserSpecific: boolean;
    selectedGroupCompany: string | undefined;
}

export const LargeCountText = styled("div")(({ theme }) => ({
    fontSize: theme.typography.pxToRem(56),
    lineHeight: 1,
}));

export const SmallCountText = styled("div")(({ theme }) => ({
    fontSize: theme.typography.pxToRem(24),
    lineHeight: 1.2,
}));

function ProcessingActivityCountWiget(props: ProcessingActivityCountWigetProps) {
    const { translateString } = useTranslation();
    const { getProcessingActivitiesOverviewUrl } = useUrlProvider();
    const { isLoading, data, isEnabled, counterValue, setCounterValue } = useProcessingActivityCountWidgetProps(props);

    const noOfCompletedPas = data?.filter((x) => x.progressScore === 100)?.length ?? 0;
    const percentageCompleted = Math.round((data?.length ?? 0 / 100) * counterValue);

    function CircularProgressWithLabel() {
        return (
            <Box>
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress value={100} sx={{ color: "#58649a" }} size={60} variant="determinate" />
                    <CircularProgress
                        variant="determinate"
                        sx={(theme) => ({
                            color: theme.palette.secondary.main,
                            position: "absolute",
                            left: 0,
                        })}
                        size={60}
                        value={percentageCompleted}
                    />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box sx={(theme) => ({ color: theme.customPalette.white, fontSize: 14 })}>{`${percentageCompleted} %`}</Box>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Widget noPadding disabled={!isEnabled}>
            <DashboardCountCard
                header={translateString("processingActivities")}
                background="darkblue"
                isLoading={isLoading}
                url={{
                    url: getProcessingActivitiesOverviewUrl(),
                    text: translateString("goToProcessingActivities"),
                    allMyPageKey: processingActivityAllOnlyUserSpecificStorageKey,
                    onlyUserSpecific: props.onlyUserSpecific,
                }}
                topRightElement={<CircularProgressWithLabel />}
            >
                {data && (
                    <Box sx={{ display: "flex", gap: 1, alignItems: "flex-end" }}>
                        <LargeCountText>
                            <Box>
                                {counterValue != noOfCompletedPas ? (
                                    <CountUp end={noOfCompletedPas} duration={5} onUpdate={() => setCounterValue(counterValue + 1)} />
                                ) : (
                                    counterValue
                                )}
                            </Box>
                        </LargeCountText>
                        <SmallCountText>
                            / {data.length}{" "}
                            {setFirstLetterToLowerCase(noOfCompletedPas < 2 ? translateString("completed") : translateString("completedPlural"))}
                        </SmallCountText>
                    </Box>
                )}
            </DashboardCountCard>
        </Widget>
    );
}

export default ProcessingActivityCountWiget;
