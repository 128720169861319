import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { DotLegalButton, DotLegalSearchField, DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import { LegalEntitySelectorViewModel } from "../LegalEntity.types";
import CloseIcon from "@mui/icons-material/Close";
import NoData from "./noData.svg?react";
import { useLegalEntityTableStyles } from "./LegalEntityTable.styles";
import LegalEntityAddButton from "../legalEntityAddButton/LegalEntityAddButton";
import LegalEntitySelectorDialog from "../legalEntitySelectorDialog/LegalEntitySelectorDialog";

export interface ILegalEntityTable {
    isLoading: boolean;
    legalEntities: LegalEntitySelectorViewModel[] | undefined;
    selectedEntities: Array<string>;
    showAddNewEntityButton: boolean;
    showAddAllEntityButton?: boolean;
    onLegalEntitiesChange?: (entities: Array<string>) => void;
    errorText?: string;
    disabled?: boolean;
    DontShowNoDataContainer?: boolean;
}

function LegalEntityTable(props: ILegalEntityTable) {
    const { translateString, translateCountry } = useTranslation();
    const styles = useLegalEntityTableStyles(props);
    const [searchString, setSearchString] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [gridLength, setGridlength] = React.useState(10);
    const [selectedEntitiesTemp, setSelectedEntitiesTemp] = useState<Array<string>>([]);

    const getHeaders = () => {
        let headers = Array<ITableHeader<LegalEntitySelectorViewModel>>();
        headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
        headers.push({ property: "address", text: translateString("address"), align: "left", showOnMobile: true });
        headers.push({ property: "zipCode", text: translateString("zipCode"), align: "left", showOnMobile: true });
        headers.push({ property: "city", text: translateString("city"), align: "left", showOnMobile: true });
        headers.push({ property: "country", text: translateString("country"), align: "left", showOnMobile: true });
        if (!props.disabled) headers.push({ property: null, text: "", align: "left", notSortable: true, showOnMobile: true });

        return headers;
    };

    function AddSelectedEntities() {
        if (props.onLegalEntitiesChange) props.onLegalEntitiesChange([...props.selectedEntities, ...selectedEntitiesTemp]);
        setSelectedEntitiesTemp([]);
    }

    function GetAvailableEntities() {
        if (props.isLoading) return [];
        else
            return props.legalEntities?.filter(function (item) {
                return !props.selectedEntities.includes(item.id);
            });
    }

    function AddAllSelectableEntities() {
        const availableEntities = GetAvailableEntities()?.map((x) => x.id);
        if (props.onLegalEntitiesChange && availableEntities) props.onLegalEntitiesChange([...props.selectedEntities, ...availableEntities]);
        setSelectedEntitiesTemp([]);
    }

    function GetSelectedEntities() {
        if (props.isLoading) return [];
        else {
            return props.legalEntities?.filter(function (item) {
                return props.selectedEntities.includes(item.id);
            });
        }
    }

    function RemoveEntity(id: string) {
        const entities = props.selectedEntities.filter(function (item) {
            return item !== id;
        });

        if (props.onLegalEntitiesChange) props.onLegalEntitiesChange(entities);
    }

    function RemoveAllEntities() {
        const available = props.legalEntities?.map((x) => x.id);
        const selected = GetSelectedEntities()?.map((x) => x.id);
        const filtered = selected?.filter((item) => !available?.includes(item));
        if (props.onLegalEntitiesChange) props.onLegalEntitiesChange(filtered ?? []);
    }

    function onLegalEntityCreated(id: string) {
        props.selectedEntities.push(id);

        if (props.onLegalEntitiesChange) props.onLegalEntitiesChange(props.selectedEntities);
    }

    function isAllEntitiesSelected() {
        const available = props.legalEntities?.map((x) => x.id);
        const selected = GetSelectedEntities()?.map((x) => x.id);
        return available?.every((item) => !selected?.includes(item));
    }
    const allEntitiesSelected = isAllEntitiesSelected();

    return (
        <React.Fragment>
            {props.selectedEntities.length > 0 ? (
                <React.Fragment>
                    <Box display="flex" justifyContent="flex-end">
                        <DotLegalSearchField labelSearch={translateString("search")} value={searchString} onChange={(e) => setSearchString(e)} />
                    </Box>
                    <DotLegalTable
                        headers={getHeaders()}
                        searchString={searchString}
                        emptyText={translateString("noData")}
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={props.isLoading}
                        data={GetSelectedEntities()}
                        paginationLabelOf={translateString("labelOf")}
                        rowsPerPage={10}
                        renderRow={(row, i) => (
                            <TableRow key={i}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.address}</TableCell>
                                <TableCell>{row.zipCode}</TableCell>
                                <TableCell>{row.city}</TableCell>
                                <TableCell>{translateCountry(row.country)}</TableCell>
                                {!props.disabled && (
                                    <TableCell>
                                        <IconButton size="small" onClick={() => RemoveEntity(row.id)}>
                                            <CloseIcon sx={styles.remove} />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        )}
                    />
                </React.Fragment>
            ) : !props.DontShowNoDataContainer ? (
                <Box sx={styles.noDataContainer} onClick={props.disabled ? undefined : () => setShowDialog(true)}>
                    <Box sx={styles.noDataImage}>
                        <NoData />
                    </Box>
                    {props.errorText ? (
                        <Box component={"span"} sx={styles.noDataInfo}>
                            {props.errorText}
                        </Box>
                    ) : (
                        <Box component={"span"} sx={styles.noDataInfo}>
                            {translateString("noCompaniesSelected")}
                        </Box>
                    )}
                </Box>
            ) : (
                <React.Fragment />
            )}

            <Box sx={styles.btnContent}>
                <Box sx={(theme) => ({ display: "flex", gap: theme.spacing(1) })}>
                    {!props.disabled && (
                        <Box
                            sx={(theme) => ({
                                "& .MuiButton-root": { fontWeight: theme.typography.fontWeightBold },
                            })}
                        >
                            <DotLegalButton btnLength={230} buttonType="secondary" onClick={() => setShowDialog(true)}>
                                {translateString("addExistingEntities")}
                            </DotLegalButton>
                        </Box>
                    )}
                    {props.showAddNewEntityButton && (
                        <LegalEntityAddButton onLegalEntityCreated={(id) => onLegalEntityCreated(id)} successMsg={translateString("companyAdded")} />
                    )}
                </Box>
                {!props.disabled && props.showAddAllEntityButton && (
                    <>
                        {allEntitiesSelected ? (
                            <DotLegalButton
                                disabled={props.isLoading}
                                buttonType={"linkButton"}
                                onClick={() => {
                                    AddAllSelectableEntities();
                                }}
                            >
                                {translateString("selectAllCompanies")}
                            </DotLegalButton>
                        ) : (
                            <DotLegalButton
                                disabled={props.isLoading}
                                buttonType={"linkButton"}
                                onClick={() => {
                                    RemoveAllEntities();
                                }}
                            >
                                {translateString("deselectAllCompanies")}
                            </DotLegalButton>
                        )}
                    </>
                )}
            </Box>

            {showDialog && (
                <LegalEntitySelectorDialog
                    gridLength={gridLength}
                    onGridChange={(e: any) => setGridlength(e)}
                    allColumns
                    isLoading={props.isLoading}
                    buttonOkText={translateString("add")}
                    setShowDialog={setShowDialog}
                    dialogHeader={translateString("addCompaniesToProcessingActivity")}
                    legalEntityItems={GetAvailableEntities()}
                    selectedEntities={selectedEntitiesTemp}
                    setSelectedEntities={setSelectedEntitiesTemp}
                    setOkClicked={() => AddSelectedEntities()}
                />
            )}
        </React.Fragment>
    );
}

export default LegalEntityTable;
