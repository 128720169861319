import { useEffect, useState } from "react";
import { TaskRelationRow } from "../TaskRelations.types";
import { IRelatedTasksSelector } from "./RelatedTasksSelector";
import { SelectableItem } from "../../../../processingActivity/ProcessingActivity.types";
import { useTranslation } from "../../../../localization/useTranslation";
import { setFirstLetterToLowerCase } from "../../../../common/stringOperations";
import { AnnualWheelMonthEnum } from "../../../../annualWheel/AnnualWheel.types";
import { sortTableData } from "@dotlegal/dotlegal-ui-components";

export default function useRelatedTasksSelectorHooks(props: IRelatedTasksSelector) {
    const { translateString } = useTranslation();
    const [orderBy, setOrderBy] = useState<string>("deadline");
    const [order, setOrder] = useState<"asc" | "desc">("asc");
    const [hoveredTask, setHoveredTask] = useState("");
    const [addClicked, setAddClicked] = useState(false);
    const [showSelectActivities, setShowSelectActivities] = useState(false);

    useEffect(() => {
        if (addClicked) {
            setAddClicked(false);
        }
    }, [addClicked]);

    useEffect(() => {
        if (props.shouldAddRelatedTask) {
            showAndAddRelatedTask();
            props.updateRelatedTaskScenario();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.shouldAddRelatedTask]);

    function showAndAddRelatedTask() {
        if (!showSelectActivities) {
            setShowSelectActivities(true);
        }

        setAddClicked(true);
    }

    function onSortClick(columnName: string) {
        setOrderBy(columnName);
        setOrder(order === "asc" ? "desc" : "asc");
    }

    function formatDate(deadline?: Date) {
        if (deadline) {
            var date = new Date(deadline);

            return `${translateString(setFirstLetterToLowerCase(AnnualWheelMonthEnum[date.getMonth() + 1].toString()))} ${date.getFullYear()}`;
        }

        return "";
    }

    let sortedData: Array<TaskRelationRow> = [];
    if (props.selectedTasks) {
        sortedData = sortTableData(props.selectedTasks, orderBy, order);
    }

    let selectableTasks: Array<SelectableItem> = [];
    if (props.selectableTasks) {
        selectableTasks = props.selectableTasks
            .map((x) => {
                var name = `${x.activityName} - ${formatDate(x.deadline)} - ${x.groupEntity ? x.groupEntity : translateString("entireGroup")}`;

                return { id: x.taskId, name: name };
            })
            .sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    return {
        sortedData,
        order,
        orderBy,
        onSortClick,
        hoveredTask,
        setHoveredTask,
        addClicked,
        showSelectActivities,
        setShowSelectActivities,
        showAndAddRelatedTask,
        formatDate,
        selectableTasks,
    };
}
