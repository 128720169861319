import { useTheme } from "@mui/material";
import { createSxStyles } from "../../common/createSxStyles";

export const useProcessingActivitiesStyle = (width: number) => {
    const theme = useTheme();
    return createSxStyles({
        paperContainer: {
            flex: "1",
            display: "flex",
            flexDirection: "column",
        },
        bold: {
            fontWeight: theme.typography.fontWeightBold,
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            "& svg": {
                width: "14px",
            },
        },
        value: {
            marginLeft: "10px",
            fontSize: "18px",
            fontWeight: theme.typography.fontWeightBold,
        },
        paperHeader: {
            display: "flex",
            alignItems: "center",
            height: 116,
            marginBottom: theme.spacing(2),
            position: "relative",
            justifyContent: "space-between",
        },
        headerImg: {
            position: "absolute",
            bottom: -4,
            right: 300,
            display: width < 1060 ? "none" : undefined,
        },
        percentageContainer: {
            display: "flex",
            alignItems: "last baseline",
            width: 238,
        },
        container: {
            width: "100%",
            /* height: "100%", */
            display: "flex",
            flexDirection: "column",
        },
        switchBoxContainer: {
            display: "inline-block",
        },
        gridAlignment: {
            display: "flex",
            justifyContent: "flex-end",
        },
        gridContainer: {
            marginBottom: theme.spacing(1.5),
            alignItems: "center",
        },
        gridContainerSM: {
            marginBottom: theme.spacing(0.5),
            alignItems: "center",
        },
        noDataInfoHeader: {
            color: theme.palette.primary.main,
            marginTop: theme.spacing(1),
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(12),
        },
        riskAssessmentScoreToolTip: {
            margin: theme.spacing(1),
        },
        chip: {
            margin: theme.spacing(0.5),
            display: "inline-block",
        },
    });
};
