import React from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import {
    DotLegalButton,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalTableWithControls,
    useIsOnSmallScreen,
    DotLegalSwitch,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import CheckIcon from "@mui/icons-material/Check";
import masterIcon from "../../masterData.svg?url";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider";
import NationalLawDialog from "../nationalLawDialog/NationalLawDialog";
import { useNationalLawStyles } from "./NationalLaws.styles";
import { useNationalLawsDataMapping } from "./NationalLaws.hooks";
import { NationalLawViewModel } from "./NationalLaws.types";
import { DeleteNationalLawDialog } from "../deleteNationaLawDialog/DeleteNationalLawDialog";
import { ChangeNationalLawStatusDialog } from "../changeNationalLawStatusDialog/ChangeNationalLawStatusDialog";
import { getSettings } from "../../../common/settingsProvider";
import { useUrlProvider } from "../../../useUrlProvider";

function BusinessAreas() {
    const styles = useNationalLawStyles();
    const { translateString } = useTranslation();
    const { permissions, customerName } = useUserContext();
    const { getBreadCrumbs } = useMasterDataBreadCrumbProvider();
    const isOnSmallScreen = useIsOnSmallScreen();
    const settings = getSettings();
    const urlProvider = useUrlProvider();

    const {
        isLoading,
        data,
        setShowAllNationalLaws,
        showAllNationalLaws,
        OnSave,
        showNationalLawDialog,
        setShowNationalLawDialog,
        showDeleteNationalLawDialog,
        setShowDeleteNationalLawDialog,
        showChangeNationalLawStatusDialog,
        setShowChangeNationalLawStatusDialog,
        selectedNationalLaw,
        setSelectedNationalLaw,
    } = useNationalLawsDataMapping();

    const getMenuItems = (nationalLaw: NationalLawViewModel) => {
        let menuItems = [];

        if (permissions.canManageMasterData) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedNationalLaw(nationalLaw);
                    setShowNationalLawDialog(true);
                },
            });

            menuItems.push({
                menuItem: nationalLaw.active ? translateString("deactivate") : translateString("activate"),
                onClick: () => {
                    setSelectedNationalLaw(nationalLaw);
                    setShowChangeNationalLawStatusDialog(true);
                },
            });
        }

        if (permissions.canManageMasterData && nationalLaw.isCustom) {
            menuItems.push({
                menuItem: translateString("removeNationalLaw"),
                onClick: () => {
                    setSelectedNationalLaw(nationalLaw);
                    setShowDeleteNationalLawDialog(true);
                },
            });
        }

        return menuItems;
    };

    const openCreateDialog = () => {
        setShowNationalLawDialog(true);
        setSelectedNationalLaw(undefined);
    };

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={getBreadCrumbs(translateString("nationalLaws"))}
                leftComponent={
                    <DotLegalSwitch
                        onChange={setShowAllNationalLaws}
                        checked={showAllNationalLaws}
                        leftLabel={translateString("active")}
                        rightLabel={translateString("all")}
                    />
                }
            />
            <div>
                <DotLegalPaper sx={styles.paperHeader} background="blueWave" backgroundCover>
                    <Box sx={{ display: "flex" }}>
                        {permissions.canManageMasterData && (
                            <DotLegalButton buttonType="primary" size="medium" onClick={() => openCreateDialog()}>
                                {translateString("createNationalLaw")}
                            </DotLegalButton>
                        )}
                        <Box
                            sx={(theme) => ({
                                "& .MuiButton-root": {
                                    marginLeft: theme.spacing(2),
                                    [theme.breakpoints.down("xs")]: {
                                        marginLeft: 0,
                                        marginTop: theme.spacing(1),
                                        display: "block",
                                    },
                                },
                            })}
                        >
                            <DotLegalButton buttonType="secondary" size="medium" href={urlProvider.getLegislationsUrl()}>
                                {translateString("manageLegislations")}
                            </DotLegalButton>
                        </Box>
                    </Box>

                    {!isOnSmallScreen && <Box component={"img"} sx={styles.headerImg} src={`${settings.mainSiteBaseUrl}${masterIcon}`} alt={masterIcon}></Box>}
                </DotLegalPaper>
            </div>

            <Box sx={styles.paperContainer}>
                <DotLegalPaper>
                    <DotLegalTableWithControls
                        headers={getHeaders(translateString)}
                        getUserSpecificPageLength={() => 30}
                        hideRowsPerPage
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        emptyText={translateString("noData")}
                        clickableRows={false}
                        data={data}
                        renderRow={(row, i) => (
                            <TableRow key={row.id}>
                                <TableCell sx={styles.bold}>{row.legislationName}</TableCell>
                                <TableCell sx={styles.bold}>{row.name}</TableCell>
                                <TableCell sx={styles.bold}>{row.reference}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>{row.provision}</TableCell>
                                <TableCell>{row.section}</TableCell>
                                <TableCell>{row.number}</TableCell>
                                <TableCell>{row.cf}</TableCell>
                                <TableCell>
                                    <Box sx={styles.iconContainer}>{row.active ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={styles.iconContainer}>{!row.isCustom ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                                </TableCell>
                                <TableCell align={"right"}>
                                    <DotLegalOverflowMenu items={getMenuItems(row)} />
                                </TableCell>
                            </TableRow>
                        )}
                    />
                </DotLegalPaper>

                {showNationalLawDialog && (
                    <NationalLawDialog
                        onDialogClose={() => setShowNationalLawDialog(false)}
                        refetch={OnSave}
                        open={showNationalLawDialog}
                        nationalLawId={selectedNationalLaw?.id}
                        isAdd={selectedNationalLaw === undefined}
                        nationalLaw={selectedNationalLaw}
                        onOkClick={() => setShowNationalLawDialog(false)}
                    />
                )}

                {showDeleteNationalLawDialog && (
                    <DeleteNationalLawDialog
                        selectedNationalLaw={selectedNationalLaw!}
                        onCloseDialog={() => setShowDeleteNationalLawDialog(false)}
                        onSaveCorrect={OnSave}
                    />
                )}

                {showChangeNationalLawStatusDialog && (
                    <ChangeNationalLawStatusDialog
                        selectedNationalLaw={selectedNationalLaw!}
                        onStatusChange={OnSave}
                        onCloseDialog={() => setShowChangeNationalLawStatusDialog(false)}
                    />
                )}
            </Box>
        </Box>
    );
}

export default BusinessAreas;

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<NationalLawViewModel>>();
    headers.push({ property: "legislationName", text: translateString("legislation"), align: "left", showOnMobile: true });
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "reference", text: translateString("reference"), align: "left", showOnMobile: true });
    headers.push({ property: "description", text: translateString("description"), align: "left", showOnMobile: true });
    headers.push({ property: "provision", text: translateString("provisionOrArticle"), align: "left", showOnMobile: true });
    headers.push({ property: "section", text: translateString("section"), align: "left", showOnMobile: true });
    headers.push({ property: "number", text: translateString("numberOrLitra"), align: "left", showOnMobile: true });
    headers.push({ property: "cf", text: translateString("cf"), align: "left", showOnMobile: true });
    headers.push({ property: "active", text: translateString("active"), align: "left", showOnMobile: true });
    headers.push({ property: "isCustom", text: translateString("standardMasterData"), align: "left", showOnMobile: true });

    return headers;
}
