import { LegalEntityAuditGroupOverviewModel } from "../../legalEntityAuditGroupOverview/LegalEntityAuditGroupOverview.types.ts";
import { Box, Divider, Grid, GridSize } from "@mui/material";
import { DotLegalCompletedPercentage, DotLegalFixedHeightText, DotLegalHeader, DotLegalHeading, DotLegalPaper } from "@dotlegal/dotlegal-ui-components";
import { LegalEntityAuditStatus } from "../../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types.ts";
import QuestionnaireTemplateLink from "../../../legalEntity/legalEntityAuditTab/QuestionnaireTemplateLink.tsx";
import DotLegalLink from "../../../common/components/dotLegalLink/DotLegalLink.tsx";
import QuestionnaireRisk from "../../../common/components/questionnaireComponents/questionnaireRisk/QuestionnaireRisk.tsx";
import { getQuestionnaireResponseScore } from "../../../common/questionnaireResponseScore.ts";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation.ts";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider.tsx";
import { useUrlProvider } from "../../../useUrlProvider.ts";

export interface IManagementReportSummaryProps {
    data: LegalEntityAuditGroupOverviewModel;
    isLoading: boolean;
    isPdf: boolean;
}

function ManagementReportSummary(props: IManagementReportSummaryProps) {
    const { translateString, translateLegalEntityAuditStatus, translateDate } = useTranslation();
    const { customerName } = useUserContext();
    const { getGroupEntityUrl } = useUrlProvider();

    const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: boolean } = {
        item: true,
        xs: 12,
        sm: props.isPdf ? 3 : 6,
        md: 4,
        lg: 2,
        xl: true,
    };

    return (
        <Box mt={2}>
            <DotLegalHeader headerStyle={"xxxl"}>{translateString("summary")}</DotLegalHeader>
            <DotLegalPaper
                sx={{ marginBottom: 2, minHeight: 132, backgroundPositionX: "right", boxShadow: "none" }}
                background="purpleWave2"
                backgroundColor={"lightPurple"}
            >
                <Grid container>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("status")}>
                            <DotLegalFixedHeightText
                                isLoading={props.isLoading}
                                fontSize="xs"
                                content={
                                    props.data
                                        ? props.data.progress === 100
                                            ? translateString("completed")
                                            : props.data?.sent
                                              ? translateLegalEntityAuditStatus(LegalEntityAuditStatus.InProgress.toString())
                                              : ""
                                        : ""
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("sent")}>
                            <DotLegalFixedHeightText
                                isLoading={props.isLoading}
                                fontSize="xs"
                                content={props.data?.sent ? translateDate(props.data.sent) : ""}
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("auditTemplate")}>
                            <DotLegalFixedHeightText
                                isLoading={props.isLoading}
                                fontSize="xs"
                                content={
                                    props.data ? (
                                        <DotLegalFixedHeightText
                                            isLoading={props.isLoading}
                                            fontSize="xs"
                                            content={
                                                props.data ? (
                                                    <QuestionnaireTemplateLink id={props.data.auditTemplateId} name={props.data.auditTemplate} type={"audit"} />
                                                ) : (
                                                    ""
                                                )
                                            }
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("deadline")}>
                            <DotLegalFixedHeightText isLoading={props.isLoading} fontSize="xs" content={props.data ? translateDate(props.data.deadline) : ""} />
                        </DotLegalHeading>
                    </Grid>
                </Grid>
                <Divider
                    sx={{
                        backgroundColor: "#F0F7FC",
                        marginBottom: 2,
                        marginTop: 2,
                        marginLeft: 6,
                        marginRight: 6,
                    }}
                />
                <Grid container>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("legalEntities")}>
                            <DotLegalFixedHeightText isLoading={props.isLoading} content={props.data?.numberOfLegalEntities ?? ""} fontSize="xl" />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("opened")}>
                            <DotLegalFixedHeightText isLoading={props.isLoading} content={props.data?.openedAuditsCount ?? ""} fontSize="xl" />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("submitted")}>
                            <DotLegalFixedHeightText isLoading={props.isLoading} content={props.data?.submittedAuditsCount ?? ""} fontSize="xl" />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("excluded")}>
                            <DotLegalFixedHeightText isLoading={props.isLoading} content={props.data?.excludedAuditsCount ?? ""} fontSize="xl" />
                        </DotLegalHeading>
                    </Grid>
                </Grid>
                <Divider
                    sx={{
                        backgroundColor: "#F0F7FC",
                        marginBottom: 2,
                        marginTop: 2,
                        marginLeft: 6,
                        marginRight: 6,
                    }}
                />
                <Grid container>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("responsible")}>
                            <DotLegalFixedHeightText isLoading={props.isLoading} fontSize="xs" content={props.data ? props.data.responsible : ""} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("sentFrom")}>
                            <DotLegalFixedHeightText
                                isLoading={props.isLoading}
                                fontSize="xs"
                                content={
                                    props.data ? (
                                        props.data.sender ? (
                                            props.data.senderId ? (
                                                <DotLegalLink bold linkColor={"primary"} to={getGroupEntityUrl(props.data.senderId)}>
                                                    {props.data.sender}
                                                </DotLegalLink>
                                            ) : (
                                                props.data.sender
                                            )
                                        ) : (
                                            customerName
                                        )
                                    ) : (
                                        ""
                                    )
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    {props.data && props.data.scoreEnabled && (
                        <Grid {...gridProps}>
                            <DotLegalHeading name={translateString("overallRiskLevelAndScore")}>
                                <DotLegalFixedHeightText
                                    isLoading={props.isLoading}
                                    fontSize="xs"
                                    content={
                                        props.data && props.data.overallRiskLevelName && props.data.overallRiskLevelName && props.data.overallRiskScore ? (
                                            <Box sx={{ display: "flex", justifyContent: "center", gap: 1, fontWeight: "bold", marginTop: "6px" }}>
                                                <QuestionnaireRisk
                                                    riskLevel={props.data?.overallRiskLevel}
                                                    riskName={props.data?.overallRiskLevelName}
                                                    scoreEnabled={props.data.scoreEnabled}
                                                    status={LegalEntityAuditStatus.Submitted}
                                                />
                                                (
                                                {getQuestionnaireResponseScore(
                                                    true,
                                                    props.data.scoreEnabled,
                                                    props.data.overallRiskScore,
                                                    translateString("scoreNotEnabled")
                                                )}
                                                )
                                            </Box>
                                        ) : undefined
                                    }
                                />
                            </DotLegalHeading>
                        </Grid>
                    )}
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("progress")}>
                            <DotLegalFixedHeightText
                                isLoading={props.isLoading}
                                fontSize="sm"
                                content={
                                    props.data ? (
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <Box width={238}>
                                                <DotLegalCompletedPercentage completed={props.data.progress} />
                                            </Box>
                                        </Box>
                                    ) : undefined
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    {props.data && !props.data.scoreEnabled && <Grid {...gridProps}></Grid>}
                </Grid>
            </DotLegalPaper>
        </Box>
    );
}

export default ManagementReportSummary;
