import React from "react";
import { DotLegalPageHeader, useStateLocalStorage } from "@dotlegal/dotlegal-ui-components";
import DotLegalAllOrMySwitchBox from "../common/components/dotLegalSwitch/dotLegalAllOrMySwitchBox/dotLegalAllOrMySwitchBox.tsx";
import { useUserContext } from "../auth/userContextProvider/UserContextProvider.tsx";
import { useTranslation } from "../localization/useTranslation.ts";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import Widget from "./Widget.tsx";
import ProcessingActivityCountWiget from "./processingActivityCountWidget/ProcessingActivityCountWiget.tsx";
import VendorsCountWidget from "./vendorsCountWidget/VendorsCountWidget.tsx";
import RiskWidget from "./riskWidget/RiskWidget.tsx";
import SystemsCountWidget from "./systemsCountWidget/SystemsCountWidget.tsx";
import TasksCountWidget from "./tasksCountWidget/TasksCountWidget.tsx";
import TasksWidget from "./tasksWidget/TaskWidget.tsx";
import TaskOverviewWidget from "./taskOverviewWidget/TaskOverviewWidget.tsx";
import LatestAuditsSubmittedWidget from "./latestAuditsSubmittedWidget/LatestAuditsSubmittedWidget.tsx";
import DotLegalCompanySelector from "../common/components/dotLegalCompanySelector/DotLegalCompanySelector.tsx";
import { useGroupEntitiesForUserQuery } from "../common/hooks/useSelectableItemQueries.ts";
import IncidentLogWidget from "./incidentLogWidget/IncidentLogWidget.tsx";

export interface IDashboardProps {}

function Dashboard(props: IDashboardProps) {
    const { translateString } = useTranslation();
    const theme = useTheme();
    const isOnXlScreen = useMediaQuery(theme.breakpoints.up("xl"), { noSsr: true });
    const { permissions, customerName, fullName } = useUserContext();
    const [onlyUserSpecific, setOnlyUserSpecific] = useStateLocalStorage("recordOnlyUserSpecific", !permissions.canAccessAllData);
    const [selectedGroupCompany, setSelectedGroupCompany] = useStateLocalStorage("dashboard-selected-group-entity", "");

    let groupEntitiesForUserQuery = useGroupEntitiesForUserQuery();

    let mbCompensation = isOnXlScreen ? -2 : 0;

    const heightTaskAndRisk = isOnXlScreen ? "auto" : "740px";
    const heightOtherWidgets = isOnXlScreen ? "50%" : "450px";
    return (
        <>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={[
                    {
                        name: translateString("userGreeting", { firstName: fullName.substring(0, fullName.indexOf(" ")) }),
                    },
                ]}
                rightComponent={
                    <DotLegalCompanySelector
                        isLoading={groupEntitiesForUserQuery.isLoading}
                        selectedCompany={selectedGroupCompany ?? ""}
                        setSelectedCompany={(selectedCompany) => setSelectedGroupCompany(selectedCompany)}
                        companies={groupEntitiesForUserQuery.data ?? []}
                    />
                }
                leftComponent={<DotLegalAllOrMySwitchBox onChange={setOnlyUserSpecific} enabled={onlyUserSpecific} hidden={!permissions.canAccessAllData} />}
            />
            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", mb: mbCompensation }}>
                <Box sx={{ padding: 2, backgroundColor: "#EEEFFA", borderRadius: "20px", mb: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item md={6} lg={3} xs={12}>
                            <ProcessingActivityCountWiget onlyUserSpecific={onlyUserSpecific} selectedGroupCompany={selectedGroupCompany} />
                        </Grid>
                        <Grid item md={6} lg={3} xs={12}>
                            <SystemsCountWidget onlyUserSpecific={onlyUserSpecific} />
                        </Grid>
                        <Grid item md={6} lg={3} xs={12}>
                            <VendorsCountWidget onlyUserSpecific={onlyUserSpecific} />
                        </Grid>
                        <Grid item md={6} lg={3} xs={12}>
                            <TasksCountWidget onlyUserSpecific={onlyUserSpecific} selectedGroupCompany={selectedGroupCompany} />
                        </Grid>
                    </Grid>
                </Box>

                {/* Bottom Row Filling Remaining Space */}
                <Box style={{ flexGrow: 1 }}>
                    <Grid container spacing={2} sx={{ height: "100%" }}>
                        <Grid item md={6} xl={3} xs={12} sx={{ height: heightTaskAndRisk }}>
                            <TasksWidget onlyUserSpecific={onlyUserSpecific} selectedGroupCompany={selectedGroupCompany} />
                        </Grid>
                        <Grid item md={6} xl={3} xs={12} sx={{ height: heightTaskAndRisk }}>
                            <RiskWidget onlyUserSpecific={onlyUserSpecific} selectedGroupCompany={selectedGroupCompany} />
                        </Grid>
                        <Grid mb={mbCompensation} item md={12} xl={6} xs={12}>
                            <Grid spacing={2} container sx={{ height: "100%" }}>
                                <Grid item md={6} lg={6} xs={12} sx={{ height: heightOtherWidgets }}>
                                    <LatestAuditsSubmittedWidget onlyUserSpecific={onlyUserSpecific} selectedGroupCompany={selectedGroupCompany} />
                                </Grid>
                                <Grid item md={6} lg={6} xs={12} sx={{ height: heightOtherWidgets }}>
                                    <IncidentLogWidget />
                                </Grid>
                                <Grid item xs={12} sx={{ height: heightOtherWidgets }}>
                                    <TaskOverviewWidget onlyUserSpecific={onlyUserSpecific} selectedGroupCompany={selectedGroupCompany} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}

export default Dashboard;
