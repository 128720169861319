import React from "react";
import Widget from "../Widget";
import { useTranslation } from "../../localization/useTranslation";
import { useUrlProvider } from "../../useUrlProvider";
import DashboardCountCard from "../DashboardCountCard";
import { useVendorsCountWidget } from "./VendorsCountWidget.hooks";
import { Box } from "@mui/material";
import { LargeCountText } from "../processingActivityCountWidget/ProcessingActivityCountWiget";
import CountUp from "react-countup";

export interface VendorsCountWidgetProps {
    onlyUserSpecific: boolean;
}

function VendorsCountWidget(props: VendorsCountWidgetProps) {
    const { translateString } = useTranslation();
    const { getLegalEntitiesUrl } = useUrlProvider();
    const { isLoading, data, isEnabled, counterValue, setCounterValue } = useVendorsCountWidget(props);

    return (
        <Widget noPadding disabled={!isEnabled}>
            <DashboardCountCard
                isLoading={isLoading}
                header={translateString("vendors")}
                background="purple"
                icon="building"
                url={{ url: getLegalEntitiesUrl(), text: translateString("goToVendors") }}
            >
                {data !== undefined && (
                    <Box sx={{ display: "flex", gap: 1, alignItems: "flex-end" }}>
                        <LargeCountText>
                            <Box>{counterValue != data ? <CountUp end={data} duration={5} onEnd={() => setCounterValue(data)} /> : counterValue}</Box>
                        </LargeCountText>
                    </Box>
                )}
            </DashboardCountCard>
        </Widget>
    );
}

export default VendorsCountWidget;
