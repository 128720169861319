import React from "react";
import { DotLegalFile, DotLegalFileUpload } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";

export interface IDotLegalFileUploadWrapperProps {
    onFilesChanged: (files?: Array<DotLegalFile>) => void;
    files: Array<string>;
    isLoading?: boolean;
    disabled?: boolean;
    errorText?: string;
    multiple?: boolean;
    shouldOpenFileDialog?: boolean;
    onFileDialogCancel?: () => void;
}

function DotLegalFileUploadWrapper(props: IDotLegalFileUploadWrapperProps) {
    const { translateString } = useTranslation();
    return (
        <DotLegalFileUpload
            {...props}
            shouldOpenFileDialog={props.shouldOpenFileDialog}
            onFileDialogCancel={props.onFileDialogCancel}
            dropZoneText={translateString("dragDrop")}
            choseFileText={translateString("chooseFile")}
            numberOfFilesChosenText={translateString("numberOfFilesChosen")}
            dropFileText={translateString("dropFile")}
            fileWithoutErrorText={translateString("fileWithoutErrorText")}
        />
    );
}

export default DotLegalFileUploadWrapper;
