import React from "react";
import AuditGroupHeader from "./auditGroupHeader/AuditGroupHeader";
import { useTranslation } from "../../localization/useTranslation";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { useUrlProvider } from "../../useUrlProvider";
import { useLegalEntityAuditGroupDialog } from "./LegalEntityAuditGroupOverview.hooks";
import ExternalAuditEmptyStateIcon from "../externalAuditsEmptyState.svg?react";
import {
    DotLegalButton,
    DotLegalCompletedPercentage,
    DotLegalEmptyState,
    DotLegalFixedHeightText,
    DotLegalHeading,
    DotLegalMultiSelect,
    DotLegalPaper,
    DotLegalTableWithControls,
    DotLegalTooltip,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { Box, Divider, Grid, GridSize, IconButton, Skeleton, TableCell, TableRow } from "@mui/material";
import { LegalEntityAuditGroupAuditModel, LegalEntityAuditGroupAuditTableModel } from "./LegalEntityAuditGroupOverview.types";
import { setFirstLetterToLowerCase } from "../../common/stringOperations";
import { LegalEntityContactType } from "../../legalEntity/legalEntityContactTab/LegalEntityContactTab.types";
import QuestionnaireStatus from "../../common/components/questionnaireComponents/questionnaireStatus/QuestionnaireStatus";
import QuestionnaireRisk from "../../common/components/questionnaireComponents/questionnaireRisk/QuestionnaireRisk";
import { getQuestionnaireResponseScore } from "../../common/questionnaireResponseScore";
import { LegalEntityAuditStatus } from "../../legalEntity/legalEntityAuditTab/LegalEntityAudit.types";
import { NoteSVG } from "../../common/icons";
import QuestionnaireReportOverflowButtons from "../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportOverflowButtons";
import QuestionnaireReportButtons from "../../common/components/questionnaireComponents/questionnaireReportButtons/QuestionnaireReportButtons";
import LegalEntityAuditTableOverflowMenu from "../../legalEntity/legalEntityAuditTab/legalEntityAuditTableOverflowMenu/LegalEntityAuditTableOverflowMenu";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";
import QuestionnaireTemplateLink from "../../legalEntity/legalEntityAuditTab/QuestionnaireTemplateLink.tsx";
import ClassificationRisk from "../../common/components/questionnaireComponents/classiificationRisk/ClassificationRisk.tsx";
import { LegalEntityMasterType, LegalEntityPageEnum } from "../../legalEntity/legalEntities/LegalEntities.types.ts";
import SystemLinks from "../../system/SystemLinks.tsx";
import QuestionnaireEvaluation from "../../common/components/questionnaireComponents/questionnaireEvaluation/QuestionnaireEvaluation.tsx";
import { EvaluationStatus } from "../../common/components/questionnaireComponents/evaluateQuestionnaireDialog/EvaluateQuestionnaireDialog.types.ts";
import { LegalEntityAuditTabs } from "../../legalEntity/LegalEntity.types.ts";

export interface LegalEntityAuditGroupOverviewProps {}

function LegalEntityAuditGroupOverview() {
    const { translateString, translateDate, translateLegalEntityAuditStatus } = useTranslation();
    const { customerName } = useUserContext();
    const { getAuditGroupsUrl, getLegalEntityUrl, getGroupEntityUrl, getAuditGroupManagementReportUrl } = useUrlProvider();
    const {
        isLoading,
        data,
        rows,
        onAuditGroupUpdated,
        showNote,
        setShowNote,
        saveNote,
        isDownloadingPdf,
        onDownloadReportAsPdf,
        getReportPdfUrl,
        refetch,
        filters,
        searchedType,
        setSearchedType,
        searchedRiskLevel,
        setSearchedRisklevel,
        searchedClassifications,
        setSearchedClassifications,
        searchedAssets,
        setSearchedAssets,
        searchedStatus,
        setSearchedStatus,
        auditIsLoading,
        onDownloadManagementReport,
        isDownloadingManagementReport,
        searchedEvaluations,
        setSearchedEvaluations,
    } = useLegalEntityAuditGroupDialog();

    const gridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: boolean } = {
        item: true,
        xs: 12,
        sm: 6,
        md: 4,
        lg: 2,
        xl: true,
    };

    function getHeaders() {
        let headers = Array<ITableHeader<LegalEntityAuditGroupAuditTableModel>>();
        headers.push({ property: "legalEntityName", text: translateString("legalEntity"), align: "left", showOnMobile: true });
        headers.push({ property: "uniqueIdentifier", text: translateString("id"), align: "left", showOnMobile: true });
        headers.push({ property: "legalEntityTypes", text: translateString("type"), align: "left", showOnMobile: true });
        headers.push({ property: "contact", text: translateString("contact"), align: "left", showOnMobile: true });
        headers.push({ property: "assets", text: translateString("assets"), align: "left", showOnMobile: true });
        headers.push({ property: "classifications", text: translateString("classifications"), align: "left", showOnMobile: true });
        headers.push({ property: "status", text: translateString("status"), align: "left", showOnMobile: false });
        headers.push({ property: "riskLevel", text: translateString("riskLevel"), align: "left", showOnMobile: false });
        headers.push({ property: "score", text: translateString("score"), align: "left", showOnMobile: true });
        headers.push({ property: "evaluation", text: translateString("evaluation"), align: "left", showOnMobile: true });
        headers.push({ property: "report", text: translateString("legalEntityAuditReport"), align: "left", showOnMobile: true });
        headers.push({ property: "reminderSent", text: translateString("headerReminderSent"), align: "left", showOnMobile: true });

        headers.push({ property: "evaluationName", text: "", align: "left", showOnMobile: true, hideHeader: true });

        headers.push({ property: "note", text: translateString("note"), align: "left", showOnMobile: true });
        headers.push({ property: "assetsString", text: translateString("assets"), align: "left", showOnMobile: false, hideHeader: true });
        headers.push({ property: "reminderSentString", text: "", align: "left", showOnMobile: false, hideHeader: true });

        return headers;
    }

    function getReportButtons(row: LegalEntityAuditGroupAuditTableModel) {
        const reportUrl = getReportPdfUrl(row, false, row.hasRecommendations);
        const showButtons = row.status !== LegalEntityAuditStatus.Opened && row.status !== LegalEntityAuditStatus.Sent && !row.closed;
        const downloadingPdf = isDownloadingPdf === row.id;

        if (row.scoreEnabled || row.hasRecommendations) {
            return (
                <QuestionnaireReportOverflowButtons
                    reportPdfUrl={reportUrl}
                    reportPdfMenuItems={getReportButtonMenuItems(row)}
                    showButtons={showButtons}
                    isDownloadingPdf={downloadingPdf}
                />
            );
        }

        return (
            <QuestionnaireReportButtons
                isDownloadingPdf={downloadingPdf}
                onDownloadReportPdf={() => onDownloadReportAsPdf(row, true, row.hasRecommendations)}
                reportPdfUrl={reportUrl}
                showButtons={showButtons}
            />
        );
    }

    function getReportButtonMenuItems(row: LegalEntityAuditGroupAuditTableModel) {
        if (row.scoreEnabled) {
            return [
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithScoreAndRecommendations") : translateString("downloadWithScore"),
                    onClick: () => onDownloadReportAsPdf(row, false, row.hasRecommendations),
                },
                {
                    menuItem: row.hasRecommendations ? translateString("downloadWithoutScoreAndRecommendations") : translateString("downloadWithoutScore"),
                    onClick: () => onDownloadReportAsPdf(row, true, false),
                },
            ];
        } else {
            return [
                { menuItem: translateString("downloadWithRecommendations"), onClick: () => onDownloadReportAsPdf(row, false, true) },
                {
                    menuItem: translateString("downloadWithoutRecommendations"),
                    onClick: () => onDownloadReportAsPdf(row, false, false),
                },
            ];
        }
    }

    function getSearchFields() {
        const tableGridProps: { item: boolean; xs: GridSize; sm: GridSize; md: GridSize; lg: GridSize; xl: boolean } = {
            item: true,
            xs: 12,
            sm: 6,
            md: 4,
            lg: 2,
            xl: true,
        };

        return (
            <React.Fragment>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedType}
                        chosenOptions={searchedType}
                        label={translateString("type")}
                        placeholder={translateString("type")}
                        isLoading={isLoading}
                        options={filters.typeSelectOptions}
                        noMargin
                        limit={1}
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>

                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedAssets}
                        chosenOptions={searchedAssets}
                        label={translateString("assets")}
                        placeholder={translateString("assets")}
                        isLoading={isLoading}
                        options={filters.assetsOptions}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>

                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedClassifications}
                        chosenOptions={searchedClassifications}
                        label={translateString("classifications")}
                        placeholder={translateString("classifications")}
                        isLoading={isLoading}
                        options={filters.classificationOptions}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>

                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedStatus}
                        chosenOptions={searchedStatus}
                        label={translateString("status")}
                        placeholder={translateString("status")}
                        isLoading={isLoading}
                        options={filters.statusOptions}
                        noMargin
                        noOptionsLabel={translateString("status")}
                    />
                </Grid>

                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        onChange={setSearchedRisklevel}
                        chosenOptions={searchedRiskLevel}
                        label={translateString("riskLevel")}
                        placeholder={translateString("riskLevel")}
                        isLoading={isLoading}
                        options={filters.riskLevelOptions}
                        noMargin
                        noOptionsLabel={translateString("noOptions")}
                    />
                </Grid>
                <Grid {...tableGridProps}>
                    <DotLegalMultiSelect
                        options={filters.evaluationOptions}
                        noMargin
                        chosenOptions={searchedEvaluations}
                        onChange={setSearchedEvaluations}
                        label={translateString("evaluation")}
                        noOptionsLabel={translateString("noOptions")}
                    ></DotLegalMultiSelect>
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <AuditGroupHeader
                breadCrumbs={[
                    { name: translateString("externalAudits"), link: getAuditGroupsUrl() },
                    { name: data?.name, tooltip: data?.description },
                ]}
                customerName={customerName}
                onAuditGroupUpdated={onAuditGroupUpdated}
                auditGroupModel={
                    data
                        ? {
                              id: data.id,
                              auditTemplateId: data!.auditTemplateId,
                              auditTemplateName: data.auditTemplate,
                              auditTemplateVersion: data.auditTemplateVersion,
                              deadline: data.deadline,
                              name: data.name,
                              responsibleId: data.responsibleId,
                              description: data.description,
                              senderId: data.senderId,
                              sent: data.sent,
                          }
                        : undefined
                }
                showAddLegalEntityBtn
                showDeleteMenuItem
                canDeleteAuditgroup={data !== undefined && data.audits.filter((x) => x.status === LegalEntityAuditStatus.Submitted).length === 0}
            />

            <DotLegalPaper sx={{ marginBottom: 2, minHeight: 132 }} background="smallPurpleWave" backgroundCover>
                <Grid container>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("status")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="xs"
                                content={
                                    data
                                        ? data.progress === 100
                                            ? translateString("completed")
                                            : data?.sent
                                              ? translateLegalEntityAuditStatus(LegalEntityAuditStatus.InProgress.toString())
                                              : ""
                                        : ""
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("sent")}>
                            <DotLegalFixedHeightText isLoading={isLoading} fontSize="xs" content={data?.sent ? translateDate(data.sent) : ""} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("auditTemplate")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="xs"
                                content={
                                    data ? (
                                        <DotLegalFixedHeightText
                                            isLoading={isLoading}
                                            fontSize="xs"
                                            content={
                                                data ? <QuestionnaireTemplateLink id={data.auditTemplateId} name={data.auditTemplate} type={"audit"} /> : ""
                                            }
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("deadline")}>
                            <DotLegalFixedHeightText isLoading={isLoading} fontSize="xs" content={data ? translateDate(data.deadline) : ""} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("responsible")}>
                            <DotLegalFixedHeightText isLoading={isLoading} fontSize="xs" content={data ? data.responsible : ""} />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("sentFrom")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="xs"
                                content={
                                    data ? (
                                        data.sender ? (
                                            data.senderId ? (
                                                <DotLegalLink bold linkColor={"primary"} to={getGroupEntityUrl(data.senderId)}>
                                                    {data.sender}
                                                </DotLegalLink>
                                            ) : (
                                                data.sender
                                            )
                                        ) : (
                                            customerName
                                        )
                                    ) : (
                                        ""
                                    )
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                </Grid>
                <Divider
                    sx={{
                        backgroundColor: "#F0F7FC",
                        marginBottom: 2,
                        marginTop: 2,
                        marginLeft: 6,
                        marginRight: 6,
                    }}
                />
                <Grid container>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("legalEntities")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={data?.numberOfLegalEntities ?? ""} fontSize="xl" />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("opened")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={data?.openedAuditsCount ?? ""} fontSize="xl" />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("submitted")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={data?.submittedAuditsCount ?? ""} fontSize="xl" />
                        </DotLegalHeading>
                    </Grid>
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("excluded")}>
                            <DotLegalFixedHeightText isLoading={isLoading} content={data?.excludedAuditsCount ?? ""} fontSize="xl" />
                        </DotLegalHeading>
                    </Grid>
                    {data && data.scoreEnabled && (
                        <Grid {...gridProps}>
                            <DotLegalHeading name={translateString("overallRiskLevelAndScore")}>
                                <DotLegalFixedHeightText
                                    isLoading={isLoading}
                                    fontSize="xs"
                                    content={
                                        data && data.overallRiskLevelName && data.overallRiskLevelName && data.overallRiskScore ? (
                                            <Box sx={{ display: "flex", justifyContent: "center", gap: 1, fontWeight: "bold", marginTop: "6px" }}>
                                                <QuestionnaireRisk
                                                    riskLevel={data?.overallRiskLevel}
                                                    riskName={data?.overallRiskLevelName}
                                                    scoreEnabled={data.scoreEnabled}
                                                    status={LegalEntityAuditStatus.Submitted}
                                                />
                                                (
                                                {getQuestionnaireResponseScore(
                                                    true,
                                                    data.scoreEnabled,
                                                    data.overallRiskScore,
                                                    translateString("scoreNotEnabled")
                                                )}
                                                )
                                            </Box>
                                        ) : undefined
                                    }
                                />
                            </DotLegalHeading>
                        </Grid>
                    )}
                    <Grid {...gridProps}>
                        <DotLegalHeading name={translateString("progress")}>
                            <DotLegalFixedHeightText
                                isLoading={isLoading}
                                fontSize="sm"
                                content={
                                    data ? (
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                            <Box width={238}>
                                                <DotLegalCompletedPercentage completed={data.progress} />
                                            </Box>
                                        </Box>
                                    ) : undefined
                                }
                            />
                        </DotLegalHeading>
                    </Grid>
                    {data && !data.scoreEnabled && <Grid {...gridProps}></Grid>}
                </Grid>
            </DotLegalPaper>

            <DotLegalPaper>
                {isLoading || (data && data.audits.length > 0) ? (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <DotLegalTableWithControls
                            headers={getHeaders()}
                            getUserSpecificPageLength={() => 30}
                            extraControls={getSearchFields()}
                            hideRowsPerPage
                            defaultOrderBy={"legalEntityName"}
                            defaultOrder={"asc"}
                            isLoading={isLoading}
                            labelRowsPerPage={translateString("showEntites")}
                            labelSearch={translateString("search")}
                            noOptionsLabel={translateString("noOptions")}
                            paginationLabelOf={translateString("labelOf")}
                            data={mapToTableData(rows)}
                            clickableRows={false}
                            emptyText={translateString("noData")}
                            renderRow={(row, i) => {
                                return (
                                    <TableRow key={row.id + i}>
                                        <TableCell>
                                            <Box key={row.id} component={"span"} sx={{ fontWeight: "bold" }}>
                                                <DotLegalLink linkColor={"primary"} to={`${getLegalEntityUrl(row.legalEntityId, LegalEntityAuditTabs.Audits)}`}>
                                                    {row.legalEntityName}
                                                </DotLegalLink>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{row.uniqueIdentifier}</TableCell>
                                        <TableCell>{row.legalEntityTypeString}</TableCell>
                                        <TableCell>
                                            <DotLegalTooltip text={row.contactEmail}>
                                                <Box>
                                                    {row.contact} {row.contactTypes.length > 0 && <>({row.contactTypesString})</>}
                                                </Box>
                                            </DotLegalTooltip>
                                        </TableCell>
                                        <TableCell>
                                            <SystemLinks systems={row.assets} />
                                        </TableCell>
                                        <TableCell sx={{ minWidth: 350 }}>
                                            {auditIsLoading ? (
                                                <Skeleton width={"100%"} height={18} />
                                            ) : (
                                                row.classifications &&
                                                row.classifications.length > 0 && (
                                                    <ClassificationRisk
                                                        legalEntityId={row.id}
                                                        legalEntityPage={LegalEntityPageEnum.Vendor}
                                                        classifications={row.classifications}
                                                    />
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <QuestionnaireStatus
                                                showQuestionsStatistics={row.showQuestionsStatistics}
                                                numberOfQuestionsAnswered={row.numberOfQuestionsAnswered}
                                                totalNumberOfQuestions={row.totalNumberOfQuestions}
                                                status={row.status}
                                                lastestRecipientUpdate={row.lastestResponseChange}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <QuestionnaireRisk
                                                riskLevel={row.riskLevel}
                                                riskName={row.riskName}
                                                scoreEnabled={row.scoreEnabled}
                                                status={row.status}
                                            />
                                        </TableCell>
                                        <TableCell>{row.scoreString}</TableCell>
                                        <TableCell>
                                            <QuestionnaireEvaluation evaluation={row.evaluation} evaluationStatus={row.evaluationStatus} />
                                        </TableCell>
                                        <TableCell>{getReportButtons(row)}</TableCell>
                                        <TableCell>
                                            {row.remindersPaused ? translateString("remindersPaused") : row.reminderSent ? translateDate(row.reminderSent) : ""}
                                        </TableCell>
                                        <TableCell>
                                            {row.note && (
                                                <IconButton
                                                    onClick={() => {
                                                        setShowNote(row.id);
                                                    }}
                                                >
                                                    {NoteSVG}
                                                </IconButton>
                                            )}
                                        </TableCell>

                                        <TableCell align={"right"}>
                                            {!isLoading && data && (
                                                <LegalEntityAuditTableOverflowMenu
                                                    key={row.id}
                                                    auditId={row.id}
                                                    isClosed={row.closed}
                                                    status={row.status}
                                                    canBeDeleted={row.closed || row.status === LegalEntityAuditStatus.Submitted}
                                                    questionnaireName={row.questionnaireName}
                                                    deadline={row.deadline}
                                                    auditNote={row.note}
                                                    responsibleId={data.responsibleId}
                                                    contactId={row.contactId}
                                                    contactName={row.contact}
                                                    legalEntityId={row.legalEntityId}
                                                    responseExternalId={row.responseExternalId}
                                                    evaluation={row.evaluation}
                                                    evaluationStatus={row.evaluationStatus}
                                                    onAuditClosed={refetch}
                                                    onAuditReopened={refetch}
                                                    onAuditDeleted={refetch}
                                                    onResponsibleChanged={refetch}
                                                    onContactChanged={refetch}
                                                    onReminderSent={refetch}
                                                    onAuditEvaluated={refetch}
                                                    onCloseNoteDialog={() => setShowNote(undefined)}
                                                    saveNote={(note) => saveNote(note, row.id)}
                                                    showNote={showNote === row.id}
                                                    disableChangeResponsible
                                                    onRemindersToggled={refetch}
                                                    remindersPaused={row.remindersPaused}
                                                    questionnaireResponseId={row.questionnaireResponseId}
                                                    questionnaireId={row.questionnaireId}
                                                    legalEntityName={row.legalEntityName}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            }}
                        />
                        <Box sx={{ alignSelf: "flex-end" }}>
                            {data && data.sent && (
                                <Box sx={{ display: "flex", gap: 1 }}>
                                    <DotLegalButton
                                        ariaHaspopup
                                        onClick={() => {
                                            window.open(getAuditGroupManagementReportUrl(data!.id), "_blank");
                                        }}
                                        buttonType={"primary"}
                                    >
                                        {translateString("viewManagementReportInBrowser")}
                                    </DotLegalButton>
                                    <DotLegalButton
                                        ariaHaspopup
                                        onClick={() => onDownloadManagementReport()}
                                        disabled={isDownloadingManagementReport}
                                        isLoading={isDownloadingManagementReport}
                                        buttonType={"primary"}
                                    >
                                        {translateString("downloadManagementReport")}
                                    </DotLegalButton>
                                </Box>
                            )}
                        </Box>
                    </Box>
                ) : (
                    <DotLegalEmptyState icon={<ExternalAuditEmptyStateIcon />} text={"Du har ingen udsendte audits på denne auditgruppe"} />
                )}
            </DotLegalPaper>
        </React.Fragment>
    );

    function mapToTableData(audits: Array<LegalEntityAuditGroupAuditModel> | undefined): Array<LegalEntityAuditGroupAuditTableModel> {
        if (audits) {
            return audits.map((x) => {
                return {
                    ...x,
                    assetsString: x.assets.flatMap((x) => x.name).join(", "),
                    contactTypesString: x.contactTypes.map((x) => translateString(setFirstLetterToLowerCase(LegalEntityContactType[x].toString()))).join(", "),
                    auditStatusString: translateLegalEntityAuditStatus(x.status.toString()),
                    scoreString: getQuestionnaireResponseScore(
                        x.status === LegalEntityAuditStatus.Submitted,
                        x.scoreEnabled,
                        x.score,
                        translateString("scoreNotEnabled")
                    ),
                    legalEntityTypeString: x.legalEntityTypes
                        ?.map((type) => translateString(setFirstLetterToLowerCase(LegalEntityMasterType[type].toString())))
                        .join(", "),
                    reminderSentString: x.reminderSent ? translateDate(x.reminderSent) : "",
                    evaluationName: x.evaluationStatus ? translateString(setFirstLetterToLowerCase(EvaluationStatus[x.evaluationStatus].toString())) : "",
                };
            });
        }

        return [];
    }
}

export default LegalEntityAuditGroupOverview;
