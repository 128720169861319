import { useTranslation } from "../../localization/useTranslation.ts";
import { useUrlProvider } from "../../useUrlProvider.ts";
import Widget from "../Widget.tsx";
import DashboardCountCard from "../DashboardCountCard.tsx";
import { Box } from "@mui/material";
import { LargeCountText, SmallCountText } from "../processingActivityCountWidget/ProcessingActivityCountWiget.tsx";
import { useTasksCountWidget } from "./TasksCountWidget.hooks.tsx";
import { taskManagementAllMyStorageKey } from "../../taskManagement/TaskManagement.hooks.ts";
import CountUp, { useCountUp } from "react-countup";
import React, { useState } from "react";

export interface ITasksCountWidgetProps {
    onlyUserSpecific: boolean;
    selectedGroupCompany: string | undefined;
}

function TasksCountWidget(props: ITasksCountWidgetProps) {
    const { translateString } = useTranslation();
    const { getTaskManagementUrl } = useUrlProvider();
    const { isLoading, data, isEnabled, counterValue, setCounterValue } = useTasksCountWidget(props);

    return (
        <Widget noPadding disabled={!isEnabled}>
            <DashboardCountCard
                isLoading={isLoading}
                header={translateString("tasks")}
                background="violet"
                icon="calender"
                url={{
                    url: getTaskManagementUrl("?months="),
                    text: translateString("gotoTasks"),
                    allMyPageKey: taskManagementAllMyStorageKey,
                    onlyUserSpecific: props.onlyUserSpecific,
                }}
            >
                {data !== undefined && (
                    <Box sx={{ display: "flex", gap: 1, alignItems: "flex-end" }}>
                        <LargeCountText>
                            <Box>
                                {counterValue != data.numberOfTasksInYear ? (
                                    <CountUp end={data.numberOfTasksInYear} duration={5} onEnd={() => setCounterValue(data.numberOfTasksInYear)} />
                                ) : (
                                    counterValue
                                )}
                            </Box>
                        </LargeCountText>
                        <SmallCountText>{`${translateString("in")} ${new Date().getFullYear()}`}</SmallCountText>
                    </Box>
                )}
            </DashboardCountCard>
        </Widget>
    );
}

export default TasksCountWidget;
