import { DotLegalCheckbox, DotLegalFullScreenSpinner, DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { Box, Divider, TableCell, TableRow } from "@mui/material";
import React, { Fragment } from "react";
import ProcessingActivityReportDialogHeader from "../processingActivityReportDialogHeader/ProcessingActivityReportDialogHeader";
import { useTranslation } from "../../localization/useTranslation";
import { BodyTypography, getDataBasis, HeaderBodyTypography, HeaderBox, SpanBox } from "../ProcessingActivityReport";
import { ProcessingActivityReportAgreement } from "../ProcessingActivityReport.types";
import { useProcessingActivityReportHooks } from "../ProcessingActivityReport.hooks";
import { ProcessingActivityStatusManagement } from "../../auth/userContextProvider/UserContextProvider.types";
import { CustomerReportType } from "../../customer/addEditCustomerDialog/AddEditCustomer.types";
import { getNumberOfDataSubjects } from "../../processingActivity/processingActivityOverview/ProcessingActivityOverview.hooks";

export interface IInternalAcquisitionOfSignatureReport {
    processingActivityId?: string;
    isPDF: boolean;
}

export default function InternalAcquisitionOfSignatureReport(props: IInternalAcquisitionOfSignatureReport) {
    const { translateString, translateDate, translateDocumentType, translateDataSubjectsEnum } = useTranslation();
    const { isLoading, data, onReportChange, permissions, customerName } = useProcessingActivityReportHooks(
        CustomerReportType.InternalAcquisitionOfSignature,
        props.processingActivityId
    );

    const getHeaders = () => {
        let headers = Array<ITableHeader<ProcessingActivityReportAgreement>>();

        headers.push({
            property: "partner",
            text: translateString("partner"),
            align: "left",
            showOnMobile: true,
        });

        headers.push({
            property: "documentTypes",
            text: translateString("documentType"),
            align: "left",
            showOnMobile: true,
        });

        headers.push({
            property: "documentNames",
            text: translateString("documentname"),
            align: "left",
            showOnMobile: true,
        });

        headers.push({
            property: "overheadAssessment",
            text: translateString("internalAcquisitionOfSignatureReportOverhead"),
            align: "left",
            showOnMobile: true,
        });

        /* For now we don't show comments */
        /* headers.push({
            property: "comment",
            text: translateString("comment"),
            align: "left",
            showOnMobile: true,
        }); */

        return headers;
    };

    return (
        <div>
            {isLoading ? (
                <Box height={"70vh"}>
                    <DotLegalFullScreenSpinner />
                </Box>
            ) : (
                <Box sx={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    {props.isPDF && <ProcessingActivityReportDialogHeader reportType={CustomerReportType.InternalAcquisitionOfSignature} isPdf />}
                    <Box sx={{ marginTop: props.isPDF ? 2 : 0 }}>
                        <HeaderBox>
                            <BodyTypography>
                                <HeaderBodyTypography fontSize={16}>{translateString("projectTitel") + ": "}</HeaderBodyTypography>
                                <SpanBox fontSize={16} component="span">
                                    {data?.processingActivityName}
                                </SpanBox>
                            </BodyTypography>
                        </HeaderBox>
                    </Box>
                    <HeaderBox>
                        <HeaderBodyTypography>{translateString("approvelNumber") + ": "}</HeaderBodyTypography>
                        <BodyTypography>{data?.approvelNumber}</BodyTypography>
                    </HeaderBox>
                    <HeaderBox>
                        <BodyTypography>
                            <HeaderBodyTypography>{translateString("purposes") + ": "}</HeaderBodyTypography>
                            <SpanBox component="span">{data?.purposes.join(", ")}</SpanBox>
                        </BodyTypography>
                    </HeaderBox>
                    <div className="page-break-inside">
                        <HeaderBox>
                            <BodyTypography>
                                <HeaderBodyTypography>{translateString("description") + ": "}</HeaderBodyTypography>
                                <SpanBox component="span">{data?.description}</SpanBox>
                            </BodyTypography>
                        </HeaderBox>
                    </div>
                    <HeaderBox>
                        <HeaderBodyTypography>{translateString("NumberOfSubjects") + ": "}</HeaderBodyTypography>
                        <BodyTypography>
                            {data && data?.numberOfSubjects
                                ? getNumberOfDataSubjects(translateDataSubjectsEnum, data?.numberOfSubjects, data?.noOfSubjects)
                                : undefined}
                        </BodyTypography>
                    </HeaderBox>
                    <HeaderBox>
                        <HeaderBodyTypography>{translateString("approvelDate") + ": "}</HeaderBodyTypography>
                        <BodyTypography>{data?.approvelDate ? translateDate(data?.approvelDate!) : ""}</BodyTypography>
                    </HeaderBox>
                    <HeaderBox>
                        <HeaderBodyTypography>{translateString("dataprocessorEndDate") + ": "}</HeaderBodyTypography>
                        <BodyTypography>{data?.endDate ? translateDate(data?.endDate!) : ""}</BodyTypography>
                    </HeaderBox>
                    <Box className="avoid-page-break" sx={{ marginBottom: props.isPDF ? 1 : 2 }}>
                        {props.isPDF ? (
                            <BodyTypography>
                                <HeaderBodyTypography>{`${translateString("dataBaseAndSources")} (${translateString(
                                    "businessPartners"
                                )}):`}</HeaderBodyTypography>

                                {getDataBasis(translateString, data)}
                            </BodyTypography>
                        ) : (
                            <Fragment>
                                <Box>
                                    <HeaderBodyTypography>{`${translateString("dataBaseAndSources")} (${translateString(
                                        "businessPartners"
                                    )}):`}</HeaderBodyTypography>
                                </Box>

                                {getDataBasis(translateString, data)}
                            </Fragment>
                        )}
                    </Box>
                    <div className="avoid-page-break">
                        <HeaderBodyTypography>{translateString("primaryResponsible") + ":"}</HeaderBodyTypography>
                        <BodyTypography sx={{ whiteSpace: "break-spaces", marginBottom: 2 }}>{data?.primaryResponsible}</BodyTypography>

                        <HeaderBodyTypography>{translateString("contact") + ":"}</HeaderBodyTypography>
                        <BodyTypography sx={{ whiteSpace: "break-spaces" }}>{data?.contact}</BodyTypography>
                    </div>

                    {data && data.agreements.length > 0 && (
                        <div className="avoid-page-break">
                            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

                            <BodyTypography>{translateString("agreementsOnReview", { environment: customerName })}</BodyTypography>

                            <Box marginTop={3}>
                                <DotLegalTable
                                    headers={getHeaders()}
                                    defaultOrderBy="partner"
                                    defaultOrder={"asc"}
                                    isLoading={isLoading}
                                    data={data?.agreements}
                                    hidePagination
                                    isPdf={props.isPDF}
                                    disableFilterAndSortAndSlicing
                                    hideRowsPerPage
                                    clickableRows={false}
                                    renderRow={(row, i) => (
                                        <TableRow key={row.id}>
                                            <TableCell>{row.partner}</TableCell>
                                            <TableCell>
                                                {row.documentTypes
                                                    .map((x) => {
                                                        return translateDocumentType(x.toString());
                                                    })
                                                    .join(", ")}
                                            </TableCell>
                                            <TableCell sx={{ maxWidth: 500, wordWrap: "break-word" }}>{row.documentNames.join(", ")}</TableCell>
                                            <TableCell>
                                                <BodyTypography>
                                                    <DotLegalCheckbox
                                                        disabled={permissions.processingActivityStatusManagement !== ProcessingActivityStatusManagement.Full}
                                                        margin={{ marginLeft: -1 }}
                                                        checked={row.overheadAssessment}
                                                        label={translateString("overheadAssessment")}
                                                        fontSize="s"
                                                        onChange={(value) => {
                                                            let tempModel = { ...data! };
                                                            var agreement = tempModel.agreements.find((x) => x.id === row.id);

                                                            if (agreement) {
                                                                agreement.overheadAssessment = value;
                                                                onReportChange(tempModel);
                                                            }
                                                        }}
                                                    />
                                                </BodyTypography>
                                            </TableCell>
                                            {/* For now we don't show comments */}
                                            {/*                                             <TableCell>
                                                {props.isPDF ? (
                                                    <BodyTypography>
                                                        <SpanBox component="span">{row.comment}</SpanBox>
                                                    </BodyTypography>
                                                ) : (
                                                    <Box sx={{ marginTop: -1 }}>
                                                        <DotLegalTextField
                                                            label={translateString("comment")}
                                                            disabled={
                                                                permissions.processingActivityStatusManagement !== ProcessingActivityStatusManagement.Full
                                                            }
                                                            onChange={(comment) => {
                                                                let tempModel = { ...data! };
                                                                var agreement = tempModel.agreements.find((x) => x.id === row.id);

                                                                if (agreement) {
                                                                    agreement.comment = comment;
                                                                    onReportChange(tempModel);
                                                                }
                                                            }}
                                                            value={row.comment}
                                                            debounce
                                                        />
                                                    </Box>
                                                )}
                                            </TableCell> */}
                                        </TableRow>
                                    )}
                                    paginationLabelOf=""
                                />
                            </Box>
                        </div>
                    )}
                </Box>
            )}
        </div>
    );
}
