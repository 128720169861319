import { useQuery } from "react-query";
import { get } from "../../common/api/apiShared";
import { VendorsCountWidgetProps } from "./VendorsCountWidget";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider.tsx";
import { useState } from "react";

export function useVendorsCountWidget(props: VendorsCountWidgetProps) {
    let userContext = useUserContext();
    let url = `/LegalEntities/dashboard/vendorscount?onlyUserSpecific=${props.onlyUserSpecific}`;
    const isEnabled = userContext.permissions.companyPermissions.read;
    const [counterValue, setCounterValue] = useState(0);
    const { isLoading, data } = useQuery(url, () => get<number>(url), { enabled: isEnabled });

    return {
        isLoading,
        data,
        isEnabled,
        counterValue,
        setCounterValue,
    };
}
