import React from "react";
import Widget from "../Widget";
import { useTasksWidget } from "./TaskWidget.hooks";
import { Box, styled } from "@mui/material";
import { DotLegalCompletedPercentage, DotLegalEmptyState, DotLegalHeader, DotLegalSwitch, useIsOnExtraSmallScreen } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation";
import ThisMonthIcon from "../svgs/this-month.svg?react";
import OverdueIcon from "../svgs/overdue.svg?react";
import EmptyStateIcon from "../svgs/tasks-widget-empty-state.svg?react";
import Task from "../../taskManagement/task/Task";
import NavigateTo from "../navigateTo/NavigateTo";
import WhiteDashboardBox from "../WhiteDashboardBox.tsx";
import DashboardPrettyScrollbar from "../DashboardPrettyScrollbar.tsx";
import { Trans } from "react-i18next";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink.tsx";
import { useUrlProvider } from "../../useUrlProvider.ts";
import { usePermissionsProvider } from "../../auth/usePermissionsProvider.ts";

export interface TasksWidgetProps {
    onlyUserSpecific: boolean;
    selectedGroupCompany: string | undefined;
}

const SwitchNumbersCount = styled("span")({
    marginLeft: 8,
    borderRadius: "50%",
    padding: "2px 6px",
    backgroundColor: "white",
    fontWeight: "bold",
});

function TasksWidget(props: TasksWidgetProps) {
    const { getAnnualWheelUrl } = useUrlProvider();
    const permissionsProvider = usePermissionsProvider();
    const { translateString } = useTranslation();
    const {
        isLoading,
        tasks,
        progress,
        isEnabled,
        showCurrentMonth,
        numberOfOverdueTasks,
        numberOfTasksThisMonth,
        setShowCurrentMonth,
        navigateToTaskManagement,
        getTaskManagementUrl,
    } = useTasksWidget(props);

    return (
        <Widget isLoading={isLoading} disabled={!isEnabled}>
            <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <DotLegalHeader headerStyle={"medium"} marginBottom={0}>
                    {translateString("tasks").toUpperCase()}
                </DotLegalHeader>

                <DotLegalSwitch
                    onChange={() => setShowCurrentMonth(!showCurrentMonth)}
                    checked={showCurrentMonth}
                    leftLabel={
                        <Box component={"span"}>
                            {translateString("overdue")}
                            <SwitchNumbersCount>{numberOfOverdueTasks}</SwitchNumbersCount>
                        </Box>
                    }
                    rightLabel={
                        <Box component={"span"}>
                            {translateString("thisMonth")}
                            <SwitchNumbersCount>{numberOfTasksThisMonth}</SwitchNumbersCount>
                        </Box>
                    }
                />

                <WhiteDashboardBox marginTop={2}>
                    <Box
                        sx={{
                            display: "flex",

                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                        }}
                    >
                        <Box sx={{ display: "flex" }}>{showCurrentMonth ? <ThisMonthIcon /> : <OverdueIcon />}</Box>

                        <DotLegalHeader headerStyle={"xxxl"} marginBottom={0} fontWeight={400}>
                            {tasks.length}
                        </DotLegalHeader>

                        <DotLegalCompletedPercentage completed={progress} fixedColor={!showCurrentMonth && progress < 100 ? "red" : undefined} />
                    </Box>
                </WhiteDashboardBox>

                {tasks.length > 0 ? (
                    <Box
                        mt={2}
                        sx={{
                            marginRight: `-15px`,
                            height: "100%",
                            "& .MuiPaper-root": {
                                boxShadow: "none",
                            },
                        }}
                    >
                        <DashboardPrettyScrollbar showGutter>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pr: 0.5 }}>
                                {tasks.map((task) => {
                                    return (
                                        <Task
                                            activityName={task.name}
                                            taskName={task.processingActivityName ?? ""}
                                            description={task.description}
                                            businessAreas={task.businessAreas}
                                            responsibles={task.responsibles}
                                            month={new Date(task.deadline).getMonth() + 1}
                                            year={new Date(task.deadline).getFullYear()}
                                            subTaskCompleted={task.completedSubTasks}
                                            totalSubTasks={task.totalSubTasks}
                                            taskStatus={task.laneState}
                                            isOverdue={task.isOverdue}
                                            onClick={() => navigateToTaskManagement(task.id, task.deadline)}
                                            priority={task.priority}
                                            processingActivtiyId={task.processingActivityId}
                                            isSeparateTask={task.isSeparateTask}
                                            areas={task.areas}
                                            customId={task.customId}
                                            isReadyForApproval={task.isReadyForApproval}
                                            key={task.id}
                                        />
                                    );
                                })}
                            </Box>
                        </DashboardPrettyScrollbar>
                    </Box>
                ) : (
                    <WhiteDashboardBox fullHeight marginTop={2}>
                        <DotLegalEmptyState
                            textColor={"darkBlue"}
                            icon={<EmptyStateIcon />}
                            text={
                                showCurrentMonth ? (
                                    permissionsProvider.hasAnnualWheelActivityAccess() ? (
                                        <Trans
                                            i18nKey={"dashboardCurrentTasksEmtpyState"}
                                            components={{
                                                box: (
                                                    <DotLegalLink to={`${getAnnualWheelUrl()}?months=${new Date().getMonth() + 1}`} linkColor="primary">
                                                        {undefined}
                                                    </DotLegalLink>
                                                ),
                                            }}
                                        />
                                    ) : (
                                        translateString("dashboardNoCurrentTasksNoAnnualWheelAccess")
                                    )
                                ) : (
                                    translateString("noOverdueTasksDashboard")
                                )
                            }
                        />
                    </WhiteDashboardBox>
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <NavigateTo
                        color="purple"
                        text={showCurrentMonth ? translateString("goToCurrentTasks") : translateString("goToOverdueTasks")}
                        to={getTaskManagementUrl(showCurrentMonth ? `?months=${new Date().getMonth() + 1}` : "?months=&overdueTasks=true")}
                    />
                </Box>
            </Box>
        </Widget>
    );
}

export default TasksWidget;
