import { Box, Theme } from "@mui/material";
import React from "react";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export interface NavigateToProps {
    color: "white" | "darkblue" | "purple";
    to: string;
    text: string;
    onlyText?: boolean;
}

function NavigateTo(props: NavigateToProps) {
    return (
        <Box
            sx={(theme) => ({
                mt: 1,
                display: "flex",
                alignSelf: "flex-end",
                fontSize: theme.typography.pxToRem(16),
                fontWeight: "600",
                color: getColor(theme),
                alignItems: "center",
                "& svg": {
                    fill: getColor(theme),
                },
            })}
        >
            {props.onlyText ? (
                <Box
                    sx={{
                        "&:hover": {
                            textDecoration: "underline",
                        },
                    }}
                >
                    {props.text}
                </Box>
            ) : (
                <DotLegalLink bold to={props.to}>
                    {props.text}
                </DotLegalLink>
            )}

            <KeyboardArrowRightIcon />
        </Box>
    );

    function getColor(theme: Theme) {
        return props.color === "white" ? theme.customPalette.white : props.color === "darkblue" ? theme.palette.primary.dark : theme.palette.primary.main;
    }
}

export default NavigateTo;
