import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../auth/userContextProvider/UserContextProvider";
import { deleteHttp, get } from "../../common/api/apiShared";
import { useOptimisticUpdate } from "../../common/hooks/useOptimisticUpdate";
import { useDataSubjectsQuery } from "../../common/hooks/useSelectableItemQueries";
import { useUpdateProcessingActivityModel } from "../hooks/useUpdateProcessingActivityModel";
import { NumberOfProcessorsEnum, NumberOfDataSubjectsEnum, ProcessingActivityModel, SelectableSecurityMeasure } from "../ProcessingActivity.types";
import { IDataSubjectStep } from "./DataSubjectStep";

export function useDataSubjectStepHook(props: IDataSubjectStep) {
    const { id } = useParams<{ id: string }>();
    const [showAddNewSecurityMeasure, setShowAddNewSecurityMeasure] = useState(false);
    const [showAddNewDataSubject, setShowAddNewDataSubject] = useState(false);
    const [typedSecurityMeasure, setTypedSecurityMeasure] = useState("");
    const [typedDataSubject, setTypedDataSubject] = useState("");
    const { permissions } = useUserContext();
    const updateProcessingActivityModel = useUpdateProcessingActivityModel(props.processingActivityId);
    const removeStandardDataCategoryMutation = useMutation(removeStandardDataCategory);
    const optimisticUpdate = useOptimisticUpdate();

    const dataSubjectsRef = useRef<HTMLTextAreaElement>();
    const dataProcessorsRef = useRef<HTMLTextAreaElement>();

    let dataSubjectsQuery = useDataSubjectsQuery();
    let securityMeasuresQuery = useQuery(
        "processingActivitySecurityMeasures" + id,
        () => get<Array<SelectableSecurityMeasure>>(`/securitymeasures/${id}/processingactivity`),
        { enabled: permissions.processingActivityDataSubjectPermissions.hasSecurityMeasurePermission }
    );

    useEffect(() => {
        if (props.subjects == NumberOfDataSubjectsEnum.UserEnteredNumberOfSubjects) {
            dataSubjectsRef.current?.focus();
        }
    }, [props.subjects]);

    useEffect(() => {
        if (props.processors == NumberOfProcessorsEnum.UserEnteredNumberOfProcessors) {
            dataProcessorsRef.current?.focus();
        }
    }, [props.processors]);

    let dataSubjectsLoading = dataSubjectsQuery.isLoading;
    let securityMeasuresLoading = securityMeasuresQuery.isLoading;

    let dataSubjecstData;
    if (dataSubjectsQuery.data) {
        dataSubjecstData = dataSubjectsQuery.data.map((d, index) => {
            return {
                name: d.name,
                id: d.id,
            };
        });
    }

    let securityMeasuresData;
    if (securityMeasuresQuery.data) {
        securityMeasuresData = securityMeasuresQuery.data.map((d, index) => {
            return {
                name: d.name,
                id: d.id,
                isGlobal: d.isGlobal,
                description: d.description,
            };
        });
    }

    async function onRemoveStandardDataCategory(id: string, dataSubjectId: string | null) {
        const newProcessingActivityModel = updateProcessingActivityModel.update((model) => {
            if (dataSubjectId !== null && dataSubjectId !== "all")
                model.dataSubjectDataCategories = model.dataSubjectDataCategories.filter((d) => d.dataCategoryId !== id);

            model.standardDataCategories = model.standardDataCategories.filter((d) => d !== id);
        });
        await optimisticUpdate.updateWithProcessingActivityResponse(newProcessingActivityModel, removeStandardDataCategoryMutation.mutateAsync, {
            processingActivityId: newProcessingActivityModel.id,
            dataCategoryId: id,
            forDataSubjectId: dataSubjectId,
        });
    }

    const handleOnChangeDataSubject = async (dataSubjects: Array<string>) => {
        if (dataSubjects.length < props.dataSubjects.length) {
            let removedDataSubject = props.dataSubjects.filter((x) => !dataSubjects.includes(x))[0];
            let dataSubjectDataCategories = props.dataSubjectDataCategories.filter((x) => x.dataSubjectId === removedDataSubject);

            dataSubjectDataCategories.map(async (d) => {
                await onRemoveStandardDataCategory(d.dataCategoryId, d.dataSubjectId);
            });
        }

        props.onDataSubjectsChange(dataSubjects);
    };

    async function removeStandardDataCategory(data: { processingActivityId: string; dataCategoryId: string; forDataSubjectId: string | null }) {
        return await deleteHttp<ProcessingActivityModel>(
            "/processingactivity/" + data.processingActivityId + "/standardDatacategory/" + data.dataCategoryId + "?forDataSubjectId=" + data.forDataSubjectId
        );
    }

    const onSaveSecurityMeasure = (id: any, name: string) => {
        var temp = [...props.securityMeasures!];
        temp.push({ securityMeasureId: id.id, isMet: true });
        props.onSecurityMeasureChange(temp);

        var tempData = [...securityMeasuresQuery.data!];
        tempData.push({ id: id.id, isGlobal: false, name: name, description: "" });
        securityMeasuresQuery.data = tempData;

        securityMeasuresQuery.refetch();
        setTypedSecurityMeasure("");
    };

    const onSaveDataSubject = (reponse: any, name: string) => {
        var temp = [...props.dataSubjects];
        temp.push(reponse.id);
        props.onDataSubjectsChange(temp);

        var tempData = [...dataSubjectsQuery.data!];
        tempData.push({ id: reponse.id, name: name });
        dataSubjectsQuery.data = tempData;

        dataSubjectsQuery.refetch();
        setTypedDataSubject("");
    };

    return {
        dataSubjecstData,
        dataSubjectsLoading,
        securityMeasuresData,
        securityMeasuresLoading,
        showAddNewSecurityMeasure,
        setShowAddNewSecurityMeasure,
        typedSecurityMeasure,
        setTypedSecurityMeasure,
        onSaveSecurityMeasure,
        showAddNewDataSubject,
        setShowAddNewDataSubject,
        typedDataSubject,
        setTypedDataSubject,
        onSaveDataSubject,
        handleOnChangeDataSubject,
        getNoOfDataSubjectSelectOptions,
        getNoOfProcessorsSelectOptions,
        dataSubjectsRef,
        dataProcessorsRef,
    };
}

export function getNoOfDataSubjectSelectOptions(translateDataSubjectsEnum: (key: string, interpolation?: any) => string) {
    let options = [
        {
            id: NumberOfDataSubjectsEnum.OneToOneHundred.toString(),
            name: translateDataSubjectsEnum(NumberOfDataSubjectsEnum.OneToOneHundred.toString()),
        },
        {
            id: NumberOfDataSubjectsEnum.OneHundredAndOneToOneThousand.toString(),
            name: translateDataSubjectsEnum(NumberOfDataSubjectsEnum.OneHundredAndOneToOneThousand.toString()),
        },
        {
            id: NumberOfDataSubjectsEnum.OneThousandAndOneToTenThousand.toString(),
            name: translateDataSubjectsEnum(NumberOfDataSubjectsEnum.OneThousandAndOneToTenThousand.toString()),
        },
        {
            id: NumberOfDataSubjectsEnum.TenThousandAndOneToOneHundredThousand.toString(),
            name: translateDataSubjectsEnum(NumberOfDataSubjectsEnum.TenThousandAndOneToOneHundredThousand.toString()),
        },
        {
            id: NumberOfDataSubjectsEnum.OneHundredThousandAndOneToOneMillion.toString(),
            name: translateDataSubjectsEnum(NumberOfDataSubjectsEnum.OneHundredThousandAndOneToOneMillion.toString()),
        },
        {
            id: NumberOfDataSubjectsEnum.OneMillionAndOneToInfinite.toString(),
            name: translateDataSubjectsEnum(NumberOfDataSubjectsEnum.OneMillionAndOneToInfinite.toString()),
        },
        {
            id: NumberOfDataSubjectsEnum.UserEnteredNumberOfSubjects.toString(),
            name: translateDataSubjectsEnum(NumberOfDataSubjectsEnum.UserEnteredNumberOfSubjects.toString()),
        },
    ];

    return options;
}
export function getNoOfProcessorsSelectOptions(translateProcessorsEnum: (key: string, interpolation?: any) => string) {
    let options = [
        {
            id: NumberOfProcessorsEnum.OneToTen.toString(),
            name: translateProcessorsEnum(NumberOfProcessorsEnum.OneToTen.toString()),
        },
        {
            id: NumberOfProcessorsEnum.ElevenToFifty.toString(),
            name: translateProcessorsEnum(NumberOfProcessorsEnum.ElevenToFifty.toString()),
        },
        {
            id: NumberOfProcessorsEnum.FiftyOneToOneHundred.toString(),
            name: translateProcessorsEnum(NumberOfProcessorsEnum.FiftyOneToOneHundred.toString()),
        },
        {
            id: NumberOfProcessorsEnum.OneHundredAndOneToOneThousand.toString(),
            name: translateProcessorsEnum(NumberOfProcessorsEnum.OneHundredAndOneToOneThousand.toString()),
        },
        {
            id: NumberOfProcessorsEnum.OneThousandAndOneToInfinite.toString(),
            name: translateProcessorsEnum(NumberOfProcessorsEnum.OneThousandAndOneToInfinite.toString()),
        },
        {
            id: NumberOfProcessorsEnum.UserEnteredNumberOfProcessors.toString(),
            name: translateProcessorsEnum(NumberOfProcessorsEnum.UserEnteredNumberOfProcessors.toString()),
        },
    ];

    return options;
}
