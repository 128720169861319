import { createTheme } from "@mui/material";

const belowThisBreakpointIsMobile: number | "xs" | "sm" | "md" | "lg" | "xl" = "md";

export function getBreakpointForMobile() {
    return belowThisBreakpointIsMobile;
}

export const menuIconSize = 20;

const theme = createTheme();

export const toolTipTextSize = theme.typography.pxToRem(11);
export function mediaQueryNotOnMobile() {
    return theme.breakpoints.up("md");
}

const blue = "#7284FA";
const darkblue = "#002C75";
const lightBlue = "#DAEAF7";
const secondaryBlue = "#618CF5";
const white = "#FFFFFF";
const teal = "#2CD5C4";
const borderRadius = 10;
const errorRed = "#FF3F78";
const warningYellow = "#ff9800";
const disabledBackground = "#B7C0FB";
const collapseBackground = "#EFEFFA";

declare module "@mui/material/styles/createTheme" {
    interface Theme {
        customPalette: {
            collapseBackground: string;
            disabledBackground: string;
            white: string;
        };
        customSpacing: {
            menuIconSize: number;
        };
        spacingAsNumber(spacing: number): number;
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        customPalette?: {
            collapseBackground: string;
            disabledBackground: string;
            white: string;
        };
        customSpacing: {
            menuIconSize: number;
        };
        spacingAsNumber(spacing: number): number;
    }
}

const privacyTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1600,
        },
    },
    spacingAsNumber: (spacing: number) => Number(theme.spacing(spacing).slice(0, -2)),
    typography: {
        fontFamily: "Open Sans, sans-serif",
    },
    palette: {
        primary: {
            dark: darkblue,
            main: blue,
            light: lightBlue,
        },
        secondary: {
            main: teal,
            light: secondaryBlue,
        },
        action: {
            disabled: white,
            disabledBackground: disabledBackground,
        },
        error: {
            main: errorRed,
        },
        warning: {
            main: warningYellow,
        },
        grey: {
            A400: "#B0BCC6",
        },
    },
    shape: {
        borderRadius: borderRadius,
    },
    customPalette: {
        collapseBackground: collapseBackground,
        disabledBackground: disabledBackground,
        white: white,
    },
    customSpacing: {
        menuIconSize: menuIconSize,
    },
    spacing: getSpacing,
    components: {
        MuiListItem: {
            styleOverrides: {
                button: {
                    "&:hover": {
                        backgroundColor: lightBlue,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: borderRadius,
                    textTransform: "none",
                },
                containedSecondary: {
                    color: white,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: blue,
                },
                colorSecondary: {
                    color: blue,
                    "&$checked": {
                        color: blue,
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: toolTipTextSize,
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fill: blue,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: theme.typography.pxToRem(14),
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: theme.typography.pxToRem(14),
                    color: darkblue,
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                filledSuccess: {
                    background: `#20B9A9 !important`,
                },
                filledError: {
                    background: `#E3547B !important`,
                },
                filledInfo: {
                    background: `#7284FA !important`,
                },
                filledWarning: {
                    background: `#FEF4E7 !important`,
                    "& .MuiAlert-message": {
                        color: "#5B3B15 !important",
                    },
                    "& .MuiAlert-icon .MuiSvgIcon-root": {
                        fill: "#FF9729 !important",
                    },
                    "& .MuiAlert-action .MuiSvgIcon-root": {
                        fill: "#5B3B15 !important",
                    },
                },
            },
        },
    },
});

export default privacyTheme;

export function getSpacing(factor: number) {
    const width = window.innerWidth;
    if (width > 600) {
        return 8 * factor;
    } else {
        return 5 * factor;
    }
}
