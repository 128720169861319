import React from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import {
    DotLegalActionBar,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalSwitch,
    DotLegalTableWithControls,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { useTranslation } from "../../../localization/useTranslation";
import { useLegislationsStyle } from "./Legislations.styles";
import { LegislationViewModel } from "./Legislations.types";
import CheckIcon from "@mui/icons-material/Check";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider";
import { useLegislationsDataMapping } from "./Legislations.hooks";
import LegislationDialog from "../legislationDialog/LegislationDialog";
import { DeleteLegislationDialog } from "../deleteLegislationDialog/DeleteLegislationDialog";
import { ChangeLegislationStatusDialog } from "../changeLegislationStatusDialog/ChangeLegislationStatusDialog";
import { getMasterIcon } from "../../MasterData.hooks";

function Legislations() {
    const styles = useLegislationsStyle();
    const { translateString } = useTranslation();
    const { permissions, customerName } = useUserContext();
    const { getLegislationsBreadCrump } = useMasterDataBreadCrumbProvider();

    const {
        isLoading,
        data,
        showAll,
        setShowAll,
        OnSave,
        setShowLegislationDialog,
        showLegislationDialog,
        showDeleteLegislationDialog,
        setShowDeleteLegislationDialog,
        selectedLegislation,
        setSelectedLegislation,
        setShowChangeLegislationStatusDialog,
        showChangeLegislationStatusDialog,
    } = useLegislationsDataMapping();

    const getMenuItems = (legislation: LegislationViewModel) => {
        let menuItems = [];

        if (permissions.canManageMasterData) {
            if (legislation.isCustom) {
                menuItems.push({
                    menuItem: translateString("edit"),
                    onClick: () => {
                        setSelectedLegislation({ name: legislation.name, id: legislation.id, active: legislation.active });
                        setShowLegislationDialog(true);
                    },
                });
            }

            menuItems.push({
                menuItem: legislation.active ? translateString("deactivate") : translateString("activate"),
                onClick: () => {
                    setSelectedLegislation({ name: legislation.name, id: legislation.id, active: legislation.active });
                    setShowChangeLegislationStatusDialog(true);
                },
            });
        }

        if (permissions.canManageMasterData && legislation.isCustom) {
            menuItems.push({
                menuItem: translateString("deleteLegislation"),
                onClick: () => {
                    setSelectedLegislation({ name: legislation.name, id: legislation.id, active: legislation.active });
                    setShowDeleteLegislationDialog(true);
                },
            });
        }

        return menuItems;
    };

    const openCreateDialog = () => {
        setSelectedLegislation(undefined);
        setShowLegislationDialog(true);
    };

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={getLegislationsBreadCrump(translateString("legislations"))}
                leftComponent={
                    <DotLegalSwitch onChange={setShowAll} checked={showAll} leftLabel={translateString("active")} rightLabel={translateString("all")} />
                }
            />
            <div>
                <DotLegalActionBar
                    canCreate={permissions.canManageMasterData}
                    buttonText={translateString("createLegislation")}
                    onOpenDialog={openCreateDialog}
                    showDialog={showLegislationDialog}
                    icon={getMasterIcon()}
                >
                    <LegislationDialog
                        isAdd={selectedLegislation === undefined}
                        onDialogClose={() => setShowLegislationDialog(false)}
                        onSave={OnSave}
                        legislation={selectedLegislation}
                    />
                </DotLegalActionBar>
            </div>
            <Box sx={styles.paperContainer}>
                <DotLegalPaper>
                    <DotLegalTableWithControls
                        headers={getHeaders(translateString)}
                        getUserSpecificPageLength={() => 30}
                        hideRowsPerPage
                        defaultOrderBy={"name"}
                        defaultOrder={"asc"}
                        isLoading={isLoading}
                        clickableRows={false}
                        labelRowsPerPage={translateString("showEntites")}
                        labelSearch={translateString("search")}
                        noOptionsLabel={translateString("noOptions")}
                        paginationLabelOf={translateString("labelOf")}
                        emptyText={translateString("noData")}
                        data={data}
                        renderRow={(row, i) => (
                            <TableRow key={row.id}>
                                <TableCell sx={styles.bold}>{row.name}</TableCell>
                                <TableCell>
                                    <Box sx={styles.iconContainer}>{row.active ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={styles.iconContainer}>{!row.isCustom ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                                </TableCell>
                                <TableCell align={"right"}>
                                    <DotLegalOverflowMenu items={getMenuItems(row)} />
                                </TableCell>
                            </TableRow>
                        )}
                    />
                </DotLegalPaper>

                {showDeleteLegislationDialog && (
                    <DeleteLegislationDialog
                        selectedLegislation={selectedLegislation!}
                        onCloseDialog={() => setShowDeleteLegislationDialog(false)}
                        onSaveCorrect={OnSave}
                    />
                )}

                {showChangeLegislationStatusDialog && (
                    <ChangeLegislationStatusDialog
                        selectedLegislation={selectedLegislation!}
                        onStatusChange={OnSave}
                        onCloseDialog={() => setShowChangeLegislationStatusDialog(false)}
                    />
                )}
            </Box>
        </Box>
    );
}

export default Legislations;

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<LegislationViewModel>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "active", text: translateString("active"), align: "left", showOnMobile: true });
    headers.push({ property: "isCustom", text: translateString("standardMasterData"), align: "left", showOnMobile: true });
    return headers;
}
