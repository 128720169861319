import { Box } from "@mui/material";
import React from "react";
import { PurpleLockIcon } from "../common/icons.tsx";

export function WidgetLock() {
    return (
        <Box component={"span"} sx={{ "& svg": { height: 14, width: 14, marginBottom: "-2px" } }}>
            {PurpleLockIcon}
        </Box>
    );
}
