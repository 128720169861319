import { DotLegalDialog, DotLegalTableWithControls, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import CheckBox from "@mui/material/Checkbox";
import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { useTranslation } from "../../../localization/useTranslation";
import { PolicyRow } from "../PoliciesStep.types";
import { usePolicySelectorDialogDataMapping } from "./PolicySelectorDialog.hooks";
import { SelectablePolicyItem } from "../../../policy/policies/Policies.types";

export interface IPolicySelectorDialog {
    header: string;
    onDialogClose: () => void;
    policies?: Array<SelectablePolicyItem>;
    isLoading: boolean;
    onAddPoliciesClick: (policies: Array<string>) => void;
}

export default function PolicySelectorDialog(props: IPolicySelectorDialog) {
    const { translateString, translatePolicyTypes } = useTranslation();
    const { selectedPolicies, onCheckBoxClick, onSelectAllPolicies } = usePolicySelectorDialogDataMapping(props);

    const setHeaderCheckBoxState = () => {
        return selectedPolicies.length === 0 ? "false" : selectedPolicies.length === props.policies!.length ? "true" : "indeterminate";
    };

    return (
        <DotLegalDialog
            header={props.header}
            buttonOkText={translateString("add")}
            okButtonDisabled={selectedPolicies.length === 0}
            open
            onDialogClose={() => props.onDialogClose()}
            onOkClick={() => {
                props.onAddPoliciesClick(selectedPolicies);
                props.onDialogClose();
            }}
            buttonRight
            size={"md"}
            disableBackdropOnCloseIfValueChange={{ ...selectedPolicies }}
        >
            <DotLegalTableWithControls
                getUserSpecificPageLength={() => 10}
                hideRowsPerPage
                emptyText={translateString("noPoliciesAvaliable")}
                labelRowsPerPage={translateString("showEntites")}
                labelSearch={translateString("search")}
                noOptionsLabel={translateString("noOptions")}
                paginationLabelOf={translateString("labelOf")}
                headers={getHeaders(translateString)}
                onSelectAllChanged={() => onSelectAllPolicies()}
                headerCheckBoxState={setHeaderCheckBoxState()}
                defaultOrderBy={"name"}
                data={props.policies}
                defaultOrder={"asc"}
                isLoading={props.isLoading}
                renderRow={(row) => (
                    <TableRow key={row.id} onClick={() => onCheckBoxClick(row.id)}>
                        <TableCell>
                            <CheckBox checked={selectedPolicies.includes(row.id)} />
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{translatePolicyTypes(row.type.toString())}</TableCell>
                    </TableRow>
                )}
            ></DotLegalTableWithControls>
        </DotLegalDialog>
    );
}

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<PolicyRow>>();
    headers.push({ property: "name", text: translateString("policy"), align: "left", showOnMobile: true });
    headers.push({ property: "type", text: translateString("type"), align: "left", showOnMobile: true });

    return headers;
}
