import { alpha, useTheme } from "@mui/material";
import { toolTipTextSize } from "../../privacyTheme";
import { createSxStyles } from "../../common/createSxStyles";

export const useProcessingActivityOverviewStyles = () => {
    const theme = useTheme();
    return createSxStyles({
        container: {
            width: "100%",
            height: "100%",
        },
        sectionOneHeader: {
            color: theme.palette.grey["600"],
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(12),
            textTransform: "uppercase",
        },
        sectionOneText: {
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(12),
        },
        centerSectionPaper: {
            textAlign: "center",
            flex: 1,
            minHeight: theme.spacing(17),
            boxShadow: "none !important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            "& .header": {
                color: theme.palette.primary.dark,
                fontWeight: theme.typography.fontWeightBold,
                fontSize: theme.typography.pxToRem(12),
                textTransform: "uppercase",
            },
            "& .body": {
                color: theme.palette.primary.main,
                fontWeight: theme.typography.fontWeightBold,
                fontSize: theme.typography.pxToRem(36),
            },
            "& .footerContainer": {
                display: "flex",
                justifyContent: "space-evenly",
                height: 18,
            },
            "& .footer": {
                color: theme.palette.primary.dark,
                fontSize: theme.typography.pxToRem(11),
            },
        },
        autoComplete: {
            width: theme.spacing(12.5),
        },
        tableContainer: {
            flex: "1",
            display: "flex",
            flexDirection: "column",
        },
        editButton: {
            marginLeft: theme.spacing(0.5),
            "& svg": {
                height: 25,
                stroke: theme.palette.primary.main,
                fill: theme.palette.primary.light,
            },
        },
        divider: {
            backgroundColor: "#F0F7FC",
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(6),
            marginRight: theme.spacing(6),
        },
        gridRow: {
            textAlign: "center",
        },
        percentage: {
            display: "grid",
            alignContent: "center",
        },
        percentageContainer: {
            display: "flex",
            justifyContent: "center",
            width: 238,
        },
        number: {
            fontSize: theme.typography.pxToRem(24),
        },
        menuItem: {
            color: theme.palette.primary.dark,
            fontSize: "0.875rem",
            fontWeight: theme.typography.fontWeightMedium,
        },
        securityMeasure: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& svg": {
                height: 20,
                marginRight: theme.spacing(0.5),
            },
        },
        centerSectionContainer: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(4),
        },
        percentageSkeleton: {
            marginTop: theme.spacing(0.5),
        },
        gridContainer: {
            marginBottom: theme.spacing(1),
            alignItems: "center",
        },
        gridContentAlignment: {
            display: "flex",
            alignItems: "center",
        },
        gridAlignment: {
            display: "flex",
            justifyContent: "flex-end",
        },
        pictureSize: {
            height: 150,
        },
        riskAssessmentText: {
            color: theme.palette.primary.dark,
            fontSize: theme.typography.pxToRem(12),
            height: "55px",
            display: "flex",
            justifyContent: "center",
            boxOrient: "vertical",
            overflow: "hidden",
        },
        textAlignLeft: {
            textAlign: "left",
        },
        riskAssessmentScoreToolTip: {
            margin: theme.spacing(1),
        },
        ownerBlock: {
            display: "flex",
            justifyContent: "center",
            "& svg": {
                fill: theme.palette.error.light,
                marginLeft: theme.spacing(0.5),
                height: 17,
                width: 17,
            },
        },
        validationContainer: {
            fontSize: 10,
        },
    });
};
