import React from "react";
import { Box } from "@mui/material";

export interface IWhiteDashboardBoxProps {
    marginTop?: number;
    fullHeight?: boolean;
    children: React.ReactNode;
}

function WhiteDashboardBox(props: IWhiteDashboardBoxProps) {
    return (
        <Box
            sx={(theme) => ({
                mt: props.marginTop,
                height: props.fullHeight ? "100%" : undefined,
                padding: 2,
                borderRadius: "20px",
                backgroundColor: theme.customPalette.white,
            })}
        >
            {props.children}
        </Box>
    );
}

export default WhiteDashboardBox;
