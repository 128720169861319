import { useQuery } from "react-query";
import { deleteHttp, get, post } from "../../../common/api/apiShared";
import { TaskRelationRow, TaskRelationSaveModel, TaskRelationViewModel } from "./TaskRelations.types";
import { ITaskRelations } from "./TaskRelations";
import { SelectableItem } from "../../../processingActivity/ProcessingActivity.types";
import { useTranslation } from "../../../localization/useTranslation";
import { setFirstLetterToLowerCase } from "../../../common/stringOperations";
import { AnnualWheelMonthEnum } from "../../../annualWheel/AnnualWheel.types";
import { useOptimisticUpdate } from "../../../common/hooks/useOptimisticUpdate";
import { useEffect, useState } from "react";
import { sortTableData } from "@dotlegal/dotlegal-ui-components";

export default function useTaskRelationsHooks(props: ITaskRelations) {
    const optimisticUpdate = useOptimisticUpdate();
    const { translateString } = useTranslation();

    const url = "/taskRelation/" + props.taskId;
    const [showSelectActivities, setShowSelectActivities] = useState(false);

    const [hoveredTask, setHoveredTask] = useState("");
    const { data, isSuccess } = useQuery(url, () => get<TaskRelationViewModel>(url));
    const [orderBy, setOrderBy] = useState<string>("deadline");
    const [order, setOrder] = useState<"asc" | "desc">("asc");
    const [addClicked, setAddClicked] = useState(false);

    useEffect(() => {
        if (props.passIsDoneLoading && isSuccess) {
            props.passIsDoneLoading(true);
        }
    }, [props, isSuccess]);

    useEffect(() => {
        if (addClicked) {
            setAddClicked(false);
        }
    }, [addClicked]);

    useEffect(() => {
        if (props.shouldAddRelatedTask) {
            showAndAddRelatedTask();
            props.updateRelatedTaskScenario();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.shouldAddRelatedTask]);

    function showAndAddRelatedTask() {
        if (!showSelectActivities) {
            setShowSelectActivities(true);
        }

        setAddClicked(true);
    }

    let sortedData: Array<TaskRelationRow> = [];
    if (data) {
        sortedData = sortTableData(data.taskRelations, orderBy, order);
    }

    function onSortClick(columnName: string) {
        setOrderBy(columnName);
        setOrder(order === "asc" ? "desc" : "asc");
    }

    async function addTaskRelation(taskId: string) {
        const temp = { ...data };

        var selectableTask = temp.selectableTasks?.find((x) => x.taskId === taskId)!;
        temp.taskRelations?.push(selectableTask);

        temp.selectableTasks = temp.selectableTasks?.filter((x) => x.taskId !== taskId);

        await optimisticUpdate.putOnQueueAndSetQueryData(temp, url, add, taskId);
    }

    async function deleteTaskRelation(taskId: string) {
        const temp = { ...data };

        var taskRelation = temp.taskRelations?.find((x) => x.taskId === taskId)!;
        temp.selectableTasks?.push(taskRelation);

        temp.taskRelations = temp.taskRelations?.filter((x) => x.taskId !== taskId);

        if (temp.taskRelations?.length === 0) {
            props.onHideRelatedTasks();
        }

        await optimisticUpdate.putOnQueueAndSetQueryData(temp, url, remove, taskId);
    }

    let selectableTasks: Array<SelectableItem> = [];
    if (data) {
        selectableTasks = data.selectableTasks
            .map((x) => {
                var name = `${x.activityName} - ${formatDate(x.deadline)} - ${x.groupEntity ? x.groupEntity : translateString("entireGroup")}`;

                return { id: x.taskId, name: name };
            })
            .sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    function formatDate(deadline?: Date) {
        if (deadline) {
            var date = new Date(deadline);

            return `${translateString(setFirstLetterToLowerCase(AnnualWheelMonthEnum[date.getMonth() + 1].toString()))} ${date.getFullYear()}`;
        }

        return "";
    }

    return {
        data,
        selectableTasks,
        addTaskRelation,
        deleteTaskRelation,
        formatDate,
        showSelectActivities,
        setShowSelectActivities,
        sortedData,
        order,
        orderBy,
        onSortClick,
        hoveredTask,
        setHoveredTask,
        addClicked,
        setAddClicked,
        showAndAddRelatedTask,
    };

    async function add(taskId: string) {
        return post<Array<TaskRelationSaveModel>>("/taskRelation", convertToSaveModel(taskId));
    }

    async function remove(id: string) {
        return await deleteHttp(`/taskRelation/${props.taskId}/${id}`);
    }

    function convertToSaveModel(taskId2: string) {
        return {
            taskId1: props.taskId,
            taskId2: taskId2,
        };
    }
}
