import React from "react";
import { useUserContext } from "../../../auth/userContextProvider/UserContextProvider";
import {
    DotLegalActionBar,
    DotLegalOverflowMenu,
    DotLegalPageHeader,
    DotLegalTableWithControls,
    DotLegalSwitch,
    ITableHeader,
} from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../../localization/useTranslation";
import { useDataCategoriesHooks } from "./DataCategories.hooks";
import { useDataCategoriesStyles } from "./DataCategories.styles";
import { DataCategoryViewModel } from "./DataCategories.types";
import DotLegalPaper from "../../../common/components/dotLegalPaper/DotLegalPaper";
import { Box, TableCell, TableRow } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteDataCategoryDialog from "../deleteDataCategoryDialog/DeleteDataCategoryDialog";
import { DataPrivacyClassification } from "../../../processingActivity/DataCategoriesStep/DataCategoriesStep.types";
import { ChangeDataCategoryStatusDialog } from "../changeDataCatagoryStatusDialog/ChangeDataCategoryStatusDialog";
import AddEditDataCategoryDialog from "../addEditDataCatoryDialog/AddEditDataCategoryDialog";
import { useMasterDataBreadCrumbProvider } from "../../useMasterDataBreadCrumbProvider";
import { getMasterIcon } from "../../MasterData.hooks";

export function DataCategories() {
    const styles = useDataCategoriesStyles();
    const { permissions, customerName } = useUserContext();
    const { translateString } = useTranslation();
    const { getBreadCrumbs } = useMasterDataBreadCrumbProvider();

    const {
        isLoading,
        dataCategories,
        showAllDataCategories,
        setShowAllDataCategories,
        showAddEditDialog,
        setShowAddEditDialog,
        showDeleteDataCategoryDialog,
        setShowDeleteDataCategoryDialog,
        selectedDataCategory,
        setSelectedDataCategory,
        refetchDataCategories,
        showChangeStatusDialog,
        setShowChangeStausDialog,
    } = useDataCategoriesHooks();

    const getMenuItems = (dc: DataCategoryViewModel) => {
        let menuItems = [];
        if (permissions.canManageMasterData) {
            menuItems.push({
                menuItem: translateString("edit"),
                onClick: () => {
                    setSelectedDataCategory({
                        name: dc.name,
                        description: dc.description,
                        id: dc.id,
                        classification: dc.classification,
                        confidential: dc.confidential,
                        active: dc.active,
                        isCustom: dc.isCustom,
                    });
                    setShowAddEditDialog(true);
                },
            });

            menuItems.push({
                menuItem: dc.active ? translateString("deactivate") : translateString("activate"),
                onClick: () => {
                    setSelectedDataCategory({
                        name: dc.name,
                        description: dc.description,
                        id: dc.id,
                        classification: dc.classification,
                        confidential: dc.confidential,
                        active: dc.active,
                        isCustom: dc.isCustom,
                    });
                    setShowChangeStausDialog(true);
                },
            });
        }

        if (permissions.canManageMasterData && dc.isCustom) {
            menuItems.push({
                menuItem: translateString("removeDataCategory"),
                onClick: () => {
                    setSelectedDataCategory({
                        name: dc.name,
                        description: dc.description,
                        id: dc.id,
                        classification: dc.classification,
                        confidential: dc.confidential,
                        active: dc.active,
                        isCustom: dc.isCustom,
                    });
                    setShowDeleteDataCategoryDialog(true);
                },
            });
        }
        return menuItems;
    };

    return (
        <Box sx={styles.container}>
            <DotLegalPageHeader
                userContext={{ customerName }}
                breadCrumbs={getBreadCrumbs(translateString("dataCategories"))}
                leftComponent={
                    <DotLegalSwitch
                        onChange={setShowAllDataCategories}
                        checked={showAllDataCategories}
                        leftLabel={translateString("active")}
                        rightLabel={translateString("all")}
                    />
                }
            />

            <div>
                <DotLegalActionBar
                    canCreate={permissions.canManageMasterData}
                    buttonText={translateString("createDataCategory")}
                    onOpenDialog={() => {
                        setShowAddEditDialog(true);
                        setSelectedDataCategory(undefined);
                    }}
                    showDialog={showAddEditDialog}
                    icon={getMasterIcon()}
                >
                    <AddEditDataCategoryDialog
                        dataCategory={selectedDataCategory}
                        isAdd={selectedDataCategory === undefined}
                        open={showAddEditDialog}
                        onDialogClose={() => setShowAddEditDialog(false)}
                        onOkClick={() => setShowAddEditDialog(false)}
                        refetch={refetchDataCategories}
                    />
                </DotLegalActionBar>
            </div>
            <DotLegalPaper>
                <DotLegalTableWithControls
                    headers={getHeaders(translateString)}
                    isLoading={isLoading}
                    defaultOrderBy={"name"}
                    defaultOrder={"asc"}
                    getUserSpecificPageLength={() => 30}
                    hideRowsPerPage
                    labelRowsPerPage={translateString("showEntites")}
                    labelSearch={translateString("search")}
                    noOptionsLabel={translateString("noOptions")}
                    paginationLabelOf={translateString("labelOf")}
                    emptyText={translateString("noData")}
                    data={dataCategories}
                    renderRow={(row, i) => (
                        <TableRow key={i}>
                            <TableCell sx={styles.bold}>{row.name}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{translateString(DataPrivacyClassification[row.classification as number].toLowerCase())}</TableCell>
                            <TableCell>
                                <Box sx={styles.iconContainer}>{row.confidential ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={styles.iconContainer}>{row.active ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={styles.iconContainer}>{!row.isCustom ? <CheckIcon sx={styles.checkIcon} /> : ""}</Box>
                            </TableCell>
                            <TableCell align={"right"}>
                                <DotLegalOverflowMenu items={getMenuItems(row)} />
                            </TableCell>
                        </TableRow>
                    )}
                />
            </DotLegalPaper>

            {showDeleteDataCategoryDialog && (
                <DeleteDataCategoryDialog
                    selectedDataCatagory={selectedDataCategory!}
                    onSaveCorrect={refetchDataCategories}
                    onCloseDialog={() => setShowDeleteDataCategoryDialog(false)}
                />
            )}

            {showChangeStatusDialog && (
                <ChangeDataCategoryStatusDialog
                    selectedDataCategory={selectedDataCategory!}
                    refetch={refetchDataCategories}
                    onCloseDialog={() => setShowChangeStausDialog(false)}
                />
            )}
        </Box>
    );
}

function getHeaders(translateString: any) {
    let headers = Array<ITableHeader<DataCategoryViewModel>>();
    headers.push({ property: "name", text: translateString("name"), align: "left", showOnMobile: true });
    headers.push({ property: "description", text: translateString("description"), align: "left", showOnMobile: true });
    headers.push({ property: "classification", text: translateString("classification"), align: "left", showOnMobile: true });
    headers.push({ property: "confidential", text: translateString("confidential"), align: "left", showOnMobile: true });
    headers.push({ property: "active", text: translateString("active"), align: "left", showOnMobile: true });
    headers.push({ property: "isCustom", text: translateString("standardMasterData"), align: "left", showOnMobile: true });

    return headers;
}
