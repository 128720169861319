import { useTranslation } from "../../localization/useTranslation.ts";
import { useUrlProvider } from "../../useUrlProvider.ts";
import Widget from "../Widget.tsx";
import DashboardCountCard from "../DashboardCountCard.tsx";
import { Box } from "@mui/material";
import { LargeCountText } from "../processingActivityCountWidget/ProcessingActivityCountWiget.tsx";
import { useSystemsCount } from "./SystemsCountWidget.hooks.ts";
import { onlyUserSpecificSystemStorageKey } from "../../system/systems/Systems.hooks.ts";
import CountUp from "react-countup";
import React from "react";

export interface ISystemsCountWidgetProps {
    onlyUserSpecific: boolean;
}

function SystemsCountWidget(props: ISystemsCountWidgetProps) {
    const { translateString } = useTranslation();
    const { getSystemsUrl } = useUrlProvider();
    const { isLoading, data, isEnabled, counterValue, setCounterValue } = useSystemsCount(props);

    return (
        <Widget noPadding disabled={!isEnabled}>
            <DashboardCountCard
                isLoading={isLoading}
                header={translateString("systems")}
                background="pink"
                icon="screen"
                url={{
                    url: getSystemsUrl(),
                    text: translateString("gotoSystems"),
                    allMyPageKey: onlyUserSpecificSystemStorageKey,
                    onlyUserSpecific: props.onlyUserSpecific,
                }}
            >
                {data !== undefined && (
                    <Box sx={{ display: "flex", gap: 1, alignItems: "flex-end" }}>
                        <LargeCountText>
                            <Box>{counterValue != data ? <CountUp end={data} duration={5} onEnd={() => setCounterValue(data)} /> : counterValue}</Box>
                        </LargeCountText>
                    </Box>
                )}
            </DashboardCountCard>
        </Widget>
    );
}

export default SystemsCountWidget;
