import React from "react";
import { useRiskWidget } from "./RiskWidget.hooks.ts";
import Widget from "../Widget.tsx";
import { DotLegalCompletedPercentage, DotLegalEmptyState, DotLegalHeader, DotLegalSwitch, DotLegalTable, ITableHeader } from "@dotlegal/dotlegal-ui-components";
import { useTranslation } from "../../localization/useTranslation.ts";
import { Box, TableCell, TableRow } from "@mui/material";
import WhiteDashboardBox from "../WhiteDashboardBox.tsx";
import NavigateTo from "../navigateTo/NavigateTo.tsx";
import { useUrlProvider } from "../../useUrlProvider.ts";
import { DashboardRiskOverviewRow } from "./RiskWidget.types.ts";
import { RiskAssessmentType } from "../../riskAssessment/RiskAssessment.types.ts";
import RiskIndicator from "../../riskAssessment/riskIndicator/RiskIndicator.tsx";
import { RiskAssessmentArea } from "../../riskAssessment/riskAssessments/RiskAssessments.types.ts";
import DotLegalLink from "../../common/components/dotLegalLink/DotLegalLink.tsx";
import NoRisky from "./no-risky-entities.svg?react";
import NoTemplates from "./no-risk-templates.svg?react";
import { Trans } from "react-i18next";
import { WidgetLock } from "../WidgetLock.tsx";
import DashboardPrettyScrollbar from "../DashboardPrettyScrollbar.tsx";

export interface IRiskWidgetProps {
    onlyUserSpecific: boolean;
    selectedGroupCompany: string | undefined;
}

function RiskWidget(props: IRiskWidgetProps) {
    const { translateString } = useTranslation();

    const { getProcessingActivityOverviewUrl, getSystemOverviewUrl, getRiskAssessmentsUrl, getRiskAssessmentQuestionsDialog, getRiskAssessmentTemplateUrl } =
        useUrlProvider();
    const { isLoading, onPaOrSystemChange, systemsChosen, data, isEnabled, systemsOptionEnabled, processingActivityOptionEnabled, nis2Enabled } =
        useRiskWidget(props);

    const headers: Array<ITableHeader<DashboardRiskOverviewRow>> = [];
    headers.push({
        property: "name",
        text: systemsChosen ? translateString("asset") : translateString("processingActivity"),
    });
    headers.push({ property: "area", text: translateString("area") });
    headers.push({ property: "riskScore", text: translateString("risk") });

    return (
        <Widget isLoading={isLoading} disabled={!isEnabled}>
            <DotLegalHeader marginBottom={0} headerStyle={"medium"}>
                {translateString("risk").toUpperCase()}
            </DotLegalHeader>
            <DotLegalSwitch
                onChange={onPaOrSystemChange}
                checked={systemsChosen}
                disabled={!systemsOptionEnabled || !processingActivityOptionEnabled}
                leftLabel={
                    <Box component={"span"}>
                        {!processingActivityOptionEnabled && <WidgetLock />} {translateString("processingActivities")}
                    </Box>
                }
                rightLabel={
                    <Box component={"span"}>
                        {translateString("assets")} {!systemsOptionEnabled && <WidgetLock />}
                    </Box>
                }
            />

            <WhiteDashboardBox marginTop={2}>
                <DotLegalHeader marginBottom={1} headerStyle={"small"}>
                    {translateString("riskAssessmentHeader")}: GDPR
                </DotLegalHeader>
                <DotLegalCompletedPercentage oneLiner completed={data?.gdprPercentage ?? 0} />
            </WhiteDashboardBox>

            {nis2Enabled && (
                <WhiteDashboardBox marginTop={2}>
                    <DotLegalHeader marginBottom={1} headerStyle={"small"}>
                        {translateString("riskAssessmentHeader")}: NIS2
                    </DotLegalHeader>
                    <DotLegalCompletedPercentage oneLiner completed={data?.nisPercentage ?? 0} />
                </WhiteDashboardBox>
            )}

            <Box sx={{ flex: 1, mt: 2, display: "flex", flexDirection: "column" }}>
                <DotLegalHeader marginBottom={1} headerStyle={"medium"}>
                    {translateString("highOrVeryHighIdentified")}
                </DotLegalHeader>
                <WhiteDashboardBox fullHeight>
                    {data?.noTemplate ? (
                        <DotLegalEmptyState
                            textColor={"darkBlue"}
                            noFixedHeight
                            noPadding
                            icon={<NoTemplates />}
                            text={
                                <Trans
                                    i18nKey={"setupRiskTemplate"}
                                    components={{
                                        riskTemplate: (
                                            <DotLegalLink
                                                to={getRiskAssessmentTemplateUrl(
                                                    systemsChosen ? RiskAssessmentArea.Systems : RiskAssessmentArea.ProcessingActivities
                                                )}
                                                linkColor="primary"
                                            >
                                                {undefined}
                                            </DotLegalLink>
                                        ),
                                    }}
                                />
                            }
                        />
                    ) : (
                        <>
                            {(data?.rows.length ?? 0 > 0) ? (
                                <DashboardPrettyScrollbar>
                                    <DotLegalTable
                                        headers={headers}
                                        rowsPerPage={10000}
                                        clickableRows={false}
                                        defaultOrderBy={"riskScore"}
                                        defaultOrder={"desc"}
                                        data={data?.rows ?? []}
                                        hidePagination
                                        renderRow={(row) => (
                                            <TableRow key={row.id + row.versionId}>
                                                <TableCell>
                                                    {systemsChosen ? (
                                                        <DotLegalLink bold linkColor={"primary"} to={getSystemOverviewUrl(row.id)}>
                                                            {row.name}
                                                        </DotLegalLink>
                                                    ) : (
                                                        <DotLegalLink bold linkColor={"primary"} to={getProcessingActivityOverviewUrl(row.id)}>
                                                            {row.name}
                                                        </DotLegalLink>
                                                    )}
                                                </TableCell>
                                                <TableCell>{row.areaString}</TableCell>
                                                <TableCell>
                                                    <RiskIndicator
                                                        riskAssessmentType={RiskAssessmentType.None}
                                                        riskScoreTextColor={"darkblue"}
                                                        score={row.riskScore}
                                                        removeLeftMargin
                                                        linkHref={getRiskAssessmentQuestionsDialog(
                                                            row.id,
                                                            systemsChosen ? RiskAssessmentArea.Systems : RiskAssessmentArea.ProcessingActivities,
                                                            row.area,
                                                            row.versionId
                                                        )}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    />
                                </DashboardPrettyScrollbar>
                            ) : (
                                <DotLegalEmptyState
                                    textColor={"darkBlue"}
                                    noFixedHeight
                                    noPadding
                                    icon={<NoRisky />}
                                    text={translateString("noHighOrVeryHighRisksContent")}
                                />
                            )}
                        </>
                    )}
                </WhiteDashboardBox>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <NavigateTo color="purple" text={translateString("goToRisks")} to={getRiskAssessmentsUrl()} />
            </Box>
        </Widget>
    );
}

export default RiskWidget;
